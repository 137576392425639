import { useState, useEffect, useCallback } from "react";
import { useAppDispatch } from "../redux/hooks/redux-hooks";
// import { useAppDispatch } from "../redux/hooks/redux-hooks";

/*
  Condition:
  feed.clientHeight - window.pageYOffset <= window.outerHeight

  We want a reference to our feed element - to use feed.clientHeight.
  We want to make an apiCall after we trip the condition, and a state variable that tells us not to keep
  making that call.

  After we get our response, we should update that state variable and our reference to our element
  should update the trip condition.
*/

// Need to figure out a way to kill apiCall

interface InfiniteScrollParam {
  feedReference: any;
  apiCall: any;
  incrementCounterRedux: any;
  saveFeedRedux: any;
}

const useInfiniteFeedScroll = ({ feedReference, apiCall, incrementCounterRedux, saveFeedRedux }: InfiniteScrollParam) => {
  const [status, setStatus] = useState('idle'); // 'idle', 'pending', or 'completed'
  const dispatch = useAppDispatch();

  const checkScroll = useCallback(() => {
    const shouldCallApi = (
      feedReference.current.clientHeight > 1000 &&
      feedReference.current.clientHeight - window.scrollY <= window.outerHeight
    );

    if (shouldCallApi && status === 'idle') {
      console.log("Triggering API call due to scroll...");
      setStatus('calling');
    }
  }, [feedReference, status]);

  useEffect(() => {
    const handleScroll = () => checkScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [checkScroll]);

  useEffect(() => {
    if (status === 'calling') {
      setStatus('pending')
      console.log("Calling API...");
      apiCall().then((data: any) => {
        console.log("API call completed.");
        setTimeout(() => {
          dispatch(saveFeedRedux({ ...data }));
          dispatch(incrementCounterRedux());
          setStatus('completed');
        }, 2000)
       
      }).catch((error: any) => {
        console.error("Error during API call: ", error);
        setStatus('completed');
      });
    }
  }, [status, apiCall, dispatch, incrementCounterRedux, saveFeedRedux]);

  useEffect(() => {
    if (status === 'completed') {
      setTimeout(() => setStatus('idle'), 500); // add delay to prevent immediate re-fetching
    }
  }, [status]);

  return [status];
};

export default useInfiniteFeedScroll;
