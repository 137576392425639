import { useState } from 'react';
import './FeedCommentContainer.css';
import '../../story/hairline.css';
import ParentComment from '../../comments/shared/ParentComment';
import { RetrievedCommentData } from '../../../interfaces/comment-entities.interfaces';

interface Param {
  commentData: RetrievedCommentData;
  replyBoxIsOpen: boolean;
  toggleShowReplyBox: Function;
  children: any;
  entityInfo: any;
}

const FeedCommentContainer = ({ 
  commentData, 
  replyBoxIsOpen, 
  toggleShowReplyBox, 
  children,
  entityInfo,
}: Param) => {
  
  const [ , getNumOfComments ] = useState(0);
  return (
    <div className="feed-comment-wrapper">
    
      <ParentComment 
        commentData={commentData.data}
        entityInfo={entityInfo}
        getNumOfComments={getNumOfComments}
        isFeedComment
        replyBoxIsOpen={replyBoxIsOpen} 
        toggleShowReplyBox={toggleShowReplyBox}
      />

      {children}
    </div>
  );
};

export default FeedCommentContainer;
