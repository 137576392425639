import AccountTabBarItem from "./AccountTabBarItem";
import "./AccountTabBar.css";
import { useState } from "react";
import { useRedirects } from "../../../hooks/useNav";

interface Tab {
  label: string;
  link: string;
}

export interface TabData {
  tabs: Tab[],
  preSelected?: string;
}

interface Param {
  tabData: TabData;
  preSelected?: string;
}

const AccountTabBar = ({ tabData }: Param) => {
  
  const [selected, setSelected] = 
    useState<string | undefined>(tabData.preSelected);
  
  
  useRedirects({
    redirects: [
      { from: '/account', to: `/account/${selected}` }
    ],
    options: { useOnce: true }
  });

  return (
    <div className="tab-bar-container">
      {tabData?.tabs.map((tab) => (
        <AccountTabBarItem
          key={tab.label}
          label={tab.label}
          link={tab.link}
          stateChange={setSelected}
          isSelected={selected === tab.label}
        />
      ))}
    </div>
  );
};

export default AccountTabBar;
