import { useState } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../shared/text-input/TextInput';
import PrimaryButton from '../../shared/primary-button/PrimaryButton';
import HeaderText from '../../shared/header-text/HeaderText';
import '../onboarding/name-and-email/NameAndEmail.css';
import './SignIn.css';

import { authUtils } from '../../../firebase/instance';
import { PASSWORD_FORGET, SIGN_UP } from '../../../constants/routes';

const SignIn = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    error: null,
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { email, password } = state;

    authUtils
      .doSignInUserWithEmailAndPassword(email, password)
      .catch((error) => {
        console.error(error);
        setState({ ...state, error: error.code });
      });
  };

  const isInvalid = state.email === "" || state.password === "";

  return (
    <form
      className="signin-form-container section-container"
      onSubmit={handleSubmit}
    >
      <div className="name-email-wrapper">
        <HeaderText
          styleColor="light"
          headerText="ENGAGE WITH PERSONALIZED POLITICS & NEWS."
        />

        <div>
          <TextInput
            styleColor="light"
            placeholder="EMAIL"
            onTextInputChange={(event) => {
              setState({ ...state, email: event.target.value });
            }}
          />
          <TextInput
            styleColor="light"
            placeholder="PASSWORD"
            type="password"
            onTextInputChange={(event) => {
              setState({ ...state, password: event.target.value });
            }}
            errorMessage={state.error ? "Invalid email and/or password.*" : ""}
            errorMessageColor="white"
          />

          <PrimaryButton label="LOGIN" onClick={handleSubmit} disabled={isInvalid} />
        </div>

        <Link to={PASSWORD_FORGET} className="forgot-password-link">
          <span className="forgot-password-text">Forgot your password?</span>
        </Link>

        <div className="mobilize-logo-container">
          <img
            className="mobilize-logo"
            alt="Mobilize Logo"
            src="/img/mobilizeIconWhitetext.svg"
          />
        </div>
      </div>

      <div className="footer">
        <p className="footer-text">Need to create an account?</p>
        <Link to={SIGN_UP}>
          <span className="login-link">Sign Up.</span>
        </Link>
      </div>
    </form>
  );
};

export default SignIn;
