import { UnknownObject } from "../../../interfaces/global";

interface Weights {
  age: number;
  engagement: number;
  decay: number;
}

const calculateAgeScore = (postDate: string, isComment: boolean = false): number => {
  const commentOrStoryDate: any = isComment ? postDate : new Date(postDate).getTime();
  const ageInDays = (new Date().getTime() - commentOrStoryDate) / (1000 * 3600 * 24);
  return 1 / (1 + ageInDays);
};

const calculateEngagementScore = (interactions: number, lastInteractionDate: string): number => {
  const daysSinceLastInteraction = (new Date().getTime() - new Date(lastInteractionDate).getTime()) / (1000 * 3600 * 24);
  const decayedEngagement = interactions / (1 + daysSinceLastInteraction);
  return decayedEngagement;
};

const default_weights: Weights = { age: 0.5, engagement: 0.3, decay: 0.2 };

interface StoriesAndComments {
  stories: any;
  comments?: any;
  duplicateDataById?: UnknownObject;
}

export const sortFeed = ({ stories = [], comments = [], duplicateDataById = {}}: StoriesAndComments, weights: Weights = default_weights): any => {
  /*
    comment:
      - date: comment.data.timestampUTC (1676489567470)
      - interactions: entity.appData.numOfComments
      - lastInteractionDate: comment.data.timestampUTC

    stories:
      - date: entityData.pubDate (2024-04-20T03:00:00+00:00)
      - interactions: appData.numOfComments + app.opposing.length-1 + app.supporting.length-1
      - lastInteractionDate: entityData.addDate
  */
  // const randomIntFromRange = (min: number, max: number) => {
  //   return Math.floor(Math.random() * (max-min+1) + min);
  // }

  const storiesWithScore = stories.map((post: any) => {
    const ageScore = calculateAgeScore(post.entityData.pubDate);
    const hasAppData = !!post.entity?.appData
    const interactions = hasAppData ? 
      post.entity.appData.numOfComments + 
      post.entity.appData.opposing.length-1 + 
      post.entity.appData.supporting.length-1 : 0
    const engagementScore = calculateEngagementScore(
      interactions, 
      post.entityData.addDate
    );

    const story = { data: post, score: 0 };
    story.score = (weights.age * ageScore +
                  weights.engagement * engagementScore +
                  weights.decay * engagementScore);
    return story;
  });

  const commentsWithScore = comments.map((com: any) => {
    
    const ageScore = calculateAgeScore(com.comment.data.timestampUTC, true);
    const isPolitician = com?.comment?.entityInfo?.type === 'politician' ? 5 : 1;
    const isBillOrProp = com?.comment?.entityInfo?.type === 'proposition' ? 8 : 1
    const engagementScore = calculateEngagementScore(
      com.entity.appData.numOfComments * isPolitician * isBillOrProp, com.comment.data.timestampUTC
    );
    const comment = { data: com, score: 0 };
    comment.score = (weights.age * ageScore +
                  weights.engagement * engagementScore +
                  weights.decay * engagementScore);
    return comment;
  });

  
  const sorted = [...storiesWithScore, ...commentsWithScore].sort((a, b) => (b.score ?? 0) - (a.score ?? 0));
  /*
    Remove duplicate stories...
    if (
        data?.appData?.id &&
        comments?.duplicateDataById && 
        comments?.duplicateDataById[jsx.props.data.appData.id]
      ){
        return false;
      }
      return true;
  */


  /*
    Two pointer algorithm that seperates comments...
  */
  const spaceOutComments = (arr: any[], decend: number) => {
    if (arr.length < 3) return arr;
    let j = 0;
    let hold = null;
    let firstMove = false;
    for (let i = 2; i < arr.length; i++) {
      const cur = arr[i];
      j = i-2;
      let stop = false;
      if (cur?.data?.comment) {
        hold = cur;
        while (j > 2 && !stop) {
          if (!firstMove) {
            j -= decend;
            firstMove = true;
          } else {
            decend--
            j -= decend
          }
          if (j < 0) return arr;
          const nxt = arr[j];
          if (!nxt?.data?.comment) {
            arr[j] = hold;
            arr[i] = nxt;
            hold = null;
            stop = true;
          }
        }
      }
  
    }
  }
  spaceOutComments(sorted, comments.length);
  return sorted;
};
