import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { UnknownObject } from "../../../../interfaces/global";
import PoliticianPage from "../../../politician/politician-profile/PoliticianPage";
import PropositionPage from "../../proposition/PropositionPage";


const Executive = () => {
  const { data } = useOutletContext() as UnknownObject;

  const [state] = useState({
    currentTab: "Politicians",
  });

  const offices = data?.offices ?? [];


  return (
    <>
      {state.currentTab === "Politicians" ? (
        <PoliticianPage executive={true} offices={offices} />
      ) : (
        <PropositionPage />
      )}
      {/* <FooterTabBar
        leftLabel="Politicians"
        rightLabel="Proposition"
        onStateChange={newState => {
          setState({
            ...state,
            currentTab: newState
          });
        }}
      /> */}
    </>
  );
};

export default Executive;
