import "./ErrorText.css";

interface ErrorTextParam {
  errorMessage?: string;
  errorMessageColor?: string;
}

const ErrorText = ({ errorMessage, errorMessageColor }: ErrorTextParam) => {
  const className =
    errorMessageColor === "white"
      ? "error-message-color-white"
      : "error-message-color-red";

  return errorMessage !== null ? (
    <p className={`error-message ${className}`}>{errorMessage}</p>
  ) : null;
}

export default ErrorText;
