import { 
  arrayRemove, 
  arrayUnion, 
  doc, 
  runTransaction, 
  updateDoc 
} from "firebase/firestore";
import { OfficialWithDB } from "../../interfaces/politicians.interfaces";
import { firestore, politiciansCollectionRef } from "../instance";

export interface UpdatePoliticianParam {
  userId: string;
  politician: string;
  isFollowed: boolean | undefined;
}

export const updatePoliticianFollowers = async ({ userId, politician, isFollowed }: UpdatePoliticianParam) => {
  const politicianDocRef = doc(politiciansCollectionRef, politician);
  await updateDoc(politicianDocRef, {
    'appData.followers': isFollowed ? arrayRemove(userId) : arrayUnion(userId)
  });
}

export const addNewPoliticianToDb = async (politicianData: OfficialWithDB) => {
  // Check to see if politician exists
  // We don't want to overwrite
  const { entityData } = politicianData;

  const politicanDocRef = doc(politiciansCollectionRef, entityData.name);

  await runTransaction(firestore, async t => {
    const polititicanDoc = await t.get(politicanDocRef);

    if (!polititicanDoc.exists()) {
      t.set(politicanDocRef, {
        appData: {
          id: entityData.name,
          supporting: [],
          opposing: [],
          uniqueData: null,
          followers: []
        },
        entityData
      })
    }
  });
}