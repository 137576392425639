import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/redux-hooks';
import { handleChangeOfEntitySupport } from '../../../util/util.firestore';
import { handlePublishComment } from '../../../firebase/comments/shared/handle-publish.comment';

import './CommentWindow.css';
import { EntityInfo } from '../../../interfaces/comment-entities.interfaces';
import { UnknownObject } from '../../../interfaces/global';

interface CommentWindowParam {
  closeCommentWindow: Function;
  entityInfo: EntityInfo;
  entityData: any;
  handleOppose: Function;
  handleSupport: Function;
  newCommentAdded: boolean;
  doesSupport: boolean | null;
  trackCommentAdded: Function;
  uniqueData?: UnknownObject | null;
}

export const CommentWindow = 
  ({
    closeCommentWindow,
    handleOppose,
    handleSupport,
    newCommentAdded,
    entityInfo,
    entityData,
    doesSupport,
    uniqueData = null,
    trackCommentAdded,
  }: CommentWindowParam) => {

    const userAccount = useAppSelector(state => state.account);
    const dispatch = useAppDispatch();

    const [contentState, setContentState] = useState({
      inputValue: '',
    });

    const handleInputChange = (e: any) => {
      setContentState({ ...contentState, inputValue: e.target.value });
    };
    
    const handlePublish = () => {
      handlePublishComment(
        { 
          entityData,
          entityInfo,
          newCommentAdded,
          doesSupport,
          trackCommentAdded,
          uniqueData,
          userAccount,
          dispatch
        },
        contentState
      );
      closeCommentWindow();
    }

    const closeAndSaveSupportToEntity = () => {
      handleChangeOfEntitySupport({ entityInfo, entityData, userAccount, doesSupport });
      closeCommentWindow();
    }

    const handleSupportClick = () => {
      if(doesSupport !== null || doesSupport !== undefined) {
        handleSupport();
      }
    }

    const handleOpposeClick = () => {
      if(doesSupport !== null || doesSupport !== undefined) {
        handleOppose();
      }
    }

    // Close Comment Window & Make Request to support / oppose
    return ReactDOM.createPortal(
      <div className='comment-window-root'>
        <button 
          className='comment-window-background' 
          onClick={closeAndSaveSupportToEntity}
        />
        <div className='comment-window-container'>
          <div className='comment-button-container'>
            <button 
              data-id={'positive'}
              className={doesSupport ? 'support' : ''}
              onClick={handleSupportClick}
            >
              <img
                src='/img/icons/thumbUp.svg'
                alt='thump up'
                className='thumbUp-img-big'
              />
              {doesSupport === false ? 'Support' : 'Supporting'}
            </button>
            <button
              data-id={'negative'}
              className={doesSupport === false ? 'oppose' : ''}
              onClick={handleOpposeClick}
            >
              <img
                src='/img/icons/thumbDown.svg'
                alt='thump down'
                className='thumbDown-img-big'
              />
              {doesSupport === false ? 'Opposing' : 'Oppose'}
            </button>
          </div>
          <div className='input-container'>
            <input
              className='comment-input'
              placeholder='Write a thoughtful comment'
              onChange={handleInputChange}
            />
            <button 
              className='publish-button' 
              onClick={handlePublish}
            >
              Publish
            </button>
          </div>
        </div>
      </div>,
      document.getElementById('modal-root') as HTMLElement
    );
  }