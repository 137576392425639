import { useEffect, useState } from "react"
import GrandchildComment from "./GrandchildComment";
import ReplyToCommentForm from "./ReplyToCommentForm";
import { 
  hoursAgo, 
  displaySupport, 
} from "../util/comments.util"
import './comments.container.css';
import './comment.css';
import { EntityInfo } from "../../../interfaces/comment-entities.interfaces";
import { grandchildCommentsQuery } from "../../../firebase/comments/shared/fetch-comments";
import { onSnapshot } from "firebase/firestore";

interface Child {
  entityInfo: EntityInfo;
  parentData: any;
  commentData: any;
  replyBoxIsOpen: boolean;
  toggleShowReplyBox: Function;
  displayComment?: boolean;
}

export const ChildComment = 
  ({ 
    entityInfo,
    parentData,
    commentData,
    replyBoxIsOpen,
    toggleShowReplyBox,
    displayComment = false,
  }: Child) => {

    const { 
      timestampUTC, 
      userData, 
      content, 
      id, 
      supporting,
    } = commentData;

    // This is a patch for a legacy data model - this info now
    // comes from commentData.supporting
    const supported = commentData?.subjectData?.supported;

    const { username, photoURL } = userData || '';

    const [replyBtnIsOpen, toggleShowReplyBtn] = useState(false);

    const [children, updateChildren] = useState<any[]>([]);

    const [viewChildren, toggleChildren] = useState(displayComment);

    
  const [numOfChildren, updateNumOfChildren] = useState(0);

  const countChildren = (children: any) => {
    let currentCount = children.length;

    for (let i = 0; i < children.length; i++){
      currentCount += children[i].numOfChildren;
    }
    return currentCount;
  }

  useEffect(() => {
    updateNumOfChildren(countChildren(children));
  }, [children]);

    useEffect(() => {
      if (replyBtnIsOpen && !replyBoxIsOpen) toggleShowReplyBtn(false);
    }, [replyBoxIsOpen, replyBtnIsOpen]);

    const snapshotObserver = (snapshot: any) => {
      const changes = snapshot.docChanges();

      const incomingChildren: any[] = [];

      changes.forEach( (change: any) => {
        incomingChildren.push(change.doc.data());
      });

      updateChildren(prevState => [...prevState, ...incomingChildren]);
    }

    useEffect(() => {
      const unsubscribe = 
        onSnapshot(
          grandchildCommentsQuery(parentData.id, commentData.id), 
          snapshotObserver
        );
        
      return unsubscribe;
    }, [commentData.id, parentData.id]);

    const toggleReply = () => {
      toggleShowReplyBtn(!replyBtnIsOpen);
      toggleShowReplyBox(!replyBoxIsOpen);
    }

    const renderGrandchildren = () => {
      console.log("rendering grandchildren...");
      return children?.map(data => (
        <GrandchildComment
          key={data.timestampUTC}
          ancestorData={{ grandParent: parentData, parent: commentData }}
          commentData={data}
          entityInfo={entityInfo}
          replyBoxIsOpen={replyBoxIsOpen}
          toggleShowReplyBox={toggleShowReplyBox}
          parentFunctions={{ updateChildren }}
        />
      ));
    }

    const toggleShowChildren = () => {
      toggleChildren(!viewChildren);
    }
  
    const toggleViewCommentsJSX = (numOfChildren: number) => {
      // const numOfChildren = countChildren(children);
  
      return children.length > 0 && !displayComment && !viewChildren ?
        <span
          className={'view-comments-option child-wrap'}
          onClick={toggleShowChildren}
        >
          {`View ${numOfChildren} Comment${numOfChildren > 1 ? 's' : ''}`}
        </span> : 
        null
    }

    return (
      <div>
        <div className="top-wrap child-wrap">
          <p className="profile-feed-img-container">
            <img 
              /* small img profile */
              className="feed-comment-profile-img"
              src={ photoURL || "/assets/default-img.jpg" }
              alt="profile-avatar"
            />
          </p>

          <div className="comment-wrap">
            <div className="comment-wrap-info">
              <div className="comment-wrap-info-inner">
                <p>{username}</p>
                <p>{hoursAgo(timestampUTC)}</p>
                {displaySupport(supported !== undefined ? supported : supporting)}
              </div>
              <div className={`feed-comment-reply ${replyBtnIsOpen && replyBoxIsOpen && 'greyed-out'}`}>
                <img
                  src="/img/icons/reply.svg"
                  alt="reply"
                  className="reply-icon"
                />
                  <span onClick={toggleReply}>Reply</span>
              </div>
            </div>
            <section className="comment-content">
              {content}
            </section>
          </div>

        </div>
        {/*
            Need to add toggleChildren? to reply?
        */}

        <ReplyToCommentForm
          data={{
            commentData,
            entityInfo,
            id,
            isChild: true,
            isGrandchild: false,
            parentData,
            grandparentData: null
          }}
          replyBoxIsOpen={replyBoxIsOpen}
          replyBtnIsOpen={replyBtnIsOpen}
          toggleReply={toggleReply}
          parentUpdateFunctions={{ updateChildren }}
        />

        {/* This option should only appear if the comment has children */}
        {viewChildren && renderGrandchildren()}

        {toggleViewCommentsJSX(numOfChildren)}

      </div>
    )
  }
