import { 
  Comment, 
  EntityInfo 
} from "../../../interfaces/comment-entities.interfaces";

import { 
  // userFollowersCollectionRef,
  storiesCollectionRef,
  commentsCollectionRef,
  firestore
} from "../../instance";

import { 
  collection,
  doc, 
  // DocumentData, 
  increment,
  runTransaction
} from "firebase/firestore";

interface GrandChildCommentParam {
  grandchildData: Comment;
  parentData: Comment;
  grandparentData: Comment;
  entityInfo: EntityInfo;
}

export const handleAddGrandchildComment = 
  async ({ grandchildData, grandparentData, parentData, entityInfo }: GrandChildCommentParam) => {

    console.log({ grandchildData, parentData, grandparentData, entityInfo }, "<--- within grandchild");

    const { id: entityId, type: entityType } = entityInfo;
    const { id: parentID } = parentData;
    const { id: grandParentID } = grandparentData;
    // const { userId: grandParentUserId } = grandparentData.userData;
    
    // const grandParentUserFollowersRef = doc(userFollowersCollectionRef, grandParentUserId);

    const storiesCollectionDocRef = 
      entityId && entityType === 'story' 
      ? doc(storiesCollectionRef, entityId) 
      : null;

    const parentDocRef = 
      doc(collection(doc(commentsCollectionRef, grandParentID), 'childComments'), parentID);
      
    const newGrandChildDoc = doc(collection(parentDocRef, 'grandchildComments'));

    grandchildData.id = newGrandChildDoc.id;

    await runTransaction(firestore, async t => {
      // const grandParentUserFollowerDoc = await t.get(grandParentUserFollowersRef);

      /* add a new doc to sub-collection (grandchildComments) of child comment  */
      t.set(newGrandChildDoc, grandchildData);
      /* increment number of grandchildren on child document */
      t.update(parentDocRef, { numOfChildren: increment(1) });

      if (storiesCollectionDocRef) {
        t.update(storiesCollectionDocRef, {
            numOfComments: increment(1),
          }
        );
      }
      /*
        Find parent comments user-followers document - iterate over recent comments &
        find commentId - iterate over childComments & find childComment -
        finally, update childComment numOfGrandChildren field to match new value.

        Update entire parent comments user-followers document
      */
      // const grandParentUserFollowerDocData: DocumentData | undefined = grandParentUserFollowerDoc.data();
      

      // const parentCommentData1 = grandParentUserFollowerDocData?.recentComments
      //   .find((com:any) => com.id === grandParentID);

      // const parentCommentData = parentCommentData1
      //   .childComments
      //   .find((com:any) => com.id === parentID);

      // We are
      // Number.isInteger(parentCommentData.numOfChildren) ? 
      //   parentCommentData.numOfChildren += 1 : parentCommentData.numOfChildren = 1;
      
      // t.update(grandParentUserFollowersRef, grandParentUserFollowerDocData);
    });
  }
