import { useState, useEffect } from 'react';
import TextInput from '../../../shared/text-input/TextInput';
import OnboardingCardLayout from '../card-layout/OnboardingCardLayout';
import './EnterPhoneNum.css';
import { auth } from '../../../../firebase/instance';
import { PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { updateAuthState } from '../../../../redux/slices/sign-up/sign-up.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux-hooks';
import { RootState } from '../../../../redux/store';

interface Param {
  onNext: Function;
}

const EnterPhoneNum = ({ onNext }: Param) => {

  const [phoneState, updatePhoneState] = useState('');
  const signUp = useAppSelector((state: RootState) => state.signUp);
  const dispatch = useAppDispatch();

  const [recaptchaId, setRecaptchaId] = useState<string>('');
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);

  const checkValid = () => {
    return (
      phoneState.length === 10 && 
      recaptchaId !== null && 
      recaptcha !== null
    );
  };

  const handleOnClick = () => {
    const phoneAuth = new PhoneAuthProvider(auth);
    const phoneNum = `+1${phoneState}`;

    phoneAuth.verifyPhoneNumber(phoneNum, recaptcha as RecaptchaVerifier)
      .then((verificationId) => {
        dispatch(
          updateAuthState({
            phoneNumber: phoneNum,
            verificationId: verificationId
          })
        )
        onNext();
      })
      .catch(error => console.log("Bad Code: " + error));
  };

  useEffect(() => {
    async function recaptchaFlow() {
      const recaptcha: RecaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container', {}, auth
      )
      setRecaptcha(recaptcha);
      const recaptchaId = await recaptcha.verify();
      setRecaptchaId(recaptchaId);
    }

    recaptchaFlow();
  }, [signUp.retryCount]);

  const headerText = "ENTER YOUR MOBILE NUMBER TO VERIFY YOUR ACCOUNT";
  const buttonLabel = "SEND TEXT";

  return (
    <OnboardingCardLayout
      headerText={headerText}
      content={
        <div>
          <img
            className="onboarding-svg"
            alt="verify"
            src="/img/onboarding/verify.svg"
          />
          <p className="sub-title">
            CONNECT AND SHARE YOUR PROSPECTIVE WITH A VERIFIED NETWORK
          </p>
          <TextInput
            placeholder="(000) 000-0000"
            type="tel"
            value={phoneState}
            onTextInputChange={(event) => {
              updatePhoneState(event.target.value);
            }}
          />
          <p className="under-input-text">VERIFIED THROUGH TEXT</p>
          <div id="recaptcha-container"></div>
        </div>
      }
      displayBackBtn={false}
      buttonParams={
        { label: buttonLabel, onClick: handleOnClick, disabled: !checkValid() }
      }
    />
  );
};

export default EnterPhoneNum;
