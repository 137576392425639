import "./ProfileImage.css";
import Badge from "./Badge";

const ProfileImage = ({ party, defaultImage }: any) => {
  return (
    <div className="image-container">
      <img
        src={ defaultImage || "/assets/default-img.jpg" }
        alt="default profile"
        className="profile-image"
      />
      <Badge party={party} />
    </div>
  );
};

export default ProfileImage;
