import { forwardRef } from "react";
import { UnknownObject } from "../../../interfaces/global";

interface Props {
  ref: any;
  children: any;
  style?: UnknownObject;
}

const FeedWrap = forwardRef<HTMLDivElement, Props>(({ children, style }: Props, ref) => {
  // const refUsed = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   // console.log({ refUsed: refUsed.current?.offsetTop });
  //   // console.log({ refUsed },"<--- before....", !!refUsed.current)
  //   // if (refUsed.current) {
  //     console.log("adding...")
  //     document.addEventListener('scroll', (e) => {
  //       console.log({ refUsed: refUsed.current?.offsetTop });
  //     });
  //   // }
  // }, []);

  return <div ref={ref} className='feed-wrapper' style={style}>
    { children }
  </div>
})

export default FeedWrap;