import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RetrievedComment } from '../../../interfaces/comment-entities.interfaces';
import { UnknownObject } from '../../../interfaces/global';
// import { saveCommentsFeed } from '../../thunks/comments/comments.thunks';

export interface LastComment {
  id: string;
  lastComment: number;
}

interface CommentsState {
  commentsFeed: RetrievedComment[];
  duplicateDataById: UnknownObject;
  lastComment: LastComment | null;
}

const initialState: CommentsState = {
  commentsFeed: [],
  duplicateDataById: {},
  lastComment: null
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    fetch_comments_feed: ( state: any, action: PayloadAction<UnknownObject> ) => {
      return {
        ...state,
        commentsFeed: action.payload.commentsFeed
      };
    },
    update_comments_feed: (state, action) => {
      state.commentsFeed = action.payload as any[];
    },
    update_comments_with_duplicate_data: (state, action) => {
      const { duplicateDataById, comments } = action.payload;
      state.duplicateDataById = duplicateDataById;
      state.commentsFeed = comments;
    },
    saveCommentsFeedWithLastComment: (state, action) => {
      const { comments, lastComment, duplicateDataById } = action.payload;
      if (lastComment) {
        state.lastComment = lastComment;
        state.commentsFeed.push(...comments);
      } else {
        state.lastComment = lastComment;
        state.commentsFeed = comments as any[];
      }
      state.duplicateDataById = duplicateDataById;
    }
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(saveCommentsFeed.fulfilled, (state, action) => {
  //       const { comments, lastComment } = action.payload;
  //       if (lastComment) {
  //         state.lastComment = lastComment;
  //         state.commentsFeed.push(...comments)
  //       } else {
  //         state.lastComment = lastComment;
  //         state.commentsFeed = comments as any[];
  //       }
  //     })
  // }
})

export const { 
  fetch_comments_feed, 
  update_comments_feed, 
  update_comments_with_duplicate_data,
  saveCommentsFeedWithLastComment
} = commentsSlice.actions;

export default commentsSlice.reducer;
