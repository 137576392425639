import { ENTITY_TYPE } from "../../../constants/entities";
import { RetrievedCommentData } from "../../../interfaces/comment-entities.interfaces";
import PropositionCard from "../../ballot/proposition/PropositionCard";
import PoliticianFeedComment from "../../comments/comment-types/PoliticianFeedComment";
import Story from "../../story/Story";
import FeedCommentContainer from "../feed.comments/FeedCommentContainer";


interface StateManagers {
  replyBoxIsOpen: boolean;
  toggleShowReplyBox: Function;
}

interface CommentJsx {
  data: any;
  stateManagers: StateManagers,
  idx: number;
}


const CreateCommentJsx = ({ data, stateManagers, idx }: CommentJsx) => {
  const { replyBoxIsOpen, toggleShowReplyBox } = stateManagers;
  const { comment, entity } = data;
  const { entityInfo } = comment;
  const { type } = entityInfo;

  // Need to get a Comment out of UserFollowersComment...
  return <FeedCommentContainer
      replyBoxIsOpen={replyBoxIsOpen} 
      toggleShowReplyBox={toggleShowReplyBox} 
      key={comment.id} 
      commentData={comment as RetrievedCommentData}
      entityInfo={entityInfo}
    >
      { 
        type === ENTITY_TYPE['politician'] ?
          <PoliticianFeedComment
            data={data} 
          /> : 
        type === ENTITY_TYPE['proposition'] ?
          <PropositionCard
            uniqueData={entity.appData.uniqueData}
            proposition={entity.entityData}
            isFeedComment={true}
          /> :
        type === ENTITY_TYPE['story'] ?
          <Story
            data={entity}
            idx={idx}
            isFeedComment={true}
            isComment={true}
          /> :
        null
      }
    </FeedCommentContainer>
}

export default CreateCommentJsx;