import EditLayout from "../../shared/text-input/EditLayout";

interface Param {
  label: string;
  placeholder: string | null | undefined;
  onChange: any;
}

const EditEmail = ({ label, placeholder, onChange }: Param) => {
  return (
    <EditLayout
      label={label}
      content={
        <input
          type="email"
          className="edit-text-input"
          placeholder={placeholder || ''}
          onKeyUp={(e: any) => {
            onChange(e.target.value.trim());
          }}
        />
      }
    />
  );
};

export default EditEmail;
