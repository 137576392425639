import { processStoryIdForDB } from "../../../util/util.functions";

interface UpdateFeedParam {
  currentFeed: any[];
  appData?: any[];
}

interface UpdateFeedDataParam {
  feed: any[];
  feedItemsChanged: any[];
}

export const updateFeedAppData = ({ currentFeed, appData }: UpdateFeedParam) => {
  // We will base this off the storyId
  // This is suppose to be for when there is a story that doesn't have app data
  let appDataToTable: any = {}
  if (appData) {
    appData.reduce((acc, cur) => {
      acc[cur.storyId] = cur;
      return acc;
    }, appDataToTable);
  }

  if (Array.isArray(currentFeed)) {
    currentFeed.forEach(story => {
      if(story.appData && appDataToTable[story.appData.id]) {
        story.appData = appDataToTable[story.appData.id];
      } else {
        const processedId = processStoryIdForDB(story.url);
        if(appDataToTable[processedId]) {
          story.appData = appDataToTable[processedId];
        }
        if(!story.appData) {
          story.appData = { id: processedId }
        }
      }    
    });
  }

  return currentFeed;
}

export const updateFeedData = ({ feed, feedItemsChanged }: UpdateFeedDataParam) => {
  const feedToTable = feed.reduce((acc, cur, idx) => {
    if (cur.appData && cur.appData.id) {
      acc[cur.appData.id] = cur;
    } else {
      acc[idx] = cur;
    }
    return acc;
  }, {});
  
  feedItemsChanged.forEach((item) => {
    const { appData } = item;
    if (feedToTable[appData.id]) {
      feedToTable[appData.id] = item;
    }
  });

  return Object.values(feedToTable);
} 
