import { ChangeEventHandler, DetailedHTMLProps } from "react";
import "./TextInput.css";
import ErrorText from "../error-text/ErrorText";

interface TextInputParam extends DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  styleColor?: string;
  type?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  onTextInputChange: ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
  errorMessageColor?: string;
  pattern?: string;
}

const TextInput = ({
  styleColor,
  type="text",
  name,
  value,
  placeholder,
  onTextInputChange,
  errorMessage,
  errorMessageColor,
  onKeyDown,
  pattern,
  inputMode='text',
  autoComplete
}: TextInputParam) => {
  const className =
    styleColor === "light" ? "text-input-light" : "text-input-dark";

  return (
    <div className="text-input-container">
      <input
        className={`text-input ${className}`}
        placeholder={placeholder}
        onInput={onTextInputChange}
        onChange={onTextInputChange}
        onKeyDown={onKeyDown}
        type={type}
        pattern={pattern}
        name={name}
        value={value}
        inputMode={inputMode}
        autoComplete={autoComplete}
      />
      <ErrorText
        errorMessage={errorMessage}
        errorMessageColor={errorMessageColor}
      />
    </div>
  );
}

export default TextInput;
