import Section from '../../ballot/shared/Section';
import PoliticianListProfile from '../../politician/politician-profile/PoliticianListProfile';
import './politicianFeedComment.css'
import { Link, useLocation } from 'react-router-dom';

const PoliticianFeedComment = ({ data }: any ) => {
  const previousLocation = useLocation().pathname;
  const { entity } = data;
  const { entityData } = entity;
  const { officeName } = entityData;

  return (
    <Section
      isComment={true}
      executive={false}
      title={officeName}
      items={[entityData]}
      // renderItem takes an official and render with Person
      renderItem={(official: any, index: number) => {
        return (
          <PoliticianListProfile
            key={index}
            entityData={official}
            name={official.name}
            party={official.party}
            action={
              <Link
                to={`/politician-profile/${official.name}`}
                className="view-politician-link"
                state={{ previousLocation }}
              >
                Learn More
              </Link>
            }
          />
        );
      }}
    />
  )
}

export default PoliticianFeedComment;
