import { useState } from "react";
import ProfileImage from "../politician-profile/ProfileImage";
import { fetchDefaultImage } from "../../../api/api.wikipedia";
import useOnce from "../../../hooks/useOnce";

import "./Person.css";

interface PersonState {
  defaultImage: string | null;
  error: string | boolean;
}

const Person = ({ name, description, action, party, photoURL }: any) => {
  const [state, setState] = useState<PersonState>({
    defaultImage: photoURL,
    error: false,
  });

  useOnce(() => {
    if (name && !photoURL) {
      fetchDefaultImage(name)
        .then(defaultImage => {
          setState({ ...state, defaultImage });
        }).catch(err => {
          setState({ ...state, error: "No result." });
        });
    }
  });

  return (
    <li className="person-wrapper">
      <ProfileImage party={party} defaultImage={state.defaultImage} />
      <div className="content-and-btn">
        <div className="content-wrapper">
          <h4 className="content-header">{name}</h4>
          {description !== undefined ? <p>{description}</p> : null}
        </div>
        {/* action button */}
        {action !== undefined ? action : null}
      </div>
    </li>
  );
};

export default Person;