import { EventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { UnknownObject } from "../../../interfaces/global";
import "./PreviousAndButton.css";

interface Param {
  label: string;
  onClickButton?: EventHandler<any>;
  route?: string;
  style?: UnknownObject;
}

const PreviousAndButton = ({ label, onClickButton, route, style }: Param) => {
  const navigate = useNavigate();
  const goBack = () => route ? navigate(route) : navigate(-1);
  return (
    <div className="previous-and-edit-container">
      <img src="/img/previous.svg" onClick={goBack} alt="go-back-icon" />
      { 
        onClickButton ?
        <button style={style || {}} onClick={onClickButton} className="header-button">
          {label}
        </button> : 
        null 
      }
    </div>
  );
};

export default PreviousAndButton;
