import PrimaryButton from "../../../shared/primary-button/PrimaryButton";
import "./OnboardingCardLayout.css";
import HeaderText from "../../../shared/header-text/HeaderText";
import { PrimaryBtnParam } from "../../../shared/primary-button/PrimaryButton";
import { MouseEventHandler, ReactNode } from "react";

interface Param {
  buttonParams: PrimaryBtnParam;
  content: ReactNode;
  displayBackBtn?: boolean;
  headerText: string;
  onArrowClick?: MouseEventHandler<HTMLDivElement>;
}

const OnboardingCardLayout = ({
  onArrowClick,
  headerText,
  content,
  buttonParams,
  displayBackBtn=true,
}: Param) => {
  return (
    <div className="onboarding-card-layout-wrapper">
      {displayBackBtn && (<div className="previous-arrow-container">
        <div className="previous-arrow" onClick={onArrowClick}></div>
      </div>)}
      <div className="onboarding-card-container">
        <HeaderText styleColor="dark" headerText={headerText} />
        <div className="onboarding-card-content">{content}</div>
        <div className="onboarding-button-wrapper">
          <PrimaryButton
            {...buttonParams}
          />
        </div>
      </div>
    </div>
  );
}

export default OnboardingCardLayout;