import { track } from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom';
import { UserCredential } from 'firebase/auth';

import UsernameAndPassword from '../onboarding/username-password/UsernameAndPassword';
// import EnterPhoneNum from '../onboarding/enter-phone-num/EnterPhoneNum';
// import EnterVeriCode from '../onboarding/enter-veri-code/EnterVeriCode';
import InterestSelection from '../onboarding/interest-selection/InterestSelection';
import MediaSelection from '../onboarding/media-selection/MediaSelection';
import NameAndEmail from '../onboarding/name-and-email/NameAndEmail';
import UserLocation from '../onboarding/user-location/UserLocation';

import { authUtils } from '../../../firebase/instance';
import { FEED } from '../../../constants/routes';
import store, { RootState } from '../../../redux/store';
import { signUpBatchReq } from '../../../firebase/instance/auth-utils';
import { 
  updateSignUpState as updateState,
  incrementStep,
  SignUpState
} from '../../../redux/slices/sign-up/sign-up.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/redux-hooks';
import './SignUp.css';
import EnterVeriCode from '../onboarding/enter-veri-code/EnterVeriCode';
import EnterPhoneNum from '../onboarding/enter-phone-num/EnterPhoneNum';

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state: SignUpState = useAppSelector((state: RootState) => state.signUp);

  const retryPhoneAuth = () => {
    const retryCount = state.retryCount;
    if (retryCount < 1) {
      dispatch(updateState({ currentStep: 6, retryCount: retryCount + 1 }));
    }
  };

  const onNext: Function = () => {
    dispatch(incrementStep());
    const newState = store.getState();
    checkStep(newState.signUp);
  }

  const checkStep: Function = (newState: SignUpState) => {
    const nextStep = newState.currentStep;
    if (nextStep === 8) {
      console.log(
        "New User: ",newState.user,
      );
      
      createAccount(newState)
        .then(authUser => {
          const { photoURL, emailVerified, uid } = authUser;
          const userAuthData = { photoURL, emailVerified, uid };
          dispatch(updateState({
            currentStep: 7,
            userAuthData
          }));
        })
        .catch(error => {
          dispatch(updateState({
            currentStep: 1,
            error: { emailInUseError: error.message },
          }));
        });
    } else if (nextStep >= 7){
      // Check auth State
      navigate(FEED);
    }
  };

  const createAccount = async (newState: SignUpState) => {
    const { email, password } = newState.authState;
    return authUtils
      .doCreateUserWithEmailAndPassword(email, password)
        .then((authUser: UserCredential) => {
          // debugger
          signUpBatchReq(authUser.user.uid, newState);
          return authUser.user;
        });
  };

  const onSubmit = (evt: any) => {
    evt.preventDefault();
  };

  track("signUpDisplayed");
  
  return (
    <form onSubmit={onSubmit} className="signup-form-container">
      
      {state.currentStep === 1 ? (
        <NameAndEmail
          onNext={onNext}
          emailInUseError={state.error.emailInUseError}
        />
      ) : null}

      {state.currentStep === 2 ? (
        <UsernameAndPassword
          onNext={onNext}
        />
      ) : null}

      {state.currentStep === 3 ? (
        <UserLocation 
          onNext={onNext} 
        />
      ) : null}

      {state.currentStep === 4 ? (
        <MediaSelection 
          onNext={onNext} 
        />
      ) : null}

      {state.currentStep === 5 ? (
        <InterestSelection
          onNext={onNext}
        />
      ) : null}

      {state.currentStep === 6 ? <EnterPhoneNum onNext={onNext} /> : null}

      {state.currentStep === 7 ? (
        <EnterVeriCode
          onNext={onNext}
          retryCount={state.retryCount}
          retryPhoneAuth={retryPhoneAuth}
        />
      ) : null}

    </form>
  );
}

export default SignUp;