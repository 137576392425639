import { MouseEventHandler } from "react";
import { PerigonEntityData } from "../../../interfaces/comment-entities.interfaces";
import '../story.css';

interface Param {
  entityData: PerigonEntityData;
  updateIframeRender: MouseEventHandler<HTMLDivElement>;
  idx: number | undefined;
}

const NewsTitle = ({ entityData, updateIframeRender, idx }: Param) => (
  <div className="news-title-container" onClick={updateIframeRender}>
    <h5 className="news-title" key={idx}>
      <a className="news-title-and-link" href={entityData.url || "#"}>
        {entityData.title} 
      </a>
    </h5>
  </div>
)

export default NewsTitle;