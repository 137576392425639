import "./PoliticianPage.css";
import Section from "../../ballot/shared/Section";
import { Link, useLocation } from "react-router-dom";
import PoliticianListProfile from "./PoliticianListProfile";
// import { fetchPoliticianByName } from "../../../firebase/politician-ballot/fetch-politicians";
// import ActionButton from "./ActionButton";

interface Param {
  executive?: boolean | undefined;
  offices: any[];
}

const PoliticianPage = ({ executive, offices }: Param) => {
  const previousLocation = useLocation().pathname;
  // Get officialFromDb for each - need ticker data
  return (
    <div className="section-wrapper">
      {offices.map((office, idx) => {
        return (
          <Section
            executive={executive}
            key={idx}
            title={office.name}
            items={office.officials}
            // renderItem takes an official and render with Person
            renderItem={(official: any, index: number) => {

              return (
                <PoliticianListProfile
                  key={index}
                  entityData={official}
                  name={official.name}
                  party={official.party}
                  action={
                    <Link
                      to={`/politician-profile/${official.name}`}
                      className="view-politician-link"
                      state={{ previousLocation }}
                    >
                      Learn More
                    </Link>
                  }
                />
              );
            }}
          />
        );
      })}
    </div>
  );
};

export default PoliticianPage;
