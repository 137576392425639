import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnknownObject } from '../../../interfaces/global';
import { 
  accountEntitySupport,
  updateProfilePicture, 
  updateRecentActivity, 
  updateUserAccountAsync 
} from '../../thunks/account/account.thunks';

export interface AccountState {
	fullname: null | string;
	username: string;
	title?: null | string;
	about?: null | string;
	zipcode: null | string | number;
	medias: string[];
	interests: string[];
	email: null | string;
	userId: string;
	following: string[];
	isLoading?: boolean;
	isUpdating?: boolean;
	photoURL: null | string;
  politiciansFollowing?: string[];
	politiciansSupporting: string[];
	politiciansOpposing: string[];
	propositionsSupporting: string[];
	propositionsOpposing: string[];
	storiesSupporting: string[];
	storiesOpposing: string[];
  recentActivity?: any[];
}

export enum AccountSupportKeys {
  politiciansSupporting = "politiciansSupporting",
  politiciansOpposing = "politiciansOpposing",
  propositionsSupporting = "propositionsSupporting",
  propositionsOpposing = "propositionsOpposing",
  storiesSupporting = "storiesSupporting",
  storiesOpposing = "storiesOpposing",
}

const initialState: AccountState = {
  fullname: null,
  username: "",
  title: null,
  about: null,
  zipcode: null,
  medias: [],
  interests: [],
  email: null,
  userId: '',
  following: [],
  politiciansFollowing: [],
  photoURL: null,
  politiciansSupporting: [],
  politiciansOpposing: [],
  propositionsSupporting: [],
  propositionsOpposing: [],
  storiesSupporting: [],
  storiesOpposing: [],
  recentActivity: []
}

const initialStateCopy = JSON.stringify(initialState);


/*
  createSlice - will automatically generate action creators
    state.actions.todoAdded
  createSlice - allows us to safely "mutate" our safe

  name is used to generate action types
*/
export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    fetch_user: ( state, action: PayloadAction<AccountState> ) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    },
    reset_account_data: () => {
      return JSON.parse(initialStateCopy);
    },
    start_account_update: ( state, action: PayloadAction<AccountState> ) => {
      return {
        ...state,
        ...action.payload,
        isUpdating: false
      }
    },
    update_user_account: ( state, action: PayloadAction<AccountState | UnknownObject> ) => {
      return {
        ...state,
        ...action.payload
      }
    },
    upload_profile_picture: ( state, action: PayloadAction<AccountState> ) => {
      state.photoURL = action.payload.photoURL
    },
    update_user_followers: ( state, action: PayloadAction<{ userId: string, isFollowing: boolean }> ) => {
      const { userId, isFollowing } = action.payload;

      if (state.following.includes(userId) && !isFollowing) {
        state.following = state.following.filter(id => id !== userId);
      } else if (isFollowing) {
        state.following.push(userId);
      }
    },
    update_recent_activity: ( state, action: PayloadAction<{ feed: any[]}> ) => {
      const { feed } = action.payload; 
      state.recentActivity = feed;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(accountEntitySupport.fulfilled, (state, action) => {
        const { supportKey, opposeKey, newState } = action.payload;
        state[supportKey] = newState[supportKey];
        state[opposeKey] = newState[opposeKey];
      })
      .addCase(updateProfilePicture.fulfilled, (state, action) => {
        state.photoURL = action.payload;
      })
      .addCase(updateUserAccountAsync.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
      .addCase(updateRecentActivity.fulfilled, (state, action) => {
        const { feed } = action.payload;
       
        state.recentActivity = feed;
      })
  }
});

// Create slice is automatically generating action creators
export const { 
  fetch_user, 
  reset_account_data,
  start_account_update,
  update_user_account,
  upload_profile_picture,
  update_user_followers,
  update_recent_activity
} = accountSlice.actions;

export default accountSlice.reducer;
