import "./PropositionCard.css";
import { Link } from "react-router-dom";
import { UnknownObject } from "../../../interfaces/global";
import { handleSupport } from "../../../firebase/shared/handle-support";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/redux-hooks";
import { ENTITY_TYPE } from "../../../constants/entities";
import CommentAndSupport from "../../shared/comment-support/CommentAndSupport";
import { useEffect, useState } from "react";
import { fetchPropositionTickerDataById } from "../../../firebase/politician-ballot/fetch-proposition-data";
import { set_ticker_data } from "../../../redux/slices/ballot/ballot.slice";
import { CommentWindow } from "../../comments/comment-window/CommentWindow";

interface Param {
  congress?: boolean;
  proposition: any;
  isFeedComment?: boolean;
  uniqueData?: UnknownObject;
  backRoute?: string;
}

interface TickerData {
  supporting: number;
  opposing: number;
  id: string | null;
  numOfComments: number;
}

const PropositionCard = ({
  congress,
  proposition,
  isFeedComment = false,
  uniqueData,
  backRoute
}: Param) => {
  const userAccount = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();

  const { propositionsOpposing, propositionsSupporting } = userAccount;
  const addMargin = congress ? " congress-margin" : " default-margin";

  const { title, sponsorParty } = proposition;

  let id = (congress || proposition?.billId) ? proposition.billId : proposition.id;

  const jurisdiction = uniqueData ? 
    uniqueData.jurisdiction : 
    congress ? 
    "Federal" : 
    undefined;

  const tickerData = useAppSelector(state => state.ballot[id]);

  const entityInfo = { id, type: ENTITY_TYPE['proposition'] };

  const userSupportsProposition = () => 
    propositionsSupporting.includes(id) ? true : 
    propositionsOpposing.includes(id) ? false : null;

  const [state, setState] = useState({
    numOfChar: 0,
    showOptionModal: false,
    showCommentWindow: false,
  });

  const [ newCommentAdded, trackCommentAdded ] = useState(false);


  const [update, updating] = useState<Boolean>(true);
  const localStateRefresh = () => updating(prev => !prev);
  
  const onSupport = () => {
    const userSupports = propositionsSupporting.includes(id);
    console.log({ entityInfo, proposition })
    handleSupport({
      entityInfo,
      entityData: proposition,
      userAccount,
      doesSupport: !userSupports || null,
      localStateRefresh,
      dispatch
    })
  }

  const onOppose = () => {
    const userOpposes = propositionsOpposing.includes(id);
    handleSupport({
      entityInfo,
      entityData: proposition,
      userAccount, 
      doesSupport: userOpposes && null,
      localStateRefresh,
      dispatch
    })
  }

  useEffect(() => {
    const getAppData = async () => {
      const data = await fetchPropositionTickerDataById(id) as any;
      if (data.id) {
        dispatch(set_ticker_data(data));
      }
    }
    getAppData();
  }, [id, dispatch, update, proposition]);

  const userInteractionJsx = (tickerData: TickerData, proposition: any) => {
    return  (
      <div className="user-interaction-container">      
        <CommentAndSupport
          userSupportsEntity={userSupportsProposition()} 
          handleSupport={onSupport} 
          handleOppose={onOppose}
          openCommentWindow={() => setState( (prev: any) => ({ ...prev, showCommentWindow: true }) )}
          theme={"transparent"}
          tickerData={tickerData}
          entityData={proposition}
        />
        {/* view all comments link */}
      </div> 
    )
  }
  return (
    <div className={`div-card${addMargin}`} >
      {(isFeedComment && id) && <p>{jurisdiction} Bill</p> }
      {(isFeedComment && !id) && <p>{jurisdiction} Bills</p>}
      <h2 className="title">{title}</h2>
      {congress ? (
        <>
          <p className="name" style={{ marginBottom: '10px' }}>{sponsorParty}</p>
          <Link
            to={`/bill-profile/${id}/info`}
            className="view-politician-link view-proposition-link"
            state={{ isFeedComment, backRoute }}
          >
            Learn More
          </Link>
          {userInteractionJsx(tickerData, proposition)}
        </>
      ) : (
        <>
          <Link
            to={`/proposition-profile/${id}/info`}
            state={{ isFeedComment, jurisdiction, proposition, backRoute }}
            className="view-politician-link view-proposition-link"
          >
            Learn More
          </Link>
          {userInteractionJsx(tickerData, proposition)}
        </>
      )}
       { state.showCommentWindow ?
        <CommentWindow
          closeCommentWindow={() => setState(prev => ({ ...prev, showCommentWindow: false }))}
          doesSupport={userSupportsProposition()}
          entityData={proposition}
          entityInfo={entityInfo}
          handleOppose={onOppose}
          handleSupport={onSupport}
          newCommentAdded={newCommentAdded}
          trackCommentAdded={trackCommentAdded}
          uniqueData={uniqueData}
        /> : null }
      <div>
        {
          tickerData?.numOfComments ? 
            <Link to={`/comments/politician/${id}`} className="view-comment-container">
              View {tickerData.numOfComments} {tickerData.numOfComments > 1 ? 'comments' : 'comment'}
            </Link>
            : null
        }
      </div>
    </div>
  );
};

export default PropositionCard;
