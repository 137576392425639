import { useEffect, useState } from "react"
import ReplyToCommentForm from "./ReplyToCommentForm";
import { hoursAgo, displaySupport } from "../util/comments.util";
import './comments.container.css';
import './comment.css';
import { EntityInfo } from "../../../interfaces/comment-entities.interfaces";
import { UnknownObject } from "../../../interfaces/global";

interface Grandchild {
  ancestorData: any;
  commentData: any;
  entityInfo: EntityInfo;
  replyBoxIsOpen: boolean;
  toggleShowReplyBox: Function;
  parentFunctions: UnknownObject;
}

const GrandchildComment =
  ({ 
    ancestorData,
    commentData,
    entityInfo,
    replyBoxIsOpen, 
    toggleShowReplyBox,
    parentFunctions
  }: Grandchild) => {
  
    const { 
      timestampUTC, 
      userData, 
      content, 
      // id, 
      supporting,
    } = commentData;

    const {
      grandParent,
      parent
    } = ancestorData;

    const { updateChildren } = parentFunctions;

    // This is a patch for a legacy data model - this info now
    // comes from commentData.supporting
    const supported = commentData?.subjectData?.supported;

    const { username, photoURL } = userData || '';

    const [replyBtnIsOpen, toggleShowReplyBtn] = useState(false);
        
    useEffect(() => {
      if (replyBtnIsOpen && !replyBoxIsOpen) toggleShowReplyBtn(false);
    }, [replyBoxIsOpen, replyBtnIsOpen]);

    const toggleReply = () => {
      toggleShowReplyBtn(!replyBtnIsOpen);
      toggleShowReplyBox(!replyBoxIsOpen);
    }
  
    return (
      <div>
        <div className="top-wrap grandchild-wrap-2">
          <p className="profile-feed-img-container">
            <img 
              /* small img profile */
              className="feed-comment-profile-img"
              src={ photoURL || "/assets/default-img.jpg" }
              alt="profile-avatar"
            />
          </p>

          <div className="comment-wrap">
            <div className="comment-wrap-info">
              <div className="comment-wrap-info-inner">
                <p>{username}</p>
                <p>{hoursAgo(timestampUTC)}</p>
                {displaySupport(supported !== undefined ? supported : supporting)}
              </div>
              <div className={`feed-comment-reply ${replyBtnIsOpen && replyBoxIsOpen && 'greyed-out'}`}>
                <img
                  src="/img/icons/reply.svg"
                  alt="reply"
                  className="reply-icon"
                />
                  <span onClick={toggleReply}>Reply</span>
              </div>
            </div>
            <section className="comment-content">
              {content}
            </section>
          </div>

        </div>
        {/*
          Need to add toggleChildren? to reply?
        */}

        <ReplyToCommentForm
          data={{
            commentData,
            entityInfo,
            isChild: false,
            isGrandchild: true,
            parentData: parent,
            grandparentData: grandParent
          }}
          replyBoxIsOpen={replyBoxIsOpen}
          replyBtnIsOpen={replyBtnIsOpen}
          toggleReply={toggleReply}
          parentUpdateFunctions={{ updateChildren }}
        />
      </div>
    )
  }

export default GrandchildComment;