import { useEffect, useMemo, useState } from "react";
import useOnce from "../../../hooks/useOnce";
// import BillAndPropsLayout from "../../bill-profile/BillAndPropsLayout";
import { getPropositionWithId } from "../../../firebase/politician-ballot/fetch-ballot";
import { savePropositions } from "../../../redux/thunks/politicians-ballot/politicians.thunks";
import { useLocation, useMatches } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/redux-hooks";
import { UnknownObject } from "../../../interfaces/global";
import PropsLayout from "./PropsLayout";

const PropositionProfile = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { 
    isFeedComment, 
    jurisdiction, 
    proposition, 
    backRoute,
    updatePropTicker
  } = location?.state || {};

  const propositions = useAppSelector(store => store.politicians.propositions);

  const propId = useMatches()[0].params.propositionId as string;
  const [curProp, setCurProp] = useState<UnknownObject>(proposition);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useOnce(() => {
    if (propositions == null && !isFeedComment) {
      dispatch(savePropositions(jurisdiction));
    }
  });

  useEffect(() => {
    // Might want to find a way to cache this
    let ignore = false;
    if(isFeedComment && isLoading) {
      const propInfoFromId = async () => {
        const prop = await getPropositionWithId(propId);
        setIsLoading(false);
        if (ignore) {
          return;
        }
        setCurProp(prop?.proposition);
      }
      propInfoFromId();
    } else if (isLoading && !isFeedComment) {
      setIsLoading(false);
    }
    return () => {
      ignore = true;
    }
  }, [isFeedComment, propId, isLoading]);

  if (propositions.data && !curProp) {
    const targetProp = propositions?.data.filter((prop: any) => prop.id === propId);
    if (targetProp) {
      setCurProp(targetProp[0]);
    }
  }

  console.log({ curProp, isLoading });
  const propFetchData = useMemo(() => [curProp, isLoading], [curProp, isLoading]);

  return (
    <PropsLayout
      propId={propId} 
      jurisdiction={jurisdiction}
      propositionFetchedData={propFetchData}
      backRoute={backRoute}
      updatePropTicker={updatePropTicker}
    />
  );
}

export default PropositionProfile;
