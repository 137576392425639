import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, DocumentReference, updateDoc } from "firebase/firestore";
import { functions, usersCollectionRef } from "../../../firebase/instance";
import { fetchUser } from "../../../firebase/account/fetch-account";
import { updateUserAccount, uploadProfilePicture } from "../../../firebase/account/update-account";
import { AccountState, AccountSupportKeys } from "../../slices/account/account.slice";
import { ENTITIES_TYPE } from "../../../constants/entities";
import { resolveSupport } from "../../../util/util.firestore";
import { EntityInfo, UserFollowersDoc } from "../../../interfaces/comment-entities.interfaces";
import { httpsCallable } from "firebase/functions";
import { UnknownObject } from "../../../interfaces/global";

interface SupportingParam {
  entityInfo: EntityInfo;
  userAccount: AccountState;
  doesSupport: boolean | null;
}

export const accountEntitySupport = createAsyncThunk(
  'account/accountEntitySupport',
  async ({ entityInfo, userAccount, doesSupport }: SupportingParam) => {
    const { id, type } = entityInfo;

    const entityTypes = ENTITIES_TYPE[type];

    const supportKey: AccountSupportKeys = 
      `${entityTypes}Supporting` as AccountSupportKeys;
    const opposeKey: AccountSupportKeys = 
      `${entityTypes}Opposing` as AccountSupportKeys;

    const {
      userId 
    } = userAccount;

    const userDocRef: DocumentReference = doc(usersCollectionRef, userId);

    await updateDoc(userDocRef,
      resolveSupport({ supportKey, opposeKey, entityId: id, doesSupport })
    )

    const newUserAccount = await fetchUser()

    return { 
      newState: newUserAccount as unknown as AccountState, 
      supportKey, 
      opposeKey 
    }
  }
);

interface ProfilePicParam {
  imageFile: any;
  userAccount: AccountState;
}

export const updateProfilePicture = createAsyncThunk(
  'account/updateProfilePicture',
  async ({ imageFile, userAccount }: ProfilePicParam) => {
    const { userId } = userAccount;
    const url = await uploadProfilePicture(imageFile);
    const userDocRef = doc(usersCollectionRef, userId);
    await updateDoc(userDocRef, {
      photoURL: url
    })
    return url;
  }
);

interface UpdateAccountParam {
  newAccount: AccountState;
}

export const updateUserAccountAsync = createAsyncThunk(
  'account/updateUserAccountAsync',
  async ({ newAccount }: UpdateAccountParam) => {
    return updateUserAccount(newAccount)
  }
);

interface Param {
  userFollowersData: UserFollowersDoc;
}

export const updateRecentActivity = 
  createAsyncThunk('account/updateRecentActivity', 
  async ({ userFollowersData }: Param) => {

    const { recentComments } = userFollowersData;
    const generateFeedFromRecentComments =
        httpsCallable(functions, "generateFeedFromRecentComments");
    
    const { data } = await generateFeedFromRecentComments({ recentComments });
    
    return { feed: (data as unknown as UnknownObject).comments };
  }
);

