import { MouseEvent, useState } from "react";
import OnboardingCardLayout from '../card-layout/OnboardingCardLayout';
import InterestSelector from '../../../shared/interest-selector/InterestSelector';
import { updateSignUpState, updateUserInterests } from "../../../../redux/slices/sign-up/sign-up.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/redux-hooks";
import { RootState } from "../../../../redux/store";

const headerText = "SEE CONTENT YOU CARE ABOUT";
const buttonLabel = "COMPLETE REGISTRATION";

interface Param {
  onNext: Function;
}

const InterestSelection = ({ onNext }: Param) => {
  const [state, updateState] = useState<string[]>([]);
  const signUp = useAppSelector((state: RootState) => state.signUp);
  const dispatch = useAppDispatch();

  const onArrowClick = () => {
    dispatch(
      updateSignUpState({
        currentStep: signUp.currentStep - 1
      })
    )
  };

  const checkValid = () => {
    return state.length > 0;
  };

  const validate = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log("dispatching state...", state);
    dispatch(updateUserInterests(state));
    onNext();
  };

  return (
    <section id="interest-selection">
      <OnboardingCardLayout
        onArrowClick={onArrowClick}
        headerText={headerText}
        content={
          <InterestSelector
            onChange={(selectedInterests: string[]) => {
              updateState(selectedInterests);
            }}
            selectedInterests={state}
          />
        }
        buttonParams={
          { label: buttonLabel, onClick: validate, disabled: !checkValid() }
        }
      />
    </section>
  );
};

export default InterestSelection;
