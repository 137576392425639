import { useState } from "react";
import "./BioSection.css";

interface Param {
  label: string;
  content: string;
  items?: any[];
  showMore?: any;
}

const BioSection = ({ label, content, items, showMore }: Param) => {
  const [showFullContent, setShowFullContent] = useState(false);
  if (content == null || content === "" || typeof content === "object") {
    return (
      <div>
        {items?.map((section: any, idx: number) => {
          return (
            <BioSection
              key={idx}
              label={section.title}
              content={section.content}
              items={section.items}
              showMore={showMore}
            />
          );
        })}
      </div>
    );
  } else {
    const truncatedString = content?.toString().slice(0, 200);
    const fullString = content ?? "";

    return (
      <div className="bio-section-container">
        <div className="bio-content-label">{label}</div>
        <div className="bio-content-content">
          {showFullContent ? fullString : truncatedString}
          {showMore ? (
            <button
              onClick={() => {
                setShowFullContent(!showFullContent);
              }}
            >
              ...More
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default BioSection;
