import { useEffect, useState } from "react";
import "./Bio.css";
import BioSection from "./BioSection";
import NoResult from "../../shared/no-result/NoResult";
import { fetchBio } from "../../../api/api.wikipedia";
// import { useAppSelector } from "../../../redux/hooks/redux-hooks";
import { UnknownObject } from "../../../interfaces/global";
import { useMatches } from "react-router-dom";


interface State { 
  wikiProfile: UnknownObject | null, 
  error: any 
}

interface Param {
  officialName?: string;
}

const Bio = ({ officialName }: Param) => {
  // const politicians = useAppSelector(store => store.politicians);
  let name = useMatches()[0].params.officialName;
  name = officialName || name;

  const [state, setState] = useState<State>({
    wikiProfile: null,
    error: false,
  });

  useEffect(() => {
    if (!state.wikiProfile && name) {
      fetchBio(name).then(bio => {
        setState({ ...state, wikiProfile: bio});
      }).catch(err => {
        setState({ ...state, error: "No result." });
      });
    }
  }, [state, name]);

  return (
    <div className="bio-container">
      {state.wikiProfile?.map((section:any, idx:number) => {
        return (
          <BioSection
            key={idx}
            label={section.title}
            content={section.content}
            items={section.items}
            showMore={true}
          />
        );
      })}
      {state.error ? <NoResult /> : null}
    </div>
  );
};

export default Bio;
