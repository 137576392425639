import { useOutletContext } from "react-router-dom";
import { UnknownObject } from "../../../interfaces/global";
import BillProfileSection from "../bill-profile/BillProfileSection";
import Loader from "../../shared/loading/loader";

interface PropositionInfoParam {
  billData: UnknownObject;
  sponsorContent: UnknownObject;
  proposition: any;
  entityInfo: any;
  isLoading: boolean;
}

const PropositionInfo = () => {
  const { sponsorContent, proposition, entityInfo, isLoading } = useOutletContext<PropositionInfoParam>();
  console.log({ sponsorContent, proposition, entityInfo })

  if (isLoading) return <Loader isFull={true} />
  // const { entityData } = billData?.data || {};
  const { sponsorName, dateIssued, sponsorParty, originChamber } = proposition || {};
  
  // use packageLink to make call to gov...
  return (
    <div>
      <BillProfileSection
        label="Sponsored By"
        content={sponsorName}
        showMore={false}
      />
      <BillProfileSection
        label="Party"
        content={sponsorParty}
        showMore={false}
      />
       <BillProfileSection
        label="Origin Chamber"
        content={originChamber || "No Information"}
        showMore={false}
      />
      <BillProfileSection
        label="Date Introduced"
        content={dateIssued}
        showMore={false}
      />
    </div>
  )
}

export default PropositionInfo;