import { useEffect, useState } from 'react';
import { Comment, EntityInfo, UserData } from '../../../interfaces/comment-entities.interfaces';
import { displaySupport, hoursAgo } from '../util/comments.util';
import ReplyToCommentForm from '../shared/ReplyToCommentForm';
import { childCommentsQuery } from '../../../firebase/comments/shared/fetch-comments';
import { onSnapshot } from 'firebase/firestore';
import './comment.css';
import { ChildComment } from './ChildComment';
import { ENTITY_TYPE } from '../../../constants/entities';

interface Parent {
  commentData: Comment;
  displayComment?: boolean;
  entityInfo: EntityInfo;
  getNumOfComments?: Function | null;
  isFeedComment?: boolean;
  isFeedStoryComment?: boolean;
  replyBoxIsOpen: boolean;
  toggleShowReplyBox: Function;
}

const ParentComment = ({
    commentData,
    displayComment = false,
    entityInfo,
    getNumOfComments = null,
    isFeedComment = false,
    replyBoxIsOpen,
    toggleShowReplyBox,
  }: Parent) => {
  
  const { 
    timestampUTC, 
    content, 
    id,
    doesSupport,
    userData
  } = commentData;
  
  const { username, photoURL } = userData as UserData;

  const [replyBtnIsOpen, toggleShowReplyBtn] = useState(false);

  const [children, updateChildren] = useState<any[]>([]);

  const [viewChildren, toggleChildren] = useState(displayComment);

  const [numOfChildren, updateNumOfChildren] = useState(0);

  const countChildren = (children: any) => {
    let currentCount = children.length;

    for (let i = 0; i < children.length; i++){
      currentCount += children[i].numOfChildren;
    }
    return currentCount;
  }

  useEffect(() => {
    const childrenCount = countChildren(children);
    updateNumOfChildren(childrenCount);
    if (isFeedComment && getNumOfComments) getNumOfComments(childrenCount);
  }, [children, getNumOfComments, isFeedComment]);


  const snapshotObserver = (snapshot: any) => {
    const changes = snapshot.docChanges();

    const incomingChildren: any[] = [];

    changes.forEach((change: any) => {
      if (change.type === 'added') {
        incomingChildren.push(change.doc.data());
      }
    });

    updateChildren(prevState => [...prevState, ...incomingChildren]);
  }

  useEffect(() => {
    if (replyBtnIsOpen && !replyBoxIsOpen) toggleShowReplyBtn(false);
  }, [replyBoxIsOpen, replyBtnIsOpen]);

  useEffect(() => {
    // TO DO:
    // This will need error handling
    const subscribedToChildData = 
      onSnapshot(childCommentsQuery(id), snapshotObserver);
    
    const unsubscribe = () => subscribedToChildData();

    return unsubscribe;
  }, [id]);

  const toggleReply = () => {
    toggleShowReplyBtn(!replyBtnIsOpen);
    toggleShowReplyBox(!replyBoxIsOpen);
  }

  const renderChildren = () => {

    return children?.map(childData => (
      <ChildComment 
        key={childData.timestampUTC}
        entityInfo={entityInfo}
        parentData={commentData}
        commentData={childData}
        replyBoxIsOpen={replyBoxIsOpen}
        toggleShowReplyBox={toggleShowReplyBox}
      />
    ));
  }

  const toggleShowChildren = () => {
    toggleChildren(!viewChildren);
  }

  const toggleViewCommentsJSX = (numOfChildren: number) => {

    return children.length > 0 && !displayComment && !viewChildren ?
      <span
        className={'view-comments-option child-wrap'}
        onClick={toggleShowChildren}
      >
        {`View ${numOfChildren} Comment${numOfChildren > 1 ? 's' : ''}`}
      </span> : 
      null
  }

  return (
    <>
      <div className={isFeedComment ? "top-wrap-no-margin" : "top-wrap"}>
        <p className="profile-feed-img-container">
          <img 
            /* small img profile */
            className="feed-comment-profile-img"
            src={ photoURL || "/assets/default-img.jpg" }
            alt="profile-avatar"
          />
        </p>
        <div className="comment-wrap">
          <div className="comment-wrap-info">
            <div className="comment-wrap-info-inner">
              <p>{username}</p>
              <p>{hoursAgo(timestampUTC)}</p>
              {displaySupport(doesSupport)}
            </div>
            <div className={`feed-comment-reply ${replyBtnIsOpen && replyBoxIsOpen && 'greyed-out'}`}>
              <img
                src="/img/icons/reply.svg"
                alt="reply"
                className="reply-icon"
              />
                <span onClick={toggleReply}>Reply</span>
            </div>
          </div>
          <section 
            className={entityInfo.type === ENTITY_TYPE['story'] ? 
              `comment-content is-feed-comment` : `comment-content`
            }
          >
            {content}
          </section>
        </div>
      </div>

      <ReplyToCommentForm
        data={{
          entityInfo,
          commentData,
          id,
          isChild: false,
          isGrandchild: false,
          parentData: null,
          grandparentData: null
        }}
        replyBoxIsOpen={replyBoxIsOpen}
        replyBtnIsOpen={replyBtnIsOpen}
        toggleReply={toggleReply}
        parentUpdateFunctions={{ updateChildren }}
      />

      {/* This option should only appear if the comment has children */}

      {viewChildren && renderChildren()}

      {!isFeedComment && toggleViewCommentsJSX(numOfChildren)}
    </>
  )
}

export default ParentComment;