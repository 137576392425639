import { doSignOut } from "../../../firebase/instance/auth-utils";
import "./SignOut.css";

const signOut = async () => {
  await doSignOut().then(() => {
    console.log("User Signed Out");
  })
}

const SignOutButton = ({ fontColor }: any) => {
  return (
    <button
      type="button"
      onClick={signOut}
      className={`log-out-button ${fontColor}`}
    >
      Log Out
    </button>
  );
};

export default SignOutButton;
