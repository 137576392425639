import { AddPrefixToKeys, arrayUnion, doc, DocumentReference, getDoc, WriteBatch, writeBatch } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { EntityInfo, UserData, UserFollowersComment } from "../../../interfaces/comment-entities.interfaces";
import { UserFollowersDoc } from "../../../interfaces/comment-entities.interfaces";
import { AccountState } from "../../../redux/slices/account/account.slice";
import { firestore, functions, userFollowersCollectionRef } from "../../instance";
import { LastComment } from "../../../redux/slices/comments/comments.slice";

interface CommentData {
  id: string;
  userData: UserData;
  entityInfo: EntityInfo;
  ref?: DocumentReference
}

export interface UserFollowersParam {
  batch?: WriteBatch;
  chainBatch?: boolean;
  comment: CommentData;
}

export const addCommentToUserFollowers = 
  async (param: UserFollowersParam) => {

    const {
      batch = writeBatch(firestore),
      chainBatch = false,
      comment,
    } = param;

    const { userId } = comment.userData;

    const userFollowersDocRef = doc(userFollowersCollectionRef, userId);
    const userFollowersDoc = await getDoc(userFollowersDocRef);

    if(userFollowersDoc.exists() && userFollowersDoc.data().recentComments.length >= 10){
      // Delete the first comment within the recentComments field - at index 0
      const updatedComments = userFollowersDoc.data().recentComments.slice(1);
      batch.update(userFollowersDocRef, {
        recentComments: updatedComments
      });
    }
    // Add incoming comment to recentComments array (userFollowers)
    const currentTime = new Date().getTime();

    const recentComment: UserFollowersComment = {
      ...comment
    }

    const currentDoc: UserFollowersDoc = {
      lastComment: currentTime,
      recentComments: arrayUnion(recentComment)
    }

    batch.update(
      userFollowersDocRef,
      currentDoc as unknown as AddPrefixToKeys<string, any>
    );

    if (!chainBatch) batch.commit();
    return { param, batch }
  }


interface FetchCommentsFeed {
  account: AccountState;
  storiesFeed: any;
  lastComment?: LastComment | null;
}

export const fetchCommentsFeed = 
  async ({ account, storiesFeed, lastComment = null }: FetchCommentsFeed) => {
    const { userId } = account;
    
    const result = {
      comments: [],
      duplicateDataById: {},
      lastComment: null
    }

    try {
      const generateFeedFromFollowees =
        httpsCallable(functions, "generateFeedFromFollowees");
      /* 
        Bring storiesFeed into a function that goes to firestore,
        grabs all comments from userFollowers
        and then checks against that storiesFeed DS and pairs 
        the data if that comment is associated with that story.
      */
      const { data }: any =
        await generateFeedFromFollowees({ userId, preloadedEntities: storiesFeed, last: lastComment });

      return {
        comments: data.comments,
        duplicateDataById: data.duplicateDataById,
        lastComment: data.last
      }
    } catch (err){
      console.log(err);
    }
    return result;
  };
