import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions, storiesCollectionRef } from "../instance";

export const fetchStoryById = async (id: string) => {
  const storiesDoc = await getDoc(doc(storiesCollectionRef, id));
  return storiesDoc.data(); 
}

export const fetchHeadlinesFromNewsApi = async () => {
  try {
    // call clound function  fetchHeadlinesFromNewsApi, with userToken (for cloud function to talk to db)
    const fetchHeadlinesFromNewsApi = 
      httpsCallable(functions, "fetchHeadlinesFromNewsApi");

    const { data }: any = await fetchHeadlinesFromNewsApi();

    // articles (response) come back from cloud function (server): headlines from NewsApi
    return data.articles;
  } catch (err) {
    console.error(err);
    return null;
  }
};