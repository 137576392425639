import { EntityInfo } from "../../interfaces/comment-entities.interfaces";
import { UnknownObject } from "../../interfaces/global";
import { AccountState } from "../../redux/slices/account/account.slice";
import { accountEntitySupport } from "../../redux/thunks/account/account.thunks";
import { handleChangeOfEntitySupport } from "../../util/util.firestore";

interface Param {
  entityInfo: EntityInfo;
  uniqueData?: UnknownObject | null;
  userAccount: AccountState;
  entityData: any;
  doesSupport: boolean | null;
  dispatch: Function;
  remove?: boolean;
  localStateRefresh?: Function | (() => Promise<void>);
}

// TO DO: Consider turning into a thunk?
export const handleSupport = ({
  entityInfo,
  entityData,
  uniqueData = null,
  userAccount,
  doesSupport,
  dispatch,
  localStateRefresh = () => {}
}: Param) => {
  handleChangeOfEntitySupport(
    {
      entityInfo,
      entityData,
      uniqueData,
      userAccount, 
      doesSupport
    },
  ).then(() => {
    dispatch(
      accountEntitySupport({
        entityInfo,
        userAccount,
        doesSupport
      })
    );
    localStateRefresh();
  }).catch(e => {
    console.error("Error handling support: " + e);
  })
}