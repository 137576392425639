import { useEffect, useRef, useState } from "react";
import "./PersonProfileLayout.css";
import FollowTracker from "../shared/follow-tracker/FollowTracker";
import AccountTabBar from "../account/tab-bar/AccountTabBar";
import PreviousAndButton from "../shared/previous-button/PreviousAndButton";
import CommentAndSupport from "../shared/comment-support/CommentAndSupport";
import { CommentWindow } from "../comments/comment-window/CommentWindow";
import { ENTITY_TYPE } from "../../constants/entities";
import { handleSupport } from "../../firebase/shared/handle-support";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/redux-hooks";
import { UnknownObject } from "../../interfaces/global";
import { Outlet } from "react-router-dom";
import { fetchUserFollowers } from "../../firebase/user-followers/fetch-user-followers";
import { OfficialWithDB } from "../../interfaces/politicians.interfaces";
import { tempOfficialData } from "../politician/util/util.politician";
import { UserFollowersDoc } from "../../interfaces/comment-entities.interfaces";
import { tempUserFollowersDoc } from "./util/util.layout";
import { AccountState } from "../../redux/slices/account/account.slice";

interface Param {
  currentOfficial?: OfficialWithDB;
  navigationAction: UnknownObject;
  fullname?: string;
  tabData?: any;
  routes?: any[];
  isPolitician?: boolean | null;
  photoURL?: string | null;
  localStateRefresh?: Function;
}

// interface PersonDetails {
//   userSupportsPerson: null | boolean;
//   profileDescription: string;
//   photo: string;
//   about: string;
//   name: string;
//   followingCount: number;
// }

const PersonProfileLayout =
  ({
    currentOfficial = tempOfficialData,
    navigationAction,
    fullname = 'none',
    tabData,
    photoURL,
    isPolitician = false,
    localStateRefresh = () => {}
  }: Param) => {

    const userAccount: AccountState = useAppSelector(store => store.account);
    const dispatch = useAppDispatch();

    const { entityData, appData } = currentOfficial;

    const {  
      title, 
      politiciansOpposing: opposing, 
      politiciansSupporting: supporting,
      username 
    } = userAccount;

    const [ commentWindow, toggleCommentWindow ] = useState(false);
    const [ , trackCommentAdded ] = useState(false);
    const [ fixedElHeight, setFixedElHeight ] = useState<number>(0);
    const fixedRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setFixedElHeight(fixedRef.current!.getBoundingClientRect().height);
    }, [fixedRef]);

    const [followingCount, setFollowingCount] = 
      useState<number | undefined>(0);

    const [userFollowersData, setUserFollowersData] = 
      useState<UserFollowersDoc>(tempUserFollowersDoc);
    
    useEffect(() => {
      console.log("going...", { appData });
      if (isPolitician && appData?.followers) {
        setFollowingCount(appData.followers.length);
      } else if (!isPolitician){
        const setFollowers = async () => {
          const userFollowers = await fetchUserFollowers();
          console.log({userFollowers})
          setUserFollowersData(userFollowers);
        }

        setFollowers();
      }
    }, [appData, isPolitician]);
    
    const userFollowersLen = userFollowersData?.followers?.length;

    const userSupportsPerson =
      isPolitician ? (supporting.includes(fullname) ? true : 
      opposing.includes(fullname) ? false : null) : null;
    
    const profileDescription = 
      (entityData && entityData.officeName) || title;

    const photo = 
      photoURL || (!isPolitician && userAccount.photoURL) || null;

    const about =
      isPolitician ? (entityData && entityData.party) : userAccount.about;
    
    const name = isPolitician ? entityData.name : username;

    const entityInfo = { id: fullname, type: ENTITY_TYPE['politician'] };

    // let followingCount = isPolitician && currentOfficial.followers ? 
    // Find a way to determine whether there are followers from a politician...
    /*
      Need to determine how we are destructuring Politician...
      - We are doing a couple of things here
      - One, we are refactoring this right now for our user profile page
      - Two, this seems to be a major component for a politician
      - Three, because of two, we probably will want to split some things up here
      - Four, seperate the plain jsx with the functionality perhaps?
      - Five, the routing is an issue - we need to figure out how to translate to
      our current react-router setup.

      -- We'll combine the two and turn this into a proper flexible component
      -- If you are a user your posts should reflect your recent activity?
    */
    
    const onSupport = () => {
      const userSupports = supporting.includes(fullname);
      handleSupport({
        entityData,
        entityInfo,
        userAccount,
        doesSupport: !userSupports || null,
        localStateRefresh,
        dispatch
      });
    }

    const onOppose = () => {
      const userSupports = opposing.includes(fullname);
      handleSupport({
        entityData,
        entityInfo,
        userAccount,
        doesSupport: userSupports && null,
        localStateRefresh,
        dispatch
      });
    }

    const { label, onClickButton, routeBack, style } = navigationAction;

    return (
      <>
        <div 
          ref={fixedRef} 
          style={{ position: 'fixed', top: 0 }} 
          className="profile-container" 
          id="profile-container"
        >
          <PreviousAndButton
            label={label}
            onClickButton={onClickButton}
            route={routeBack}
            style={style}
          />
          <img
            src={photo || entityData?.photoURL || "/assets/default-img.jpg"}
            className="profile-img"
            alt="profile-avatar"
          />
          <div className="fullname">{name}</div>
          <div className="user-description">{profileDescription ?? ""}</div>
          <FollowTracker
            account={userAccount}
            followingCount={isPolitician ? followingCount : userFollowersLen}
            fullname={fullname}
            isPolitician={isPolitician}
          />
          <div className="user-description">{about ?? ""}</div>
          {
            isPolitician ?
              <CommentAndSupport 
                userSupportsEntity={userSupportsPerson}
                handleSupport={onSupport}
                handleOppose={onOppose}
                openCommentWindow={() => toggleCommentWindow(true)}
                tickerData={{ 
                  supporting: appData?.supporting || [], 
                  opposing: appData?.opposing || []
                }}
              />
            : ""
          }
          {
            commentWindow ?
            <CommentWindow
              closeCommentWindow={() => toggleCommentWindow(false)}
              handleSupport={onSupport}
              handleOppose={onOppose}
              entityData={entityData}
              entityInfo={{ id: name, type: ENTITY_TYPE['politician']}}
              doesSupport={userSupportsPerson}
              newCommentAdded={false}
              trackCommentAdded={trackCommentAdded}
            /> : null
          }
          {/* links */}
          <AccountTabBar tabData={tabData} />
        </div>
        <div className="profile-children" style={{ marginTop: fixedElHeight }}>
          <Outlet context={{ currentOfficial, userFollowersData, dispatch, }} />
        </div>
      </>
    );
  }

export default PersonProfileLayout;