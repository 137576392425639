import { functions } from "../instance";
import { httpsCallable } from "firebase/functions";

// export const fetchPoliticianByName = async (name: string) => {
//   const politican = await getDoc(doc(politiciansCollectionRef, name));
//   return politican.data() as OfficialWithDB;
// }

export const fetchPoliticianTickerData = async (name: string) => {
  const fetchTickerData = httpsCallable(functions, 'fetchPoliticianAndRemoveSupportingArrays');
  const ticker = await fetchTickerData({ politicianId: name });
  return ticker.data;
}