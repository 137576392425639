import { AccountState } from '../../redux/slices/account/account.slice'
import { useAppSelector } from "../../redux/hooks/redux-hooks";
import { Link, useLocation } from "react-router-dom";
import * as routes from '../../constants/routes';
import "./Navbar.css";

interface Param {
  userAccount: AccountState;
}

const NavBar = ({ userAccount }: Param) => {
  const location = useLocation();
  const account = useAppSelector(state => state.account);

  const isSelected = (label: string) => {
    return location.pathname.includes(label);
  }
  
  return (
    <div className="header-wrapper">
      <div id="navbar-header" className="header">
        <div id="people-search-icon">
          <Link to="/people">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.324 18.998L11.674 13.365C10.388 14.2391 8.85933 14.6859 7.305 14.642C5.85782 14.6388 4.44403 14.2068 3.24225 13.4005C2.04047 12.5943 1.10461 11.4499 0.55289 10.112C0.00117487 8.77411 -0.141641 7.30271 0.142483 5.88368C0.426608 4.46466 1.12493 3.16167 2.14922 2.13933C3.17351 1.11699 4.47783 0.421165 5.8974 0.139751C7.31696 -0.141663 8.78808 0.00396365 10.1249 0.558233C11.4618 1.1125 12.6043 2.05055 13.4083 3.25387C14.2123 4.45719 14.6416 5.8718 14.642 7.31899C14.6699 8.89179 14.2049 10.4339 13.312 11.729L18.962 17.363L17.325 18.998H17.324ZM7.371 1.65799C6.22869 1.66194 5.11316 2.00429 4.16527 2.6418C3.21739 3.27931 2.47967 4.1834 2.04526 5.2399C1.61085 6.2964 1.49924 7.45793 1.72451 8.57782C1.94979 9.69771 2.50185 10.7257 3.31099 11.5321C4.12013 12.3384 5.15006 12.8869 6.27072 13.1083C7.39138 13.3298 8.55252 13.2141 9.60751 12.7761C10.6625 12.338 11.564 11.5972 12.1983 10.6471C12.8325 9.69702 13.171 8.58031 13.171 7.43799C13.1673 5.90271 12.5544 4.43167 11.4669 3.34794C10.3794 2.2642 8.90628 1.65639 7.371 1.65799Z" fill="#4A4A4A"/>
            </svg>
          </Link>
        </div>
        <h3 className="home-logo">MOBILIZE</h3>
        <p className="profile-img-container">
          <Link to="/account">
            <img
              src={account?.photoURL || "/assets/default-img.jpg"}
              className="profile-img-NavBar"
              alt="profile-avatar"
            />
          </Link>
          <br />
        </p>
      </div>

      {/* links */}
      <div id="tab-container-wrapper" >
        <div className="tab-container">
          <Link
            to="/feed"
            className={`nav-bar-item ${isSelected("feed") ? "active" : null}`}
          >
            <h3>Feed</h3>
          </Link>
          <div className="ballot-divider"></div>

          <Link
            to={`${routes.BALLOT}/local`}
            className={`nav-bar-item ${
              isSelected("local") ? "active" : null
            }`}
          >
            <h3>Local</h3>
          </Link>

          <Link
            to={`${routes.BALLOT}/state`}
            className={`nav-bar-item ${
              isSelected("state") ? "active" : null
            }`}
          >
            <h3>State</h3>
          </Link>

          <Link
            to={`${routes.BALLOT}/federal`}
            className={`nav-bar-item ${
              isSelected("federal") ? "active" : null
            }`}
          >
            <h3>Federal</h3>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NavBar;