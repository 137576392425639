function populateOfficials(officialIndices: number[], json: any) {
  return officialIndices.map((index) => {
    const currentOfficial = json.officials[index];
    return currentOfficial;
  });
}

function populateOffices(officeIndices: number[], json: any) {
  return officeIndices.map((index) => {
    const currentOffice = json.offices[index];
    return {
      name: currentOffice.name,
      officials: populateOfficials(currentOffice.officialIndices, json),
    };
  });
}

const initialState = {
  state: {
    congressionalDistrict: null
  },
  local: {
    county: null,
    place: null
  },
  federal: {},
}

export function parseRepresentativesResponse(json: any) {
  const parsedData = JSON.parse(JSON.stringify(initialState));
  let offices;

  for (const key in json.divisions) {
    const parts = key.split("/");
    const locationPair = parts[parts.length - 1];

    const locationParts = locationPair.split(":");
    const location = locationParts[0];

    const name = json.divisions[key].name;

    if (json.divisions[key].officeIndices) {
      offices = populateOffices(json.divisions[key].officeIndices, json);
    }

    switch (location) {
      case "country":
        parsedData.federal = {
          name: name,
          offices: offices,
        };
        break;
      case "state":
        parsedData.state = {
          name: name,
          offices: offices,
        };
        break;
      case "cd":
        parsedData.state.congressionalDistrict = {
          name: name,
          offices: offices,
        };
        break;
      case "county":
        parsedData.local.county = {
          name: name,
          offices: offices,
        };
        break;
      case "place":
        parsedData.local.place = {
          name: name,
          offices: offices,
        };
        break;
      default:
        console.warn(`unknown location type ${location}`);
    }
  }
  return parsedData;
}