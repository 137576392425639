import { Link } from "react-router-dom";
import { BallotLinks } from "./BallotTopLinks";

const BallotSubLinks = ({ links, onNavChange, manageState }: BallotLinks) => {
  const { state, setState } = manageState;
  const { ballotTab, subBallotTab } = state;

  return links.map((link: any) => {
    if (link.path === ballotTab && link.subTabs !== undefined) {
      return (
        <div
          className="div-header div-sub-header"
          key={link.path}
          id="subheader"
        >
          {link.subTabs.map((subTab: any) => {
            return (
              <h3 key={subTab.path} className="subTabs">
                <Link
                  id={subTab.path}
                  className={`anchor ${
                    subBallotTab === subTab.path
                      ? `anchor-active`
                      : ``
                  }`}
                  to={`/ballot/${link.path}/${subTab.path}`}
                  onClick={(e) => {
                    // if (subTab.path !== currentBallot) {
                    //   onNavChange({
                    //     path: currentBallot,
                    //     subPath: subTab.path,
                    //   });
                    // }
                    setState({
                      ...state,
                      subBallotTab: subTab.path,
                    });
                  }}
                >
                  {subTab.label}
                </Link>
              </h3>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  })
}

export default BallotSubLinks;