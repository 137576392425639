import { getDocs } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { getCommentsQueryByEntityId } 
  from "../../../firebase/comments/shared/fetch-comments";
import { Comment } from "../../../interfaces/comment-entities.interfaces";
import { CommentsDynamicSegment } from "../../../routing/route-loaders";
import CommentsDisplay from "../../comments/comments-container/CommentsDisplay";

const PoliticianCommentsFeed = () => {
  const { entityId: officialName, commentType } = 
    useLoaderData() as unknown as CommentsDynamicSegment; 
      
  const [feed, updateFeed] = useState<Comment[]>([]);
  const isActive = useRef(true)
  // let isActive = true;

  useEffect(() => {
    // Retrieve comments and update "feed"
    const query = getCommentsQueryByEntityId(officialName);

    const getComments = async () => {
      const commentData: any[] = [];
      const querySnapshot = await getDocs(query);
      if (isActive.current) {
        querySnapshot.forEach(doc => {
          commentData.push(doc.data());
        });
      }
      updateFeed(commentData);
    }

    getComments();
    
    return () => {
      isActive.current = false;
    }
  }, [officialName]);
  
  console.log({ commentType, officialName }, "<--- pol com feed");

  return <CommentsDisplay 
    feed={feed} 
    entityId={officialName} 
    type={commentType as string} 
  />
}

export default PoliticianCommentsFeed;
