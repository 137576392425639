import { useState, useEffect, EventHandler} from "react";
import ReactDOM from "react-dom";
import CloseIcon from "../close-icon/CloseIcon";
import "./ReAuthenticate.css";
import TextInput from "../text-input/TextInput";
import PrimaryButton from "../primary-button/PrimaryButton";
import { 
  EmailAuthProvider, 
  reauthenticateWithCredential, 
  UserCredential 
} from "firebase/auth";
import { auth } from "../../../firebase/instance";

interface Param {
  cb: Function;
  handleClose: EventHandler<any>;
  confirmUserActionMsg: string;
}

interface State {
  password: string;
  userConfirmed: null | boolean;
  error: null | boolean;
}

const ReAuthenticate = ({ cb, handleClose, confirmUserActionMsg }: Param) => {
  const [state, setState] = 
    useState<State>({ password: "", userConfirmed: null, error: null });

  const user = auth.currentUser;
  
  const handleUserFunc = () => {
    cb(user)
      .catch((e: any) => {
        if(e.code === "auth/requires-recent-login"){
          setState({...state, userConfirmed: true});
        }
      })
  }

  const handleChange = (e: any) => {
    e.preventDefault();
    setState({ ...state, password: e.target.value });
  };

  useEffect(() => {
    document.body.classList.add("hide-overflow");
    return () => {
      document.body.classList.remove("hide-overflow");
    }
  }, [cb]);

  const handleReauthentication = async (e: any) => {
    e.persist();
    e.preventDefault();

    if (user && user.email) {
      try {
        const credential = EmailAuthProvider.credential(
          user.email,
          state.password
        )
        const userCred: UserCredential = await reauthenticateWithCredential(user, credential)
        console.log(userCred, "USER CRED");
        handleClose(null);
        cb(user);
      } catch (err) {
        setState(prev => ({ ...prev, error: true }))
      }
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-wrap">
      <div className="authenticate-modal">
        <button id="auth-close" type="button" onClick={handleClose}>
          <CloseIcon />
        </button>
        { state.userConfirmed || !confirmUserActionMsg ? 
          <>
            <p>Please re-authenticate yourself</p>
            <form onSubmit={handleReauthentication}>
              <TextInput
                styleColor="dark"
                placeholder="PASSWORD"
                type="password"
                onTextInputChange={handleChange}
                errorMessage={state.error ? "Invalid password.*" : ""}
                errorMessageColor="red"
              />
              <PrimaryButton onClick={(e) => console.log(e)} label="SUBMIT" />
            </form>
          </> : 
          <>
            <p>{confirmUserActionMsg}</p>
            <div className="confirm-delete">
              <button className="confirm-action" type="button" onClick={handleUserFunc}>CONFIRM</button>
              <button className="cancel-action" type="button" onClick={handleClose}>CANCEL</button>
            </div>
          </>
        }
      </div> 
    </div>, 
    document.getElementById('modal-root') as Element  
  )
};

export default ReAuthenticate;