import { httpsCallable } from "firebase/functions";
// import wiki from "wikijs";
import { functions } from "../firebase/instance";

const profiles: any = {};

// Convert to 32bit integer
function stringToHash(str: string) {
  let hash = 0;

  if (str.length === 0) return hash;

  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash;
}

export const fetchBio = async (officialName: string) => {
  const officialHash = stringToHash(officialName);
  if (profiles[officialHash] !== undefined && profiles[officialHash].content !== undefined) {
    return Promise.resolve(profiles[officialHash].content);
  }

  const fetchWikiContent = httpsCallable(functions, "fetchWikiContent");
  return fetchWikiContent({officialName})
    .then(dataObj => {
      const { data: content } = dataObj;
      profiles[officialHash] = {
        ...profiles[officialHash],
        content
      };
      return content;
    });
  // return wiki({apiUrl: 'https://en.wikipedia.org/w/api.php' })
  //   .page(`${officialName}`)
  //   .then(page => page.content())
  //   .then(content => {
  //     profiles[officialHash] = {
  //       ...profiles[officialHash],
  //       content
  //     };
  //     return content;
  //   });
}

export async function fetchDefaultImage(officialName: string) {
  const officialHash = stringToHash(officialName);
  if (profiles[officialHash] !== undefined && profiles[officialHash].mainImage !== undefined) {
    return Promise.resolve(profiles[officialHash].defaultImage);
  }

  const fetchWikiDefaultImage = httpsCallable(functions, "fetchWikiDefaultImage");
  return fetchWikiDefaultImage({officialName})
    .then(dataObj => {
      const { data: defaultImage } = dataObj;
      profiles[officialHash] = {
        ...profiles[officialHash],
        defaultImage
      };
      return defaultImage;
    });
  // return wiki({apiUrl: 'https://en.wikipedia.org/w/api.php'})
  //   .page(`${officialName}`)
  //   .then(page => page.mainImage())
  //   .then(defaultImage => {
  //     profiles[officialHash] = {
  //       ...profiles[officialHash],
  //       defaultImage
  //     };
  //     return defaultImage;
  //   });
}

