import * as routes from "../../constants/routes";
import PersonProfileLayout from "../shared-layout/PersonProfileLayout";
// import UnderConstruction from "../shared/under-construction/UnderConstruction";
// import CommentFeed from "../feed/feed.comments/CommentFeed";
import { useNavigate } from "react-router-dom";
import { TabData } from "./tab-bar/AccountTabBar";

const tabData: TabData = {
  tabs: [
    { label: "posts", link: `${routes.ACCOUNT}/posts` },
    { label: "followers", link: `${routes.ACCOUNT}${routes.FOLLOWERS}` },
    { label: "following", link: `${routes.ACCOUNT}${routes.FOLLOWING}` }
  ],
  preSelected: "posts"
};

const Account = () => {
  const navigate = useNavigate();

  return (
    <PersonProfileLayout
      navigationAction={{
        label: "EDIT",
        onClickButton: () => {
          navigate(`${routes.ACCOUNT}/edit`)
        },
        onClickArrow: () => {
          navigate(routes.FEED)
        },
        routeBack: routes.FEED
      }}
      tabData={tabData}
    />
  );
};

export default Account;