import ReAuthenticate from '../shared/reauthenticate/ReAuthenticate';
import { auth, usersCollectionRef } from '../../firebase/instance';
import { deleteDoc, doc } from 'firebase/firestore';
import { deleteUser } from 'firebase/auth';
import { EventHandler } from 'react';
import { useAppDispatch } from '../../redux/hooks/redux-hooks';
import { resetState } from '../../redux/slices/sign-up/sign-up.slice';

interface Param {
  handleClose: EventHandler<any>;
}

const DeleteAccount = ({ handleClose }: Param) => {
    const dispatch = useAppDispatch();
    const handleDelete = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        await deleteDoc(doc(usersCollectionRef, currentUser.uid));
        await deleteUser(currentUser);
        dispatch(resetState());
      }
    }
    const confirmDeleteMsg = "Confirm Permanent Account Deletion";
    return (
      <ReAuthenticate
        confirmUserActionMsg={confirmDeleteMsg}
        handleClose={handleClose}
        cb={handleDelete}
      />
    )
}

export default DeleteAccount;
