import { useState, useEffect, useCallback } from 'react';
import { getCommentsQueryByEntityId } from '../../../firebase/comments/shared/fetch-comments';
import ParentComment from '../shared/ParentComment';
import { DocumentData, QuerySnapshot, onSnapshot } from 'firebase/firestore';
import '../shared/comment.css';
import { Comment } from '../../../interfaces/comment-entities.interfaces';

interface Param {
  entityId: string;
  type: string;
  feed?: Comment[];
}
// Load up story comments
const CommentsDisplay = ({ entityId, type, feed = [] }: Param) => {
  const [comments, updateComments] = useState<any[]>(feed);
  const [replyBoxIsOpen, toggleShowReplyBox] = useState(false)
  
  const updateAndLog = useCallback((incomingComments: any) => {
    updateComments(prevState => [...prevState, ...incomingComments]);
  }, []);

  const snapshotObserver = useCallback((snapshot: QuerySnapshot<DocumentData>) => {
    const changes = snapshot.docChanges();
    const incomingComments: any[] = [];

    changes.forEach((change: any) => {
      if (change.type === 'added'){
        incomingComments.push(change.doc.data());
      }
    });
    // should use redux instead of state?
    console.log({ incomingComments });
    updateAndLog(incomingComments)
    // updateComments(prevState => [...prevState, ...incomingComments]);
  }, [updateAndLog]);

  useEffect(() => {
    console.log("SUBSCRIBING TO COMMENT...", { entityId });
    const unsubscribe = onSnapshot(
      getCommentsQueryByEntityId(entityId), snapshotObserver
    );
    
    return () => {
      console.log("UNSUBSCRIBING FROM COMMENTS")
      unsubscribe();
    }
  }, [entityId, snapshotObserver]);

  const displayComments = () => comments.map((comment, idx) => {
    // Should Be Parent Comment
    // return <Comment
    //     key={comment.timestampUTC}
    //     grandParent={true}
    //     commentData={comment}
    //     replyBoxIsOpen={replyBoxIsOpen}
    //     toggleShowReplyBox={toggleShowReplyBox}
    //   />
    return <ParentComment
        commentData={comment}
        entityInfo={{ id: entityId, type }}
        key={idx}
        replyBoxIsOpen={replyBoxIsOpen}
        toggleShowReplyBox={toggleShowReplyBox}
      />
  });

  return (
    <div className="comments-container">
      {displayComments()}
    </div>
  )
}

export default CommentsDisplay;
