// import Executive from "../executive/Executive";
// import Congress from "../congress/Congress";
import { AccountState } from "../../../../redux/slices/account/account.slice";
import { Outlet, useOutletContext } from "react-router-dom";
import { UnknownObject } from "../../../../interfaces/global";

interface Param {
  data?: any;
  currentSubTab?: any;
  account?: AccountState
}

const Federal = ({ data, currentSubTab, account }: Param) => {

  const { 
    politicians, account: accountContxt,
    currentSubTab: subTab
  } = useOutletContext() as UnknownObject;

  if (!account) account = accountContxt as AccountState;
  if (!data) data = politicians?.data?.federal;
  if (!currentSubTab) currentSubTab = subTab;

  return (
    <div>
      <Outlet context={{ data, account }} />
    </div>
  );
};

export default Federal;
