import { UnknownObject } from "../../../interfaces/global";
import { FoundOfficial, OfficialWithDB } from "../../../interfaces/politicians.interfaces";

interface OfficialData {
  politicians: any;
  officialName: string;
  otherKeyValues: any[];
}

export const resolveOfficialFromData = 
  (
    { 
      politicians, 
      officialName, 
      otherKeyValues 
    }: OfficialData
  ) => {
    const stateOffices = {
      offices: politicians.data?.state?.offices,
      placeName: politicians.data?.state?.name
    }
    const federalOffices = { 
      offices: politicians.data?.federal?.offices,
      placeName: politicians.data?.federal?.name
    }
    const otherValues: UnknownObject = {};

    if (otherKeyValues && Array.isArray(otherKeyValues)) {

      otherKeyValues.forEach(pair => {
        const { key, value } = pair;
        otherValues[key] = value;
      })

    }

    const localOffices = {
      offices: (politicians.data?.local?.place?.offices || []).concat(
          politicians.data?.local?.county?.offices || []),
      placeName: politicians.data?.local?.place?.name
    }
    
    if (stateOffices && federalOffices && localOffices) {
      const allOffices = [stateOffices, federalOffices, localOffices];

      for ( let officeCollection of allOffices) {
        for ( let office of officeCollection.offices ) {
          for ( let official of office.officials ) {
            if (official.name === officialName) {
              const foundOfficial: FoundOfficial = {
                ...official,
                officeName: office.name,
                placeName: officeCollection.placeName,
                ...otherValues
              }
              return foundOfficial;
            }
          }
        } 
      }
      
    }
    return null;
  }

export const tempOffical: FoundOfficial = {
  channels: [ { type: '', id: '' }],
    geocodingSummaries: [
      { 
        addressUnderstood: false, 
        featureId: {celId: '', fprint: ''},
        featureType: '',
        positionPrecisionMeters: 0,
        queryString: ''
      }
    ],
    name: '',
    officeName: '',
    party: '', 
    phones: [''], 
    photoURL: '', 
    urls: ['']
}
  
export const tempOfficialData: OfficialWithDB = {
  entityData: tempOffical,
  appData: {
    followers: [],
    info: tempOffical,
    name: '',
    numOfComments: 0,
    opposing: [''],
    supporting: ['']
  }
}
  
