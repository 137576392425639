import { ChangeEvent, MouseEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/redux-hooks";
import { updateUserInfo, updateError, updateSignUpState } from "../../../../redux/slices/sign-up/sign-up.slice";
import { RootState } from "../../../../redux/store";
import TextInput from "../../../shared/text-input/TextInput";
import OnboardingCardLayout from "../card-layout/OnboardingCardLayout";
import "./UserLocation.css";

const headerText = "POLITICS ON A LOCAL, STATE, FEDREAL LEVEL";
const placeholder = "ADD ZIP CODE";
const buttonLabel = "CONTINUE";

interface Param {
  onNext: Function;
}

const UserLocation = ({ onNext }: Param) => {

  const [state, updateState] = useState({ zipcode: ''})

  const signUp = useAppSelector((state: RootState) => state.signUp);
  const dispatch = useAppDispatch();

  const onArrowClick = () => {
    dispatch(
      updateSignUpState({
        currentStep: signUp.currentStep - 1
      })
    )
  };

  const onTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(!e.target.value.match(/^\d*$/) || e.target.value.length > 5) {
      return false;
    }
    updateState({ ...state, zipcode: e.target.value })
  };

  const validateInput = () => {
    let error = null;
    const { zipcode } = state;
    if (zipcode.length < 5 || !zipcode.match(/\d{5}/)) {
      error = "invalid zipcode";
    }

    return error;
  };

  const handleClick = (e: MouseEvent) => {
    const error = validateInput();

    switch (error) {
      case "invalid zipcode":
        dispatch(updateError({
          zipcodeError: "Invalid zipcode.*"
        }))
        break;
      case null:
        dispatch(
          updateUserInfo({
            zipcode: state.zipcode
          })
        );
        onNext();
        break;
      default:
        throw new Error(`unknown error ${error}`);
    }
  };

  return (
    <OnboardingCardLayout
      onArrowClick={onArrowClick}
      headerText={headerText}
      content={
        <div>
          <img
            className="onboarding-svg"
            alt="location"
            src="/img/onboarding/location.svg"
          />
          <TextInput
            placeholder={placeholder}
            onTextInputChange={onTextInputChange}
            type="text"
            value={state.zipcode}
            errorMessage={signUp.error.zipcodeError}
          />

          {/* <div>
            <div className="geolocation-link" onClick={handleGetGeolocation}>
              USE CURRENT LOCATION
            </div>
          </div> */}
        </div>
      }
      buttonParams={
        { label: buttonLabel, onClick: handleClick }
      }
    />
  );
};

export default UserLocation;
