import "./PropositionCard.css";
import { Link } from "react-router-dom";
import { handleSupport } from "../../../firebase/shared/handle-support";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/redux-hooks";
import { ENTITY_TYPE } from "../../../constants/entities";
import CommentAndSupport from "../../shared/comment-support/CommentAndSupport";
import { useEffect, useState } from "react";
import { fetchPropositionTickerDataById } from "../../../firebase/politician-ballot/fetch-proposition-data";
import { set_ticker_data } from "../../../redux/slices/ballot/ballot.slice";
import { CommentWindow } from "../../comments/comment-window/CommentWindow";

interface TickerData {
  supporting: number;
  opposing: number;
  id: string | null;
  numOfComments: number;
}

const DataGovBillCard = ({ bill, isFeedComment = false, backRoute }: any) => {
  const userAccount = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();

  const {
    packageId,
    congress,
    title,
    packageLink,
    dateIssued
  } = bill;

  /*
    {
      "packageId": "BILLS-118hr8647ih",
      "lastModified": "2024-07-27T06:25:26Z",
      "packageLink": "https://api.govinfo.gov/packages/BILLS-118hr8647ih/summary",
      "docClass": "hr",
      "title": "VA Home Loan Program Reform Act",
      "congress": "118",
      "dateIssued": "2024-06-05"
    }
  */

  const { propositionsOpposing, propositionsSupporting } = userAccount;
  const addMargin = "congress-margin";

  const tickerData = useAppSelector(state => state.ballot[packageId]);

  const entityInfo = { id: packageId, type: ENTITY_TYPE['proposition'] };

  const userSupportsProposition = () => 
    propositionsSupporting.includes(packageId) ? true : 
    propositionsOpposing.includes(packageId) ? false : null;

  const [state, setState] = useState({
    numOfChar: 0,
    showOptionModal: false,
    showCommentWindow: false,
  });

  const [ newCommentAdded, trackCommentAdded ] = useState(false);

  const [update, updating] = useState<Boolean>(true);

  const localStateRefresh = () => updating(prev => !prev);
  
  const onSupport = () => {
    const userSupports = propositionsSupporting.includes(packageId);
    handleSupport({
      entityInfo,
      entityData: bill,
      userAccount,
      doesSupport: !userSupports || null,
      localStateRefresh,
      dispatch
    })
  }

  const onOppose = () => {
    const userOpposes = propositionsOpposing.includes(packageId);
    handleSupport({
      entityInfo,
      entityData: bill,
      userAccount, 
      doesSupport: userOpposes && null,
      localStateRefresh,
      dispatch
    })
  }

  useEffect(() => {
    const getAppData = async () => {
      const data = await fetchPropositionTickerDataById(packageId) as any;
      if (packageId) {
        dispatch(set_ticker_data(data));
      }
    }
    getAppData();
  }, [packageId, dispatch, update, bill]);

  const userInteractionJsx = (tickerData: TickerData, proposition: any) => {
    return  (
      <div className="user-interaction-container">      
        <CommentAndSupport
          userSupportsEntity={userSupportsProposition()} 
          handleSupport={onSupport} 
          handleOppose={onOppose}
          openCommentWindow={() => setState( (prev: any) => ({ ...prev, showCommentWindow: true }) )}
          theme={"transparent"}
          tickerData={tickerData}
          entityData={proposition}
        />
        {/* view all comments link */}
      </div> 
    )
  }
  return (
    <div className={`div-card ${addMargin}`} >
      {(packageId) && <p>Congress {`(${congress})`}</p> }
      <h2 className="title">{title}</h2>
      {
        <>
          <p className="name" style={{ marginBottom: '10px' }}>{`Date Issued: ${dateIssued}`}</p>
          <Link
            to={`/bill-profile/${packageId}/info`}
            className="view-politician-link view-proposition-link"
            state={{ isFeedComment, backRoute, packageLink }}
          >
            Learn More
          </Link>
          {userInteractionJsx(tickerData, bill)}
        </>
      }
       { state.showCommentWindow ?
        <CommentWindow
          closeCommentWindow={() => setState(prev => ({ ...prev, showCommentWindow: false }))}
          doesSupport={userSupportsProposition()}
          entityData={bill}
          entityInfo={entityInfo}
          handleOppose={onOppose}
          handleSupport={onSupport}
          newCommentAdded={newCommentAdded}
          trackCommentAdded={trackCommentAdded}
          uniqueData={{}}
        /> : null }
      <div>
        {
          tickerData?.numOfComments ? 
            <Link to={`/comments/bill/${packageId}`} className="view-comment-container">
              View {tickerData.numOfComments} {tickerData.numOfComments > 1 ? 'comments' : 'comment'}
            </Link>
            : null
        }
      </div>
    </div>
  );
};

export default DataGovBillCard;
