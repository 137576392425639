// This should return my recentComments

import { useOutletContext } from "react-router-dom";
import { UserFollowersDoc } from "../../interfaces/comment-entities.interfaces";
// import useCommentFeedProcessor from '../../hooks/useCommentFeedProcessor';
import { AppDispatch } from "../../redux/store";
import FeedWrap from "../main-feed/feed.comments/FeedWrap";
import { useEffect, useState } from "react";
import NoResult from "../shared/no-result/NoResult";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/instance";
import UsersListDisplay from "./UsersListDisplay";
import { useAppSelector } from "../../redux/hooks/redux-hooks";

interface PersonProfileLayoutContext {
  userFollowersData: UserFollowersDoc;
  dispatch: AppDispatch;
}

const Followers = () => {
  const [isCalled, setIsCalled] = useState<boolean>(false);
  const [followersData, updateFollowersData] = useState<any[]>([]);
  const { following } = useAppSelector(state => state.account);

  // In userFollowersData we have the followers information
  const { userFollowersData } =
    useOutletContext<PersonProfileLayoutContext>();

  const { followers } = userFollowersData;

  useEffect(() => {
    if (!isCalled && followers && followers?.length > 0) {
      const fetchFollowers = httpsCallable(functions, "fetchFollowers");
      
      const fetchAndSetFollowersData = async () => {
        const { data: followersData } = await fetchFollowers({ followers });
        if (Array.isArray(followersData)) {
          updateFollowersData(followersData);
        }
        setIsCalled(true);
      }

      fetchAndSetFollowersData();
    }

  }, [isCalled, followers]);
  
  return <FeedWrap>
    { 
      followersData.length > 0 ? 
      <UsersListDisplay users={followersData} listToCheck={following} /> :
      <NoResult />
    }
  </FeedWrap> 
}

export default Followers;