// This should return my recentComments

import { useOutletContext } from "react-router-dom";
import { UserFollowersDoc } from "../../interfaces/comment-entities.interfaces";
// import useCommentFeedProcessor from '../../hooks/useCommentFeedProcessor';
import { AppDispatch } from "../../redux/store";
import CommentFeed from "../main-feed/feed.comments/CommentFeed";
import FeedWrap from "../main-feed/feed.comments/FeedWrap";
import { useEffect, useState } from "react";
import { updateRecentActivity2 } from "../../redux/thunks/account/account.action-creators";
import NoResult from "../shared/no-result/NoResult";
import { useAppSelector } from "../../redux/hooks/redux-hooks";

interface PersonProfileLayoutContext {
  userFollowersData: UserFollowersDoc;
  dispatch: AppDispatch;
}

// Need to generate posts
const Posts = () => {
  const [isCalled, setIsCalled] = useState<boolean>(false);
  const account = useAppSelector(store => store.account);

  const { userFollowersData, dispatch } =
    useOutletContext<PersonProfileLayoutContext>();

  useEffect(() => {
    if ((userFollowersData?.recentComments as any[])?.length > 0 && !isCalled) {
      setIsCalled(true);
      updateRecentActivity2({ userFollowersData, dispatch });
    }
  }, [dispatch, userFollowersData, isCalled]);
  
  return <FeedWrap>
    { 
      account.recentActivity && account.recentActivity.length > 0 ?
      <CommentFeed insertedFeed={account.recentActivity as any[]} /> :
      <NoResult />
    }
  </FeedWrap> 
}

export default Posts;