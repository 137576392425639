import "./ActionButton.css";

function ActionButton({ type, label, onClick }: any) {
  const style = `btn-${type}`;
  return (
    <button className={`btn-base ${style}`} onClick={onClick}>
      {label}
    </button>
  );
}

export default ActionButton;
