import "./CustomizedInterest.css";

interface Param {
  interest: string;
  onDelete: Function;
}

const CustomizedInterest = ({ interest, onDelete }: Param) => {
  return (
    <>
      <div className="interest-list-item">
        <span>{interest}</span>
        <sup>
          <button
            onClick={() => {
              onDelete(interest);
            }}
          >
            x
          </button>
        </sup>
      </div>
    </>
  );
};

export default CustomizedInterest;
