import EditLayout from "./EditLayout";
import "./EditTextInput.css";

interface Param {
  label: string;
  description?: string;
  placeholder: string | null | undefined;
  onChange: Function;
  prefix?: any;
}

const EditTextInput = ({
  label,
  description,
  placeholder,
  onChange,
  prefix,
}: Param) => {
  return (
    <EditLayout
      label={label}
      description={description}
      content={
        <>
          {prefix}
          <input
            type="text"
            placeholder={placeholder || ''}
            className="edit-text-input"
            onKeyUp={(e: any) => {
              onChange(e.target.value.trim());
            }}
          />
        </>
      }
    />
  );
};

export default EditTextInput;
