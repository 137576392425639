import EditLayout from "../../shared/text-input/EditLayout";
import InterestSelector from "../../shared/interest-selector/InterestSelector";

interface Param {
  label: string;
  selectedInterests: any[];
  onChange: any;
  width?: string;
}

const EditInterest = ({ label, selectedInterests, onChange, width }: Param) => {
  return (
    <EditLayout
      label={label}
      content={
        <InterestSelector
          onChange={onChange}
          selectedInterests={selectedInterests}
          width={width}
        />
      }
    />
  );
};

export default EditInterest;
