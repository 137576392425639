import './App.css';
import { useNavigate } from 'react-router-dom';
import * as routes from './constants/routes';

import SharedLayout from './components/shared-layout/SharedLayout';
import { AMPLITUDE_API_KEY } from './config';
import { init } from '@amplitude/analytics-browser';
import { doSignOut, monitorAuthState } from './firebase/instance/auth-utils';
import { useEffect } from 'react';
import { AccountState, update_user_account } from './redux/slices/account/account.slice';
import { useAppDispatch, useAppSelector } from './redux/hooks/redux-hooks';
import { User as FirebaseAuthUser } from 'firebase/auth';
import { fetchUser } from './firebase/account/fetch-account';

init(AMPLITUDE_API_KEY);

const App = () => {
  const userAccount = useAppSelector(store => store.account);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {

    let observer: any = null;

    const addCurrentUserToStore = async (authUser: FirebaseAuthUser, param: any) => {
      // Not using arguments
      const firestoreUser: AccountState = await fetchUser();
      if (!firestoreUser) {
        await doSignOut();
        navigate(routes.SIGN_IN);
        return false;
      }
      dispatch(update_user_account(firestoreUser));
      navigate(param);
    }

    const setObserver = async () => {
      const authObserver = await monitorAuthState({
        username: userAccount.username,
        loggedIn: { cb: addCurrentUserToStore, param: routes.FEED, userParam: true },
        loggedOut: { cb: navigate, param: [routes.SIGN_UP, routes.SIGN_IN] },
      });
      observer = authObserver;
    }

    setObserver();
    
    return observer ? observer() : () => {};
  }, [navigate, dispatch, userAccount.username]);
  

  return (
    <SharedLayout userAccount={userAccount} />
  );
}

export default App;
