import Person from "../politician/person/Person";
import ActionButton from "../shared/action-button/ActionButton";
import { UnknownObject } from "../../interfaces/global";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/redux-hooks";
import { AccountState } from "../../redux/slices/account/account.slice";
import { handleFollowClick, foundUserInListToCheck } from "../../util/util.functions";

const SearchResultDisplay = ({
  searchResult,
  following,
  emailBody,
  emailSubject,
}: UnknownObject) => {
  const currentUser: AccountState = useAppSelector(store => store.account);
  const dispatch = useAppDispatch();
  const searchResultsJsx = searchResult.length !== 0 ?
    (<div className="search-result-display">
      {
        searchResult.map((user: any, idx: number) => {
          // determine if user is in following array,
          const isFollowed = foundUserInListToCheck(user.userId, following);
          return (
            <Person
              key={idx}
              name={user.fullname}
              description={user.username}
              action={
                <ActionButton
                  type={isFollowed ? "following" : "follow"}
                  label={isFollowed ? "UNFOLLOW" : "FOLLOW"}
                  onClick={() => handleFollowClick(user, currentUser, dispatch)}
                />
              }
            />
          );
        })
      }
    </div>) : null;

   const noSearchResultsJsx =
      <div className="no-result-container">
        <p>No results found.</p>
        <p>
          Grow your network. Share your prospetive while learning from others.
        </p>

        <a
          className="email-invite-link"
          href={`mailto:?subject=${emailSubject}&body=${emailBody}`}
        >
          INVITE
        </a>
      </div>

  return searchResultsJsx || noSearchResultsJsx
};

export default SearchResultDisplay;