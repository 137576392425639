import './count.css';
import { AccountState } from '../../../redux/slices/account/account.slice';
import { UnknownObject } from '../../../interfaces/global';

interface IsFollowingParam {
  following: any[];
  fullname: string;
  isPolitician: boolean | null;
}

const userIsFollowing = ({ following, fullname, isPolitician }: IsFollowingParam) => {
  return isPolitician && fullname ?
    following?.some(cur => cur === fullname) : null;
}

interface FollowTrackerParam {
  followingCount?: string | number;
  account: AccountState | UnknownObject;
  fullname: string;
  isPolitician: boolean | null;
}

const FollowTracker = (
    { followingCount = 0, account, fullname, isPolitician }: FollowTrackerParam  
  ) => {
    const followers = (
      <span className='number-of-followers'>
        {`${followingCount} Followers`}
      </span>
    );

    const politicianJsx = (
      <>
        {followers}
        {
          userIsFollowing(
            { following: account?.politiciansFollowing, fullname, isPolitician }
          ) ? 'Following' : 'Not Following'
        }
      </>
    );

    return (
      <div className='count-wrapper'>
        {isPolitician ? politicianJsx : followers}
      </div>
    );
  };

export default FollowTracker;
