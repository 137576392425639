import { useLocation } from 'react-router-dom';
import PropositionCard from './PropositionCard';
import './PropositionPage.css';

const propositions: any[] = [];

const PropositionPage = (props: any) => {
  const location = useLocation();
  return (
    <ul id="proposition-list">
      {(props.data ?? propositions).map((proposition: any, idx: number) => (
        <PropositionCard
          backRoute={location.pathname}
          key={idx} 
          congress={props.congress} 
          proposition={proposition}
          uniqueData={{ jurisdiction: props.jurisdiction }}
        />
      ))}
    </ul>
  );
};

export default PropositionPage;
