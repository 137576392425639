import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/redux-hooks';
import FeedWrap from '../../main-feed/feed.comments/FeedWrap';
import { getPoliticianFeedAndDbData } from '../../../api/firebase-functions/api.news-stories-politician';
import { sortFeed } from '../../main-feed/utils/feedSort';
import { politicians_increment_page_counter, save_feed_with_db_data_scroll } from '../../../redux/slices/politicians/politicians.slice';
import { UnknownObject } from '../../../interfaces/global';
import { RootState } from '../../../redux/store';
import Story from '../../story/Story';
import useInfiniteFeedScroll from '../../../hooks/useInfiniteFeedScroll';
import Loader from '../../shared/loading/loader';
import NoResult from '../../shared/no-result/NoResult';

interface FeedGenericParam {
  fetchFeedParam: any;
  style?: UnknownObject;
}
// FeedGeneric({ feedType: 'newsfeed', })
const PoliticianFeed = (
  { 
    fetchFeedParam,
    style,
  }: FeedGenericParam
) => {

  const { politicians, account } = 
    useAppSelector((state: RootState) => state);

  const initialFeedAdded = useRef(false);
  const feedRef = useRef(null);

  const infiniteFeedApiCall = useCallback(async () => {
    const { data, pagination } = 
      await getPoliticianFeedAndDbData({ fetchFeedParam, pagination: politicians.pagination });
    const sortedFeed = 
      sortFeed({
        stories: data,
      });

    return { feed: sortedFeed, pagination };
  }, [fetchFeedParam, politicians.pagination,]);

  const [status] = useInfiniteFeedScroll({
    feedReference: feedRef, 
    apiCall: infiniteFeedApiCall,
    saveFeedRedux: save_feed_with_db_data_scroll,
    incrementCounterRedux: politicians_increment_page_counter
  });

  const dispatch = useAppDispatch();

  const addFeedToStore = useCallback(async () => {
    const {
      data,
      pagination
    } = await getPoliticianFeedAndDbData({ fetchFeedParam, pagination: 1 });

    const sortedFeed = sortFeed({
      stories: data
    });

    dispatch(save_feed_with_db_data_scroll({ 
        feed: sortedFeed,
        pagination,
        andQuery: fetchFeedParam.andQuery,
      })
    );

    dispatch(politicians_increment_page_counter());
  }, [dispatch, fetchFeedParam]);

  useEffect(() => {
    if (account.userId && !initialFeedAdded.current) {
      initialFeedAdded.current = true
      addFeedToStore();
    }
  }, [account.userId,  addFeedToStore]);

  const stories = useCallback(() => {
    return politicians.politiciansNewsFeed.map((post, idx) => {
      const { data } = post;
      return <Story key={idx} data={data} />
    });
  }, [politicians]);
  
  return (
    <FeedWrap style={style }>
      {stories()}
      {status === 'pending' ? <Loader /> : politicians.politiciansNewsFeed.length < 0 ? <NoResult /> : null}
    </FeedWrap>
  )
}

export default PoliticianFeed;