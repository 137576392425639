export type EntityType = { 
  [key: string]: any;
}

export const ENTITY_TYPE: EntityType = {
  story: 'story',
  politician: 'politician',
  proposition: 'proposition',
  bill: 'bill'
}

export const ENTITIES_TYPE: EntityType = {
  ['story' || 'stories']: 'stories',
  ['politician' || 'politicians']: 'politicians',
  ['proposition' || 'propositions']: 'propositions'
}
