import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  saveBills, 
  savePoliticianData, 
  savePropositions 
} from '../../thunks/politicians-ballot/politicians.thunks';
import { tempOfficialData } from '../../../components/politician/util/util.politician';
import { updateFeedData } from '../../thunks/news-stories/stories.helper-functions';

interface PoliticiansState {
  isLoading: boolean;
  currentOfficial: any;
  data: any;
  bills: any;
  propositions: any;
  politiciansNewsFeed: any[];
  pagination: number;
}

const initialState: PoliticiansState = {
  currentOfficial: tempOfficialData,
  isLoading: false,
  politiciansNewsFeed: [],
  pagination: 1,
  data: null,
  bills: [],
  propositions: { jurisdiction: null, data: null },
};

export const politiciansSlice = createSlice({
  name: 'politicians',
  initialState,
  reducers: {
    fetch_politicians_state: ( state, action: PayloadAction<PoliticiansState> ) => {
      return {
        ...state,
        ...action.payload
      }
    },
    update_current_official: ( state, actions: PayloadAction<any> ) => {
      state.currentOfficial = actions.payload;
    },
    clear_current_official: ( state ) => {
      state.currentOfficial = tempOfficialData;
    },
    update_politicians_feed: (state, action: PayloadAction<any[]>) => {
      state.politiciansNewsFeed = updateFeedData({
        feed: state.politiciansNewsFeed, feedItemsChanged: action.payload
      })
    },
    save_feed_with_db_data_scroll: (state, action) => {
      const { data, pagination } = action.payload;
      if (pagination > 1) {
        state.politiciansNewsFeed.push(...data)
      } else {
        state.politiciansNewsFeed = data;
      }
    },
    politicians_increment_page_counter: (state) => {
      state.pagination += 1;
    },
    mix_reset_page_counter: (state) => {
      state.pagination = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(savePoliticianData.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(saveBills.fulfilled, (state, action) => {
        /*
          Working on pagination - after we get the new data
          check the current bills and add only the new data to the end
        */
        state.bills = action.payload;
      })
      .addCase(savePropositions.fulfilled, (state, action) => {
        state.propositions = action.payload;
      })
  },
})

export const { 
  fetch_politicians_state, 
  update_current_official,
  update_politicians_feed,
  clear_current_official,
  save_feed_with_db_data_scroll,
  politicians_increment_page_counter
} = politiciansSlice.actions;

export default politiciansSlice.reducer;
