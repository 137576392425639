import { useState, useEffect } from "react";
import { UnknownObject } from "../interfaces/global";

const useOnce = (fn: Function | UnknownObject) => {
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [result, updateResult] = useState(null);

  useEffect(() => {
    let ignore = false;
    if (!isCalled) {
      setIsCalled(true);
      if (fn instanceof Function && fn.constructor.name === 'AsyncFunction') {
        const waitForCall = async () => {
          const res = await fn();
          if (ignore) {
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          updateResult(res);
        };
        waitForCall();
      } else {
        setIsLoading(false);
        updateResult(
          fn instanceof Function ? fn() : fn
        );
      }
    }
    return () => {
      ignore = true;
    }
  }, [fn, isCalled, setIsCalled, result, isLoading]);

  return [result, isLoading];
};

export default useOnce;
