import { useEffect, useState } from "react";
import ProfileImage from "../politician-profile/ProfileImage";
import { fetchDefaultImage } from "../../../api/api.wikipedia";
import useOnce from "../../../hooks/useOnce";
import "./PoliticianListProfile.css";
import { fetchPoliticianTickerData } from "../../../firebase/politician-ballot/fetch-politicians";
import CommentAndSupport from "../../shared/comment-support/CommentAndSupport";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/redux-hooks";
import { handleSupport } from "../../../firebase/shared/handle-support";
import { ENTITY_TYPE } from "../../../constants/entities";
import { Link } from "react-router-dom";
import { CommentWindow } from "../../comments/comment-window/CommentWindow";

interface PersonState {
  defaultImage: string | null;
  error: string | boolean;
}

const PoliticianListProfile = ({ 
  entityData, 
  name, 
  description, 
  action, 
  party, 
  photoURL, 
  showCommentFeature = true 
}: any) => {
  
  const [state, setState] = useState<PersonState>({
    defaultImage: photoURL,
    error: false,
  });

  
  const [ commentWindow, toggleCommentWindow ] = useState(false);
  const [ supportUpdated, updateSupport ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ loading, setIsLoading ] = useState(true);
  const [ newCommentAdded, trackCommentAdded ] = useState(false);
  
  const triggerSupport = () => updateSupport(prev => !prev);
  
  const dispatch = useAppDispatch();
  const userAccount = useAppSelector(state => state.account);
  
  const { politiciansSupporting, politiciansOpposing } = userAccount;
  
  const userSupportsPolitician = 
    politiciansSupporting.includes(name) ? true :
    politiciansOpposing.includes(name) ? false : null;
  
  const [tickerData, setTickerData] = useState<any>({ 
    appData: { 
      id: '',
      opposing: [],
      supporting: [],
      numOfComments: 0,
      uniqueData: {}
    }, 
    entityData
  });
  
  const entityInfo = { 
    id: tickerData?.appData?.id || name, 
    type: ENTITY_TYPE['politician'] 
  };

  useEffect(() => {
    let ignore = false;

    const getData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // This is if we have data from the db
        const officialFromDb: any = await fetchPoliticianTickerData(name);
        if (ignore) return;
        if (officialFromDb) {
          if (!officialFromDb?.appData?.numOfComments) {      
            officialFromDb.appData.numOfComments = 0;  
            setTickerData(officialFromDb);
          } else {
            setTickerData(officialFromDb);
          }
        }
        setIsLoading(false);
      } catch (e) {
        setError((e as any)?.message);
        setIsLoading(false);
        console.error(e);
      }
    }

    getData();

    return () => {
      ignore = true;
    }
  }, [supportUpdated, name, newCommentAdded]);

  useOnce(() => {
    // let ignore = false;
    if (name && !photoURL) {
      const getData = async () => {
        try {
          const defaultImage = await fetchDefaultImage(name);
          // if (ignore) return;
          setState({...state, defaultImage });
        } catch (e) {
          setError((e as any)?.message);
          console.error(e);
        }
      }
      getData();
    }
    // return () => {
    //   ignore = true;
    // }
  });

  const onSupport = () => {
    const userSupports = politiciansSupporting.includes(name);
    handleSupport({
      entityInfo,
      entityData,
      userAccount,
      doesSupport: !userSupports || null,
      localStateRefresh: triggerSupport,
      dispatch
    })
  }

  const onOppose = () => {
    const userOpposes = politiciansOpposing.includes(name);
    handleSupport({
      entityInfo,
      entityData,
      userAccount, 
      doesSupport: userOpposes && null,
      localStateRefresh: triggerSupport,
      dispatch
    })
  }

  return (
    <li className="person-wrapper" style={{ marginBottom: '0px' }}>
      <div className="person-profile-container">
        <ProfileImage party={party} defaultImage={state.defaultImage} />
        <div className="content-and-btn">
          <div className="content-wrapper">
            <h4 className="content-header">{name}</h4>
              {description !== undefined ? <p>{description}</p> : null}
              {/* action button */}
              {action !== undefined ? action : null}
          </div>
        </div>
      </div>

      <div className="user-interaction-container" style={{ marginBottom: '0px'}}>     
        <CommentAndSupport
          userSupportsEntity={userSupportsPolitician} 
          handleSupport={onSupport} 
          handleOppose={onOppose}
          openCommentWindow={() => toggleCommentWindow(true)}
            tickerData={{ 
              supporting: tickerData?.appData?.supporting || [], 
              opposing: tickerData?.appData?.opposing || [] 
            }}
          theme={"light"}
        />
          {
            commentWindow ?
             <CommentWindow
              closeCommentWindow={() => toggleCommentWindow(false)}
              doesSupport={userSupportsPolitician}
              entityData={entityData}
              entityInfo={entityInfo}
              handleOppose={onOppose}
              handleSupport={onSupport}
              newCommentAdded={newCommentAdded}
              trackCommentAdded={trackCommentAdded}
              uniqueData={tickerData?.appData.uniqueData}
            /> : null 
          }
        </div>
        <div className="number-of-comments">
        {
          showCommentFeature && tickerData?.appData.numOfComments ? 
            <Link to={`/comments/politician/${name}`} className="view-comment-container">
              View {tickerData.appData.numOfComments} {tickerData.appData.numOfComments > 1 ? 'comments' : 'comment'}
            </Link>
            : null
        }
      </div>

    </li>
  );
};

export default PoliticianListProfile;
