import { useState } from "react";
import "../feed.css";
import { useAppSelector } from "../../../redux/hooks/redux-hooks";
import { RetrievedComment, RetrievedCommentData } from "../../../interfaces/comment-entities.interfaces";
import FeedCommentContainer from "./FeedCommentContainer";
import PoliticianFeedComment from "../../comments/comment-types/PoliticianFeedComment";
import { ENTITY_TYPE } from "../../../constants/entities";
import PropositionCard from "../../ballot/proposition/PropositionCard";
import Story from "../../story/Story";

interface Param {
  feedType?: string;
  insertedFeed?: any[];
}

const CommentFeed = ({ feedType, insertedFeed }: Param) => {
  const store = useAppSelector(store => store);
  const [replyBoxIsOpen, toggleShowReplyBox] = useState(false);

  const { account, comments } = store;

  const types: any = {
    'commentsFeed': comments.commentsFeed,
    'recentActivity': account.recentActivity,
  }

  const feed = insertedFeed || types[feedType as string];
  
  const commentsJSX = () => { 
    return feed && feed.reduce(
      (acc: any[], data: RetrievedComment, idx: number) => {
        // Going to need the data here for each entity...
        const { comment, entity } = data;
        const { entityInfo } = comment;
        const { type } = entityInfo;

        // Need to get a Comment out of UserFollowersComment...
          acc.push(
            <FeedCommentContainer
              replyBoxIsOpen={replyBoxIsOpen} 
              toggleShowReplyBox={toggleShowReplyBox} 
              key={comment.id} 
              commentData={comment as RetrievedCommentData}
              entityInfo={entityInfo}
            >
              { 
                type === ENTITY_TYPE['politician'] ?
                  <PoliticianFeedComment
                    data={data} 
                  /> : 
                type === ENTITY_TYPE['proposition'] ?
                  <PropositionCard
                    uniqueData={entity.appData.uniqueData}
                    proposition={entity.entityData}
                    isFeedComment={true} 
                  /> : 
                type === ENTITY_TYPE['story'] ?
                  <Story
                    data={entity}
                    idx={idx}
                    isFeedComment={true}
                  /> :
                null
              }
            </FeedCommentContainer>
          )
          return acc;
      }, []);
    }

  return commentsJSX();
}

export default CommentFeed;