import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import { auth } from "../../firebase/instance";
import { functions } from "../../firebase/instance";
import { addFirebaseDataToStories } from "../../redux/thunks/news-stories/stories.thunks-action-creators";
import { NewsStoriesRes } from "../../redux/interfaces/news-stories.interface";

interface PoliticianPerigonFeed {
  fetchFeedParam: any;
  pagination: number;
}

interface Res {
  data: NewsStoriesRes;
}


export const fetchPoliticianPerigonFeed = 
  async ({ fetchFeedParam, pagination }: PoliticianPerigonFeed): Promise<NewsStoriesRes> => {
    const currentUser = auth.currentUser;
    const userToken = await currentUser?.getIdToken(true);
    //   medias: account.medias, 
    //   interests: account.interests,
    //   fetchFeedParam,
    //   pageNum: pagination
    const defaultRes = {
      status: "", 
      totalResults: 0, 
      articles: []
    };

    if (userToken) {
      try {            
        // call clound function fetchNews, with userToken (for cloud function to talk to db)
        const fetchPoliticianNews = httpsCallable(functions, "fetchPoliticianNewsFromNewsApi");
        // console.log({ medias, interests, userToken });
        const response = await fetchPoliticianNews({
          pagination,
          fetchFeedParam,
          userToken
        }) as unknown as Res;
        // articles (response) come back from cloud function (server): stories from NewsApi with userShares
        // const newsfeed = await addFirebaseDataToStories(response.data?.articles, 'STORIES ACTIONS');
        // return newsfeed;
        return response.data;
      } catch (err) {
        console.error(err);
        return defaultRes;
      }
    } else {
      console.error("User is not logged in");
      return defaultRes;
    }
  };


export const fetchPoliticianNews = async ({ politicianName, medias, andQuery, account }: any) => {
  // fetch news
    const { userId } = account;

    const result = {
      data: [],
    }

    try {
      const fetchPoliticianNewsFromNewsApi = 
        httpsCallable(functions, "fetchPoliticianNewsFromNewsApi");

      const { data }: HttpsCallableResult<any> = 
        await fetchPoliticianNewsFromNewsApi({
          politicianName,
          medias,
          andQuery,
          userId
        });

      return {
        data
      }
    } catch (e) {
      console.error("Error in fetchPoliticianNews: " + e);
    }

    return result;
  };

export const getPoliticianFeedAndDbData = 
  async ({ fetchFeedParam, pagination = 1 }: any) => {

    const res = await fetchPoliticianPerigonFeed({
      fetchFeedParam,
      pagination
    });

    const data = await addFirebaseDataToStories(res.articles);
    return { data, pagination }
  }