import { toggleFollowReq } from "../firebase/people/follow-people";
import { AccountState, update_user_followers } from "../redux/slices/account/account.slice";
import { AppDispatch } from "../redux/store";

export const processStoryIdForDB = (storyId: string): string => {
  return storyId?.trim().split("/").join("_");
}

interface FilterSupport {
  opposing: string[];
  supporting: string[];
  doesSupport: boolean | null;
  filterOn: string;
}

export const filterSupport = 
  ({ opposing, supporting, doesSupport, filterOn }: FilterSupport) => {
    // Filter out any inconsistencies between user support & oppose arrays.
    const filteredOppose = 
      doesSupport || doesSupport === null ? 
      opposing.filter(cur => cur !== filterOn) :
      [...opposing]
    const filteredSupport =
      doesSupport ? [...supporting] :
      supporting.filter(cur => cur !== filterOn);
    // If null, leave arrays alone
    if(doesSupport) { 
      filteredSupport.push(filterOn);
    } else if(doesSupport === false) { 
      filteredOppose.push(filterOn);
    }
    return [ filteredSupport, filteredOppose ];
  }
  
export const handleFollowClick = (
    targetUser: AccountState | { userId: string }, 
    currentUser: AccountState, 
    dispatch: AppDispatch
  ) => {
  // follow user if this user is not followed    
  console.log({ targetUser });
  toggleFollowReq(currentUser.userId, targetUser.userId)
    .then((isFollowing) => {
      console.log({ isFollowing })
      // here we are making the decision to update our client store manually
      dispatch(
        update_user_followers({ 
          userId: targetUser.userId,
          isFollowing: isFollowing as boolean 
        })
      );
    })
    .catch(err => console.error("Error following user: " + err));
};

export const foundUserInListToCheck = (userId: string, listToCheck: any[] | null = null, isFollowing = false) => {
  return isFollowing ? true : listToCheck?.includes(userId);
};