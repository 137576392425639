import { Link } from 'react-router-dom';
import './NameAndEmail.css'
import { MouseEvent, useState } from 'react';
import TextInput from '../../../shared/text-input/TextInput';
import HeaderText from '../../../shared/header-text/HeaderText';
import PrimaryButton from '../../../shared/primary-button/PrimaryButton';
import { authUtils } from '../../../../firebase/instance';
import { UnknownObject } from '../../../../interfaces/global';
import { useAppDispatch } from '../../../../redux/hooks/redux-hooks';
import {
  updateUserInfo,
  updateAuthState
} from '../../../../redux/slices/sign-up/sign-up.slice';
import { SIGN_IN } from '../../../../constants/routes';

interface Param {
  onNext: Function;
  emailInUseError: string;
}

interface State {
  error: UnknownObject;
}

const NameAndEmail = ({ onNext, emailInUseError='' }: Param) => {
  const [state, setState] = useState({ name: '', email: ''});
  const dispatch = useAppDispatch();

  const [errorState, setErrorState] = useState<State>({
    error: {
      emailInUseError
    }
  });

  const validateInput = async () => {
    let error = null;
    const { name, email } = state;
    let signInMethods = await authUtils.doFetchSignInMethodsForEmail(email);

    if(signInMethods.length > 0) {
      error = "emailExists";
    }
    else if (!email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
      error = "email";
    }
    else if (name.length < 1) {
      error = "fullname";
    }
    return error;
  };

  const handleClick = (e: MouseEvent) => {
    validateInput().then(error => {
      switch (error) {
        case "fullname":
          setErrorState({
            error: {
              ...errorState.error,
              fullnameError: "Please enter a valid fullname.*",
            },
          });
          break;
        case "email":
          setErrorState({
            error: {
              ...errorState.error,
              emailError: "Please enter a valid email.*",
            },
          });
          break;
        case "emailExists":
          setErrorState({
            error: {
              ...errorState.error,
              emailError: "This account already exists.*",
            },
          });
          break;
        case null:
          dispatch(updateUserInfo({
            fullname: state.name
          }));
          dispatch(updateAuthState({
            email: state.email
          }));
          onNext();
          break;
        default:
          throw new Error(`unknown error ${error}`);
      }
    }).catch(e => {
      console.error(e);
    });
  };

  return (
    <div className='section-container'>
      <div className='name-email-wrapper'>
        <HeaderText
          styleColor='light'
          headerText='Political Visibility'
          subHeaderText='ISSUES THAT MATTER'
        />
        <div>
          <TextInput
            styleColor='light'
            placeholder='FULL NAME'
            value={state.name}
            onTextInputChange={(event) =>
              setState({ ...state, name: event.target.value })
            }
            errorMessage={errorState.error.fullnameError}
            errorMessageColor='white'
          />
          <TextInput
            styleColor='light'
            placeholder='EMAIL'
            value={state.email}
            onTextInputChange={(event) => {
              setState({ ...state, email: event.target.value })
            }}
            errorMessage={errorState.error.emailError}
            errorMessageColor='white'
          />
          <div className='PrimaryButton'>
          <PrimaryButton label='CREATE ACCOUNT' onClick={handleClick} />
          </div>
        </div>
        <div className='mobilize-logo-container'>
          <img
            className='mobilize-logo'
            alt='Mobilize Logo'
            src='/img/mobilizeIconWhitetext.svg'
          />
        </div>
      </div>

      <div className='footer'>
        <p className='footer-text'>Already have an account?</p>
        <Link to={SIGN_IN}>
          <span className='login-link'>Login.</span>
        </Link>
      </div>
    </div>
  );
};

export default NameAndEmail;
