//const NEWS_API_KEY = `4cc9c7ee4a11464aade03f85d51fd52a`;
const GOOGLE_API_KEY = `AIzaSyCR4Ms57G1IXXudbQKN3yl7C_Jpq6qwCaI`;
export const OPENSTATE_URI = "https://open.pluralpolicy.com/graphql";
export const OPENSTATE_REST_URI = "https://v3.openstates.org/";
export const OPENSTATE_API_KEY = "a03d661b-18a9-4aa2-981c-f0684529bb0d";

export const DATA_GOV_API_KEY = "2PbDzcT3CRJdHtkUT5sDdtiINbPorO3mchrZStXj";
export const DATA_GOV_API_URL = `https://api.congress.gov/v3/bill/117?fromDateTime=2022-08-04T04:02:00Z&toDateTime=2022-09-30T04:03:00Z&sort=updateDate+desc`;

export const getRepresentativesUrl = ({ zip }: any) => {
  return `https://www.googleapis.com/civicinfo/v2/representatives?key=${GOOGLE_API_KEY}&address=${zip}`;
};

export const PROPUBLICA_URL = `https://api.propublica.org/congress/v1/bills/search.json?`;
export const PROPUBLICA_API_KEY = "Gpre4uNMRkajlmpGwPSqAO19iR0UcMK7eyJnzqXO";
export const AMPLITUDE_API_KEY = "c8349290bf562e6b89fce72dc5864b56";
