import { useCallback, useEffect, useState } from "react";
import "./BillProfile.css";
import { useLocation, useMatches } from "react-router-dom";
import BillLayout from "./BillLayout";
import { UnknownObject } from "../../../interfaces/global";
import { fetchBillSummary } from "../../../api/api.civic-api-politicians";
import { fetchBillTickerDataById } from "../../../firebase/bills/fetch-bill-data";


const BillProfile = () => {
  const [billSummaryData, updateBillSummaryData] = useState<UnknownObject>({ data: null, isLoading: true, error: null });
  const [route, setBackRoute] = useState();

  const billId = useMatches()[0].params.billId;
  const { state } = useLocation();
  const { packageLink } = state;

  useEffect(() => {
    if (state && state.backRoute) {
      setBackRoute(state.backRoute);
    }
  }, [state]);

  useEffect(() => {
    // Do we need to check the database?
    // we could check the database with the billId, then
    // if the bill is already there, we get app data?
    // we can use last modified to update chagnes in db
    let ignore = false;

    const fetchSummaryAndSave = async () => {
      updateBillSummaryData({ data: null, isLoading: true, error: null });
      try { 
        const appData = await fetchBillTickerDataById(billId as string);
        // If appData - check last modified - if different, update
        const entityData = await fetchBillSummary(state.packageLink);
        console.log({ appData, entityData: entityData.data });
        if (ignore) {
          return;
        }   
        updateBillSummaryData({ data: { entityData: entityData.data, appData }, isLoading: false, error: null });
      } catch (e) {
        updateBillSummaryData({ error: e, isLoading: false, data: null });
      }
    }

    fetchSummaryAndSave();

    return () => {
      ignore = true;
    }
  }, [state, packageLink, billId]);

  // should update ticker data here
  const updateAppData = useCallback(async () => {
    try {
      const appData = await fetchBillTickerDataById(billId as string);
      if (appData) {
        updateBillSummaryData(prev => {
          return {
            ...prev,
            data: {
              ...prev.data,
              appData
            }
          }
        });
      }
    } catch (e) {
      console.error(e, ` issue updating ticker data for bill ${billId}`);
    }
  }, [billId]);

  return (
    <BillLayout 
      billId={billId as string}
      jurisdiction={"Federal"} 
      billData={billSummaryData}
      backRoute={route}
      localStateRefresh={updateAppData}
    />
  );
};

export default BillProfile;
