export const miliToHours = (mili: number) => {
  return Math.floor(mili / 1000 / 60 / 60);
}

export const hoursAgo = (utc: number | undefined) => {
  if (typeof utc === 'number') {
    const hours = miliToHours(Date.now() - utc);
    if(hours / 24 > 2) return `${Math.floor((hours / 24))} days ${Math.floor(((hours / 24) % 1) * 24)} hours ago`;
    else return `${hours} hours ago`;
  } 
  return '';
}

export const displaySupport = (supported: boolean | undefined | null) => {
  if(supported === null || supported === undefined) return;
  return <div className={`feed-supported ${supported ? '' : 'feed-opposed'}`}>
    <img
      src={`/img/icons/${supported ? 'thumbUp.svg' : 'thumbDown.svg'}`}
      alt={`${supported ? 'thumb up' : 'thumb down'}`}
      className={`${supported ? 'thumbUp-img' : 'thumbDown-img'}`}
    />
    {supported ? "Supported" : "Opposed"}
  </div>
}