import { ReactNode } from "react";
import "./EditLayout.css";

interface Param {
  label: string;
  description?: string;
  content: ReactNode;
}

const EditLayout = ({ label, description, content }: Param) => {
  return (
    <div className="edit-text-input-container">
      <div className="label-container">
        <span className="label">{label}</span>
        <span className="description">{description}</span>
      </div>
      <div className="content">{content}</div>
    </div>
  );
};

export default EditLayout;
