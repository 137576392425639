import Typesense from "typesense";

export const typeSenseClient = new Typesense.Client({
  nodes: [{
    host: "localhost",
    port: 8108,
    protocol: "http",
  }],
  apiKey: "xyz",
  connectionTimeoutSeconds: 2,
});

interface Param {
  collectionName: string;
  search: { q: string, query_by: string }
}

export const onUserCreate = (snapshot: any, context: any) => {
  const id = context.params.userId;
  const {username, fullname} = snapshot.data();
  const document = {id, username, fullname};
  return typeSenseClient.collections("users").documents().create(document);
};

export const queryTypesense = async (queryParams: Param) => {
  const {collectionName, search} = queryParams;
  const result = await typeSenseClient.collections(collectionName)
      .documents()
      .search(search);
  return result;
};

// export const typesenseUserCreate =
//   functions.firestore.document("/users/{userId}").onCreate(onUserCreate);
