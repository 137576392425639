import FeedWrap from "../main-feed/feed.comments/FeedWrap";
import { useEffect, useState } from "react";
import NoResult from "../shared/no-result/NoResult";
import { useAppSelector } from "../../redux/hooks/redux-hooks";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/instance";
import UsersListDisplay from "./UsersListDisplay";


const Following = () => {
  const [isCalled, setIsCalled] = useState<boolean>(false);
  const [followingData, updateFollowingData] = useState<any[]>([]);
  const { following } = useAppSelector(state => state.account);

  useEffect(() => {
    if (!isCalled && following && following?.length > 0) {
      const fetchFollowing = httpsCallable(functions, "fetchFollowing");
      
      const fetchAndSetFollowingData = async () => {
        const { data: followingData } = await fetchFollowing({ following });
        if (Array.isArray(followingData)) {
          updateFollowingData(followingData);
        }
        setIsCalled(true);
      }

      fetchAndSetFollowingData();
    }

  }, [isCalled, following]);

  
  return <FeedWrap>
    { 
      followingData.length > 0 ? 
      <UsersListDisplay users={followingData} listToCheck={following} /> :
      <NoResult />
    }
  </FeedWrap> 
}

export default Following;