import { useAppDispatch, useAppSelector } from "../../redux/hooks/redux-hooks";
import { AccountState } from "../../redux/slices/account/account.slice";
import { handleFollowClick, foundUserInListToCheck } from "../../util/util.functions";
import Person from "../politician/person/Person";
import ActionButton from "../shared/action-button/ActionButton";

interface User {
  fullname: string;
  userName: string | null;
  photoURL: string | null;
  userId: string;
}

interface Param {
  users: User[];
  listToCheck?: string[];
  isFollowing?: boolean;
}

const UsersListDisplay = ({ users, listToCheck, isFollowing = false }: Param) => {
  const currentUser: AccountState = useAppSelector(store => store.account);
  const dispatch = useAppDispatch();

  return (
    <>
      {
        users.map(({ fullname, userName, photoURL, userId }, idx ) => (
          <Person
            key={idx}
            name={fullname}
            description={userName}
            photoURL={photoURL}
            action={
              <ActionButton
                type={"following"}
                label={foundUserInListToCheck(userId, listToCheck, isFollowing) ? "UNFOLLOW" : "FOLLOW"}
                onClick={() => handleFollowClick({ userId }, currentUser, dispatch)}
              />
            }
          />
        ))
      }
    </>
  )
}

export default UsersListDisplay;