import { Outlet } from "react-router-dom";
import { AccountState } from "../../redux/slices/account/account.slice";
// import NavBar from "../navbar/NavBar";

interface Param {
  userAccount: AccountState;
}

const SharedLayout = ({ userAccount }: Param) => (
  <Outlet context={userAccount} />
)

export default SharedLayout;