import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchBills, fetchPoliticianData, fetchProposition } from "../../../api/api.civic-api-politicians";

export const savePoliticianData = createAsyncThunk(
  'politicians/savePoliticianData',
  async () => {
    const data = await fetchPoliticianData();
    return data;
  }
);

export const saveBills = createAsyncThunk(
  'politicians/saveBills',
  async () => {
    const bills = await fetchBills();
    return bills;
  }
);

export const savePropositions = createAsyncThunk(
  'politicians/savePropositions',
  async (jurisdiction: string) => {
    const data = await fetchProposition(jurisdiction);
    return { jurisdiction, data };
  }
);
