import { arrayRemove, arrayUnion, doc, DocumentReference, WriteBatch, writeBatch } from "firebase/firestore";
import { ENTITY_TYPE } from "../constants/entities";
import { CollectionRef, collectionRefObj, firestore } from "../firebase/instance";
import { EntityInfo } from "../interfaces/comment-entities.interfaces";
import { UnknownObject } from "../interfaces/global";
import { AccountState } from "../redux/slices/account/account.slice";

interface EntitySupportParam {
  entityInfo: EntityInfo;
  entityData: any;
  uniqueData?: UnknownObject | null;
  userAccount: AccountState;
  doesSupport: boolean | null;
}

export const handleChangeOfEntitySupport = 
  async ({ entityInfo, entityData, uniqueData = null, userAccount, doesSupport }: EntitySupportParam) => {
    console.log({ entityInfo, entityData, uniqueData, userAccount, doesSupport }, "<-- ");
    const { id, type } = entityInfo;
    const { userId } = userAccount;
    const entity = ENTITY_TYPE[type];
    if (!entity) throw Error("Bad entity type");

    const entityFirebaseRef = collectionRefObj[type as keyof CollectionRef];
    
    if (!entityFirebaseRef) return;
    
    const entityFirebaseDoc: DocumentReference = doc(entityFirebaseRef, id);

    try {
      const batch: WriteBatch = writeBatch(firestore);
      if (doesSupport === null) {
        batch.set(entityFirebaseDoc, {
          appData: {
            id,
            supporting: arrayRemove(userId),
            opposing: arrayRemove(userId),
            uniqueData
          },
          entityData
        }, { merge: true });
      } else {
        batch.set(entityFirebaseDoc, {
          appData: {
            id,
            [doesSupport ? "supporting" : "opposing"]: arrayUnion(userId),
            [doesSupport ? "opposing" : "supporting"]: arrayRemove(userId),
            uniqueData
          },
          entityData
        }, { merge: true });
      }
      await batch.commit();
    } catch (e) {
      console.error("Error trying to change entity support: ", e);
    }
  }


interface ResolveSupport {
  supportKey: string;
  opposeKey: string;
  entityId: string;
  doesSupport: boolean | null;
}

export const resolveSupport = (
  { 
    supportKey, 
    opposeKey, 
    entityId, 
    doesSupport 
  }: ResolveSupport
) => {
  if (doesSupport === null) {
    return {
      [supportKey]: arrayRemove(entityId),
      [opposeKey]: arrayRemove(entityId)
    }
  } else {
    return {
      [doesSupport ? supportKey : opposeKey]: arrayUnion(entityId),
      [doesSupport ? opposeKey : supportKey]: arrayRemove(entityId)
    }
  }
}
