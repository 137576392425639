import "./Badge.css";

const Badge = ({ party }: any) => {
  let badgeSvg = null;

  switch (party) {
    case "Democratic Party":
      badgeSvg = "/img/blueBadge.svg";
      break;
    case "Democratic":
      badgeSvg = "/img/blueBadge.svg";
      break;
    case "Republican Party":
      badgeSvg = "/img/redBadge.svg";
      break;
    case "Republican":
      badgeSvg = "/img/redBadge.svg";
      break;
    default:
      badgeSvg = null;
  }

  if (badgeSvg === null) {
    return null;
  } else {
    return (
      <div className="badge-container">
        {party === "Nonpartisan" ? null : (
          <img src={`${badgeSvg}`} alt="badge" className="badge-svg" />
        )}
      </div>
    );
  }
};

export default Badge;
