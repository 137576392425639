import { PerigonEntityData } from "../../../interfaces/comment-entities.interfaces";
import { UnknownObject } from "../../../interfaces/global";
import '../story.css';

interface Param {
  entityData: PerigonEntityData;
}

const NewsImageContainer = ({ entityData }: Param) => {
  const { urlToImage } = entityData;
  return (
    <div
      className="news-img-container"
      style={{
        backgroundImage: `url(${urlToImage || (entityData as UnknownObject).imageUrl})`,
        backgroundSize: "cover",
        height: "150px",
      }}
    />
  )
}

export default NewsImageContainer;