import { EntityInfo } from "../../../interfaces/comment-entities.interfaces";
import { UnknownObject } from "../../../interfaces/global";
import { AccountState } from "../../../redux/slices/account/account.slice";
import { accountEntitySupport } from "../../../redux/thunks/account/account.thunks";
import { addCommentSequence } from "./add-comment";

interface PublishCommentParam {
  entityData: UnknownObject;
  entityInfo: EntityInfo;
  newCommentAdded: boolean;
  doesSupport: boolean | null;
  trackCommentAdded: Function;
  uniqueData: UnknownObject | null;
  userAccount: AccountState;
  dispatch: Function;
}

// Need to standardise supporting / doesSupport
// currently doesSupport makes the most sense
export const handlePublishComment = async (
    { 
      entityData,
      entityInfo,
      uniqueData,
      doesSupport, 
      userAccount,
      dispatch,
      trackCommentAdded
    }: PublishCommentParam, 
    state: UnknownObject
  ) => {
  const { photoURL, username, userId } = userAccount;
  const contentToAdd = state.inputValue.trim();
  if (state.inputValue) {
    addCommentSequence({
      data: {
        id: "tbd",
        content: contentToAdd,
        userData: { photoURL, username, userId },
        doesSupport,
        entityInfo
      },
      entityData,
      uniqueData
    }).then(() => {
      dispatch(
        accountEntitySupport({
          entityInfo,
          userAccount,
          doesSupport
        })
      ).then(() => {
        trackCommentAdded();
      })
    }).catch(e => {
      console.error("Error handling support: " + e);
    })
  }
};
