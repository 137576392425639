import { collection, runTransaction } from "firebase/firestore";
import { firestore } from '../../instance';
import { 
  doc,
  // DocumentData,
  DocumentReference, 
  // DocumentSnapshot, 
  increment 
} from "firebase/firestore";
import { 
  commentsCollectionRef,
  // userFollowersCollectionRef, 
  storiesCollectionRef
} from "../../instance";
import { 
  Comment, 
  // UserData, 
  EntityInfo 
} from "../../../interfaces/comment-entities.interfaces";

interface ChildCommentParam {
  childData: Comment;
  parentData: Comment;
  entityInfo: EntityInfo;
}
// We should add the new comment to the current users user-followers document...

export const handleAddChildComment = 
  async ({ childData, parentData, entityInfo }: ChildCommentParam) => {
    const { id: entityId, type: entityType }: EntityInfo = entityInfo;
    const { id: parentId }: Comment = parentData;
    // const { userId: parentUserID }: UserData = userData;

    // const userFollowersRef: DocumentReference = doc(userFollowersCollectionRef, parentUserID);
    const storyDocRef: DocumentReference | null = entityType === 'story' ? doc(storiesCollectionRef, entityId) : null;
    const parentDoc: DocumentReference = doc(commentsCollectionRef, parentId);

    const newChildDoc = doc(collection(parentDoc, "childComments"));
    
    // Updating childComments array with new childComment in user-followers document and adding child doc
    try {
      await runTransaction(firestore, async t => {
        // const docUserFollowers: DocumentSnapshot = await t.get(userFollowersRef);
        // if(!docUserFollowers.exists) return;

        // const docData: DocumentData | undefined = docUserFollowers.data();
        childData.id = newChildDoc.id;

        // Add comment data to a new document (commentsSubRef) of parent comments sub-collection (childComments)
        t.set(newChildDoc,
          childData
        );

        if(storyDocRef) {
          t.update(storyDocRef, {
              numOfComments: increment(1),
            }
          );
        }

        t.update(parentDoc, {
          numOfChildren: increment(1)
        });

        // Go into the parent comment within user-followers "recentComments" array and add childComment
        // if (docData) {
        //   for(let i = 0, len = docData.recentComments.length; i < len; i++){
        //     const cur = docData.recentComments[i];
        //     if(cur.id === parentID || cur.commentId === parentID){
        //       cur.childComments.push(childData);
        //       if(typeof cur.numOfChildren === 'number'){
        //         cur.numOfChildren += 1;
        //       } else {
        //         cur.numOfChildren = 0;
        //       }
        //       break;
        //     }
        //   }
        // }
        // Update whole user-followers document with mutated recentComments array
        // t.update(userFollowersRef, docData);
        // Maybe we could pull the id of our new child comment and log this for testing...
        console.log("Transaction success!");
      });
    } catch (e) {
      console.log("Transaction failed: ", e);
    }
  }
