import "./NoResult.css";

const NoResult = ({message="No result"}) => {
  return(
    <div className="under-construction">
      <p style={{ textAlign: 'center' }}>
        {message}
      </p>
    </div>
  );
};

export default NoResult;
