import { ChangeEvent, KeyboardEvent, useState } from "react";
import CustomizedInterest from "../customized-interest/CustomizedInterest";
import "./InterestSelector.css";

import { interestsCollectionRef } from "../../../firebase/instance";
import useOnce from "../../../hooks/useOnce";
import { getDocs, query } from "firebase/firestore";
import { Interest } from "../../../interfaces/interests.interface";

interface Param {
  onChange: Function;
  selectedInterests: any[];
  width?: string;
}

interface CustomInterests {
  interests: string[];
  inputValue: string;
}

const InterestSelector = ({ onChange, selectedInterests=[], width }: Param) => {
  const [interestsFromDB, setInterestsFromDB] = useState<any[]>([]);

  const [customInterests, setCustomInterests] = useState<CustomInterests>({
    interests: [],
    inputValue: "",
  });

  const handleDelete = (interestToDelete: string) => {
    const interests = customInterests.interests?.filter(
      (interest) => interest !== interestToDelete
    );
    setCustomInterests((prev) => ({...prev, interests }));
    onChange(
      selectedInterests.filter(cur => !interests.includes(cur))
    );
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomInterests((prev) => ({ ...prev, inputValue: e.target.value }));
  };

  const handleInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    // Deprechttps://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
    if (e.keyCode === 13 || e.which === 13 || e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      let newInterest = (e.target as HTMLInputElement).value.trim();
      const { interests } = customInterests;
      if (newInterest && interests.indexOf(newInterest) === -1 ) {
        const newCustumInterests = [ ...interests, newInterest ];
        setCustomInterests({
          interests: newCustumInterests,
          inputValue: "",
        });
        onChange([...selectedInterests, newInterest]);
      } else {
        setCustomInterests((prev) => ({
          ...prev,
          inputValue: "",
        }));
      }
    }
  };

  const onSelect = (name: string) => {
    const interestIdx = selectedInterests.indexOf(name);
    let newSelectedInterests;
    if (interestIdx !== -1) {
      newSelectedInterests = 
        selectedInterests.filter((next) => name !== next);
    } else {
      newSelectedInterests = [...selectedInterests, name];
    }
    onChange(newSelectedInterests);
  };

  useOnce(() => {
    async function fetchData() {
      getDocs(query(interestsCollectionRef))
        .then((querySnapShot) => {
          const interests: Interest[] = [];
          querySnapShot.forEach((doc) => {
            const interest = { name: doc.data().name };
            interests.push(interest);
          });
          const interestNames = interests.map((interest) => interest.name);
          setInterestsFromDB(interestNames);
        });
    }
    fetchData();
  });

  const buttonWidth = width ?? "";

  const allInterestsJsx = () => (
    interestsFromDB.map(interest => {
      const isSelected = selectedInterests.indexOf(interest) > -1;
      const className = isSelected ? "active" : "";
      return (
        <button
          key={interest}
          className={`interest-button ${className} ${buttonWidth}`}
          onClick={() => {
            onSelect(interest);
          }}
        >
          {interest}
        </button>
      );
    })
  )

  const customInterestsJsx = () => (
    <div className="pills-list">
      {  
        customInterests.interests.length ? 
          customInterests.interests.map((interest, idx) => (
              <CustomizedInterest
                key={idx}
                interest={interest}
                onDelete={handleDelete}
              />
            )
          )
        : null
        }
      <input
        className="pills-input"
        onKeyPress={handleInputKeyPress}
        onChange={handleOnChange}
        value={customInterests.inputValue}
      />
    </div>
  )

  return (
    <>
      <ul className={`interest-container ${buttonWidth}`}>
        {allInterestsJsx()}
      </ul>

      <p className="add-key-words-text">
        ADD KEY WORDS TO FURTHER PERSONALIZED NEWS CONTENT YOU SEE.
      </p>

      <div className="customized-interest-container">
        {customInterestsJsx()}
      </div>
    </>
  );
};

export default InterestSelector;
