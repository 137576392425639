import EditLayout from "../../shared/text-input/EditLayout";
import MediaSelector from "../../shared/media-selector/MediaSelector";

interface Param {
  label: string;
  selectedMedias: string[];
  onChange: Function;
  height?: string | undefined;
}

const EditMedia = ({ label, selectedMedias, onChange, height }: Param) => {
  return (
    <EditLayout
      label={label}
      content={
        <MediaSelector
          medias={selectedMedias}
          onChange={onChange}
          height={height}
        />
      }
    />
  );
};

export default EditMedia;
