import { firestore } from './firebase-instance';
import { collection, CollectionReference, DocumentData } from 'firebase/firestore';

export const commentsCollectionRef = collection(firestore, 'comments');
export const politiciansCollectionRef = collection(firestore, 'politicians');
export const userFollowersCollectionRef = collection(firestore, 'user-followers');
export const usersCollectionRef = collection(firestore, 'users');
export const propositionCollectionRef = collection(firestore, 'propositions');
export const storiesCollectionRef = collection(firestore, 'stories');
export const sourcesCollectionRef = collection(firestore, 'sources');
export const interestsCollectionRef = collection(firestore, 'interests');
export const perigonSources = collection(firestore, 'perigon-sources');

export interface CollectionRef {
  [key: string]: CollectionReference<DocumentData>
}

export const collectionRefObj: CollectionRef = {
  'comment': commentsCollectionRef,
  'politician': politiciansCollectionRef,
  'proposition': propositionCollectionRef,
  'user': usersCollectionRef,
  'story': storiesCollectionRef,
  'sources': sourcesCollectionRef,
  'interests': interestsCollectionRef
}