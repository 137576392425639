import { MouseEventHandler } from "react";

export interface PrimaryBtnParam {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const PrimaryButton = ({ label, onClick, disabled }: PrimaryBtnParam) => {
  const isDisabled = disabled ?? false;
  return (
    <button className="primary-button" onClick={onClick} disabled={isDisabled}>
      {label}
    </button>
  );
}

export default PrimaryButton;