import { useEffect, useState } from "react";
import EditTextInput from "../../shared/text-input/EditTextInput";
import EditTextAreaInput from "../../shared/text-input/EditTextAreaInput";
import EditMedia from "./EditMedia";
import EditInterest from "./EditInterest";
import EditEmail from "./EditEmail";
import { Link } from "react-router-dom";
import * as routes from "../../../constants/routes";
import SignOutButton from "../../authentication/sign-out/SignOut";
import { updateUserAccountAsync, updateProfilePicture } from "../../../redux/thunks/account/account.thunks";
import PreviousAndButton from "../../shared/previous-button/PreviousAndButton";
import DeleteAccount from '../DeleteAccount';
import "./EditAccount.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/redux-hooks";
import { AccountState } from "../../../redux/slices/account/account.slice";

const locationLabel = "Location";
const locationDescription =
  " - Your location will determin what news and political content you see";

const EditAccount = () => {
    const dispatch = useAppDispatch();
    const userAccount = useAppSelector(store => store.account);

    const [accountState, setAccountState] = useState<AccountState>({
      ...userAccount
    });

    useEffect(() => {
      setAccountState(prev => ({...prev, ...userAccount}));
    }, [userAccount]);

    const [modalIsOpen, openModal] = useState(false);

    const [isSaved, setIsSaved] = useState(false);

    const openDeleteModal = () => {
      openModal(true);
    }

    const closeDeleteModal = () => {
      openModal(false);
    }

    const handleSaveUpdate = () => {
      setIsSaved(true);
      dispatch(updateUserAccountAsync({ newAccount: accountState }));
    };

    // onChange functions
    const handlePropChange = (propName: string) => {
      return (txt: string) => {
        setAccountState(prev => ({ ...prev, [propName]: txt }));
      }
    }

    const handleEditAboutChange = (about: string) => {
      if (150 - about.length >= 0) {
        setAccountState({ ...accountState, about });
      }
    };

    const buttonLabel =
      userAccount.isUpdating === false && isSaved ? "SAVED" : "SAVE";

    return (
      <div className="edit-account-container" id="edit-account-container">
        <PreviousAndButton
          label={buttonLabel}
          onClickButton={handleSaveUpdate}
        />
        {modalIsOpen ? <DeleteAccount handleClose={closeDeleteModal}/> : null}
        <div className="profile-img-section">
          <div className="profile-img-container">
            <img
            src={(userAccount.photoURL || "/assets/default-img.jpg")}
              className="profile-img"
              alt="profile-avatar"
            />
            <label htmlFor="file-input">
              <img
                src="/img/addProfilePic.svg"
                className="add-profile-pic"
                alt="add-profile-pic"
                onClick={() => {
                  console.log("edit profile pic");
                }}
              />
            </label>
            <input
              id="file-input"
              type="file"
              className="upload-pic-input"
              onChange={(e: any) => {
                dispatch(updateProfilePicture({ imageFile: e.target.files[0], userAccount }));
              }}
            />
          </div>
        </div>

        <div className="edit-profile-container">
          <EditTextInput
            label="Name"
            placeholder={accountState.fullname}
            onChange={handlePropChange('fullname')}
          />

          <EditTextInput
            label="User Name"
            placeholder={accountState.username}
            onChange={handlePropChange('username')}
            prefix={<div className="prefix-username">@</div>}
          />

          <EditTextInput
            label="Title"
            placeholder={accountState.title}
            onChange={handlePropChange('title')}
          />

          <EditTextAreaInput
            label="About"
            placeholder={accountState.about}
            onChange={handleEditAboutChange}
            value={accountState.about}
            charCount={
              <div className="char-count">
                {150 - ((accountState.about && accountState.about.length) || 0)}
              </div>
            }
          />

          <EditTextInput
            label={locationLabel}
            description={locationDescription}
            placeholder={accountState.zipcode as string}
            onChange={handlePropChange('zipcode')}
          />

          <EditMedia
            label={`Trusted News Agency(${accountState.medias.length})`}
            onChange={handlePropChange('medias')}
            selectedMedias={accountState.medias}
            height="short"
          />

          <EditInterest
            label={`Topic of Interests(${accountState.interests.length})`}
            onChange={handlePropChange('interests')}
            selectedInterests={accountState.interests}
            width="short"
          />

          <EditEmail
            label="Email"
            placeholder={accountState.email}
            onChange={handlePropChange('email')}
          />

          <div className="edit-account-footer">
            <Link to={routes.PASSWORD_FORGET} className="change-password-link">
              Change Password
            </Link>

            <SignOutButton fontColor="purple" />

            <div>
              <button
                className="delete-account-button"
                onClick={openDeleteModal}
              >
                Delete Account
              </button>
            </div>
          </div>

          <div className="mobilize-logo-container-edit-account">
            <img
              className="mobilize-logo-edit-account"
              alt="Mobilize Logo"
              src="/img/newMobilize.svg"
            />
          </div>
        </div>
      </div>
    );
  }

export default EditAccount;
