import { auth, firestore } from "./firebase-instance";
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  onAuthStateChanged,
} from "firebase/auth";

import { userFollowersCollectionRef, usersCollectionRef } from "./firestore-collection-refs";
import { doc, DocumentReference, WriteBatch, writeBatch } from "firebase/firestore";
import { UserFollowersDoc } from "../../interfaces/comment-entities.interfaces";
import { AccountState } from "../../redux/slices/account/account.slice";
import { SignUpState } from "../../redux/slices/sign-up/sign-up.slice";

export const doCreateUserWithEmailAndPassword = async (email: string, password: string) =>
  createUserWithEmailAndPassword(auth, email, password);

export const doSignInUserWithEmailAndPassword = async (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password);

export const doSignOut = (): Promise<void> => signOut(auth);

export const doPasswordReset = async (email: string) =>
  sendPasswordResetEmail(auth, email);

export const doPasswordUpdate = async (password: string) =>
  auth.currentUser ? updatePassword(auth.currentUser, password) : null;

export const verifyPasswordResetCodeWrap = (actionCode: string) =>
  verifyPasswordResetCode(auth, actionCode);

export const confirmPasswordResetWrap = async (actionCode: string, newPassword: string) =>
  confirmPasswordReset(auth, actionCode, newPassword);

export const doFetchSignInMethodsForEmail = async (email: string) =>
  fetchSignInMethodsForEmail(auth, email);

interface CBandParam {
  cb: Function;
  param: any;
  userParam?: boolean;
}

interface MonitorParam {
  loggedIn: CBandParam;
  loggedOut: CBandParam;
  username: string | null;
}

export const monitorAuthState = 
  async ({ loggedIn, loggedOut, username }: MonitorParam) => {
    // https://firebase.google.com/docs/auth/web/manage-users
    const { cb: loggedInCB, param: loggedInParam, userParam = false } = loggedIn;
    const { cb: loggedOutCB, param: loggedOutParam } = loggedOut;
    const [location, location2] = loggedOutParam;
    const curLocation = window.location.pathname;
    return onAuthStateChanged(auth, user => {
      // console.log({ curLocation, user, location, location2, userParam, loggedInParam });
      if (user) {
        // Need to check if user is within redux...
        if (curLocation === location || curLocation === location2 || !username) { 
          userParam ? loggedInCB(user, loggedInParam) : loggedInCB(loggedInParam);
        }
      } else {
        window.location.pathname === location2 ? loggedOutCB(location2) : loggedOutCB(location);
      }
    })
  }

export const signUpBatchReq = async (userId: string, userSignUp: SignUpState) => {
  try {
    const { 
      fullname, 
      username, 
      zipcode, 
      medias, 
      interests 
    } = userSignUp.user;

    const {
      email
    } = userSignUp.authState;

    const userFollowersDocRef: DocumentReference = 
      doc(userFollowersCollectionRef, userId);

    const usersCollectionDocRef: DocumentReference = 
      doc(usersCollectionRef, userId);

    const batch: WriteBatch = writeBatch(firestore);

    const newUserFollower: UserFollowersDoc = {
      lastComment: null,
      followers: [],
      recentComments: []
    }

    const newUser: AccountState = {
      fullname,
      username,
      zipcode,
      medias,
      interests,
      email,
      userId,
      title: null,
      about: null,
      following: [],
      politiciansFollowing: [],
      photoURL: null,
      politiciansSupporting: [],
      politiciansOpposing: [],
      propositionsSupporting: [],
      propositionsOpposing: [],
      storiesSupporting: [],
      storiesOpposing: [],
      recentActivity: []
    }

    batch.set(userFollowersDocRef, newUserFollower);
    
    batch.set(usersCollectionDocRef, newUser);

    await batch.commit();
    console.log("batch successful");
  } catch(e){
    console.error("Sing up batch request failed: " + e);
  }
}
