import useOnce from "../../hooks/useOnce";
import Story from "../story/Story";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/redux-hooks";
import { saveHeadlinesWithDbData } from "../../redux/thunks/news-stories/stories.thunks-action-creators";

const Headlines = () => {
  const headlines = useAppSelector(store => store.stories.headlines);
  const dispatch = useAppDispatch()

  useOnce(() => {
    dispatch(saveHeadlinesWithDbData());
  });

  return (
    <div className="feed-wrapper">
      {headlines?.map((story, idx) => {
        return (
          <Story
            key={idx}
            data={story}
            idx={idx}
            showSourceIcon={true}
            showCommentFeature={false}
          />
        );
      })}
    </div>
  );
};

export default Headlines;
