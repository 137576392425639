import { useState } from "react";
import useOnce from "../../../hooks/useOnce";
import "./MediaSelector.css";
import { perigonSources } from "../../../firebase/instance";
import { getDocs, query, QuerySnapshot } from "firebase/firestore";

interface Param {
  onChange: Function;
  medias: string[];
  height?: string
}

const MediaSelector = ({ onChange, medias, height }: Param) => {
  // const [signUp, setSignUp] = useState<SignUpState>({
  //   ...signUpState
  // });

  const [apiSources, setApiSources] = useState<any[]>([]);

  const onSelect = (id: string) => {
    const mediaIdx = medias.indexOf(id);
    if (mediaIdx !== -1) {
      const newMedias = [...medias];
      newMedias.splice(mediaIdx, 1);
      onChange(newMedias);
    } else {
      onChange([ ...medias, id ]);
    }
  };

  useOnce(() => {
    async function fetchData() {
      getDocs(query(perigonSources))
        .then((querySnapshot: QuerySnapshot) => {

          const sources: any[] = [];

          querySnapshot.forEach((doc) => {
            const { name, logoFavIcon, logoSquare, domain } = doc.data();
            const source = {
              id: doc.id,
              name: name,
              icon: logoSquare?.url || logoFavIcon?.url,
              perigonApiId: domain
            };

            sources.push(source);
          });
          setApiSources(sources);
        })
    }

    fetchData();
  });

  const containerHeight = height ?? "";

  return (
    <ul className={`media-icon-container ${containerHeight}`}>
      {apiSources.map((source) => {
        const isSelected = medias.indexOf(source.perigonApiId) > -1;

        const className = isSelected ? "active" : "";

        return (
          <div key={source.id} className="media-container">
            <div className={`img-container ${className}`}>
              <img
                src={`${source.icon}`}
                alt={source.name}
                onClick={() => {
                  onSelect(source.perigonApiId);
                }}
                className="media-icon"
              />
            </div>
            <div className="stories-source">{source.name}</div>
          </div>
        );
      })}
    </ul>
  );
};

export default MediaSelector;
