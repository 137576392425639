import { useState } from "react";
import "./FooterTabBar.css";

interface Param {
  leftLabel: string;
  rightLabel: string;
  onStateChange: Function;
}

const FooterTabBar = ({ leftLabel, rightLabel, onStateChange }: Param) => {
  const [state, setState] = useState({
    currentTab: leftLabel
  });

  const onTabChange = (label: string) => {
    setState({ ...state, currentTab: label });
    onStateChange(label);
  };

  return (
    <div className="footerTabBar-wrap">
      <button
        className={`footerBtnBasic ${
          state.currentTab === leftLabel ? "btnClicked" : null
        }`}
        onClick={() => onTabChange(leftLabel)}
      >
        {leftLabel}
      </button>
      <button
        className={`footerBtnBasic ${
          state.currentTab === rightLabel ? "btnClicked" : null
        }`}
        onClick={() => onTabChange(rightLabel)}
      >
        {rightLabel}
      </button>
    </div>
  );
};

export default FooterTabBar;
