import { UnknownObject } from "../../../interfaces/global";
import "./SearchInput.css";


const SearchInput = ({ placeholder, onChange, onKeyPress, value }: UnknownObject) => {
  return (
    <input
      className="search-bar"
      placeholder={placeholder}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
    />
  );
};

export default SearchInput;