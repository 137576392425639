// onboarding
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LANDING = "/";
export const HOME = "/home";
export const ENTER_VERI_CODE = "/enter-veri-code";

// account
export const ACCOUNT = "/account";

// tabs
export const FEED = "/feed";
export const HEADLINES = "/headlines";
export const PEOPLE = "/people";
export const COMMENTS = "/comments/:commentType/:entityId";

// passwords
export const PASSWORD_FORGET = "/pw-forget";
export const PASSWORD_RESET = "/reset-password";

// Ballot
export const BALLOT = "/ballot";
export const PROPOSITION_PAGE = "/local/propositions";
export const POLITICIAN_PROFILE = "/politician-profile/:officialName";
export const BILL_PROFILE = "/bill-profile/:billId";
export const PROPOSITION_PROFILE = "/proposition-profile/:propositionId";

// New
export const FOLLOWERS = "/followers";
export const FOLLOWING = "/following";
