import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UnknownObject } from "../../../interfaces/global";
import { NewsStoriesRes } from "../../interfaces/news-stories.interface";
import { updateFeedData } from "../../thunks/news-stories/stories.helper-functions";

interface StoriesState {
  isLoading: boolean;
  newsfeed: any[];
  comments: UnknownObject;
  posts: any[];
  headlines: any[];
  politiciansNewsFeed: any[];
  pageNum: number;
}

const initialState: StoriesState = {
  isLoading: false,
  newsfeed: [],
  comments: {},
  posts: [],
  headlines: [],
  politiciansNewsFeed: [],
  pageNum: 1,
};

export const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    fetchFeed: (state, action: PayloadAction<StoriesState>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    },
    addToFeed: (state, action: PayloadAction<NewsStoriesRes>) => {
      const { articles } = action.payload;
      return {
        ...state,
        newsfeed: [...state.newsfeed, ...articles]
      }
    },
    updateFeed: (state, action: PayloadAction<any[]>) => {
      state.newsfeed = updateFeedData({ 
        feed: state.newsfeed, feedItemsChanged: action.payload 
      });
    },
    savePoliticianFeedWithDBData: (state, action: PayloadAction<any[]>) => {
      state.politiciansNewsFeed = updateFeedData({
        feed: state.politiciansNewsFeed, feedItemsChanged: action.payload 
      });
    },
    reset_page_counter: (state) => {
      state.pageNum = 0;
    },
    increment_page_counter: (state) => {
      state.pageNum += 1;
    },
  },
})

export const { 
  fetchFeed, 
  addToFeed, 
  updateFeed, 
  savePoliticianFeedWithDBData,
  reset_page_counter,
  increment_page_counter,
} = storiesSlice.actions;

export default storiesSlice.reducer;
