import { useMatches, useOutletContext } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks/redux-hooks";
import PoliticianFeed from "../politician-feed/PoliticianFeed";

interface Param {
  officialName?: string;
}

const FeedForPolitician = ({
  officialName
}: Param) => {

  const { medias } = useAppSelector(state => state.account);
  const { currentOfficial } = useOutletContext<{ currentOfficial: any }>();
  let name = useMatches()[0].params.officialName;
  name = officialName || name;

  return <PoliticianFeed
    fetchFeedParam={{ 
      politicianName: name,
      medias, 
      andQuery: [ currentOfficial?.entityData?.placeName || "" ]
    }}
    style={{ paddingTop: 22 }}
  />
};

export default FeedForPolitician;
