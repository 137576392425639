import { useState } from 'react';
import { UnknownObject } from '../../../interfaces/global';
import { useAppSelector } from '../../../redux/hooks/redux-hooks';
import { handleAddChildComment } from '../../../firebase/comments/shared/add-child.comment';
import { handleAddGrandchildComment } from '../../../firebase/comments/shared/add-grandchild.comment';
import { Comment } from '../../../interfaces/comment-entities.interfaces';

interface Param {
  data: any;
  replyBtnIsOpen: boolean;
  replyBoxIsOpen: boolean;
  toggleReply: Function;
  parentUpdateFunctions?: UnknownObject;
}

const ReplyToCommentForm = ({ 
  data,
  replyBtnIsOpen, 
  replyBoxIsOpen, 
  toggleReply, 
}: Param) => {

  const account = useAppSelector(store => store.account);

  const { 
    commentData, 
    isChild,
    isGrandchild, 
    parentData, 
    grandparentData,
    entityInfo
  } = data;

  const { userData } = commentData;

  const [reply, updateReply] = 
    useState(isChild || isGrandchild ? `@${userData.username}` : '');

  const handleChange = (e: any) => {
    updateReply(e.target.value);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Should find a way to pass in comment data at the top level of system?
    // This needs to be more dynamic (if adding propositionID, or politican name to new comment)
    const { photoURL, userId, username } = account;

    const childData: Comment = {
      id: 'tbd',
      content: reply,
      numOfChildren: 0,
      timestampUTC: new Date().getTime(),
      entityInfo,
      userData: { photoURL, userId, username: username as string }
    }

    if(!isChild && !isGrandchild) {
      console.log(" adding child comment ");
      handleAddChildComment({ childData, parentData: commentData, entityInfo })
      .catch(error => { console.log(error); });
    } else if(isChild) {
      handleAddGrandchildComment({ 
        grandchildData: childData, 
        parentData: commentData, 
        grandparentData: parentData, 
        entityInfo
      })
      .catch(error => { console.log("HandleAddGrandchildComment: " + error) });
    } else if (isGrandchild){
      handleAddGrandchildComment({ 
        grandchildData: childData, 
        parentData, 
        grandparentData, 
        entityInfo 
      })
      .catch(error => { console.log(error) });
    }
    toggleReply();
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: `${replyBtnIsOpen && replyBoxIsOpen ? 'inline-block' : 'none'}` }}
    >
      <textarea
        value={reply}
        onChange={handleChange}
        placeholder="Add a comment..."
        ref={ref => ref && ref.focus()}
        cols={100}
        rows={4}
        style={{resize: 'none'}}
      >
      </textarea>
      <input type="submit" value="Submit" />
    </form>
  )
}

export default ReplyToCommentForm;