import { functions } from "../instance";
import { httpsCallable } from "firebase/functions";

export const fetchPropositionTickerDataById = async (propId: string) => {
  /*
    TO DO: REMOVE ARRAY AND GENERATE SUPPORTING / OPPOSING NUMBER IN CLOUD FUNCTION
  */
  const fetchTickerData = httpsCallable(functions, 'fetchPropositionTickerDataById');
  const ticker = await fetchTickerData({ propositionId: propId });
  return ticker.data;
}
