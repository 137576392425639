import { doc, getDoc, writeBatch } from 'firebase/firestore';
import { arrayUnion, arrayRemove } from 'firebase/firestore';
import { firestore } from '../instance';
import { usersCollectionRef, userFollowersCollectionRef } from '../instance';

export const toggleFollowReq = async (userId: string, targetUserId: string) => {
  const userDocRef = doc(usersCollectionRef, userId);
  // Here we need to add / remove target users followers list
  const targetUserFollowersDocRef = doc(userFollowersCollectionRef, targetUserId);
  
  try {
    const userDoc = await getDoc(userDocRef);
    const isFollowingTarget = userDoc.exists() && userDoc.data().following.includes(targetUserId);

    const batch = writeBatch(firestore); 
    // Add userId to targetUsers userCollection array
    batch.update(targetUserFollowersDocRef, {
      followers: isFollowingTarget ? arrayRemove(userId) : arrayUnion(userId)
    });
    // Add targetUser to users following array
    batch.update(userDocRef, {
      following: isFollowingTarget ? arrayRemove(targetUserId) : arrayUnion(targetUserId)
    });
    await batch.commit();
    return !isFollowingTarget;
  } catch (e) {
    console.error("Error with follow / unfollow request: " + e);
  }
}