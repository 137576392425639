import { MouseEventHandler } from 'react';
import './CommentAndSupport.css';
import '../../story/hairline.css'

interface Param {
  userSupportsEntity: boolean | null;
  openCommentWindow: MouseEventHandler<HTMLButtonElement>;
  handleSupport: MouseEventHandler<HTMLButtonElement>;
  handleOppose: MouseEventHandler<HTMLButtonElement>; 
  theme?: string;
  tickerData?: any;
  entityData?: any;
}

const CommentAndSupport = 
  ({ userSupportsEntity, openCommentWindow, handleSupport, handleOppose, theme, tickerData, entityData }: Param) => {
    const themes: any = {
      'light': 'light-theme',
      'transparent': 'transparent'
    }

    const btnTheme = theme && themes[theme] === 'transparent' ? 'transparent' : '';

    return (
      <div className="support-container" id={`${theme ? themes[theme]: ''}`}>
        <button id={btnTheme} onClick={openCommentWindow}>
          <img className="comment-icon" alt="add comment" src="/img/icons/addComment.png" />
          <span>Comment</span>
        </button>
        <button 
          onClick={handleSupport} 
          id={btnTheme} 
          className={userSupportsEntity ? 'button-support' : ''}
        >
          <img 
            className="support-icon"
            alt="thumbs-up" 
            src={
              userSupportsEntity ? 
              "/img/icons/thumbsUp2-selected.png" :
              "/img/icons/thumbsUp2.png"
            }
          />
          <span style={{ marginRight: '.5em' }}>{`${tickerData?.supporting || 0}`}</span>
          <span>{userSupportsEntity ? "Supporting" : "Support"}</span>
        </button>
        <button 
          onClick={handleOppose} 
          id={btnTheme} 
          className={userSupportsEntity === false ? 'button-support' : ''}
        >
          <img 
            className="support-icon" 
            alt="thumbs-down" 
            src={
              userSupportsEntity === false ? 
              "/img/icons/thumbsDown2-selected.png" : 
              "/img/icons/thumbsDown2.png" 
            }
          />
          <span style={{ marginRight: '.5em' }}>{`${tickerData?.opposing || 0}`}</span>
          <span>{userSupportsEntity === false ? "Opposing" : "Oppose"}</span>
        </button>
      </div>
    )
  }

export default CommentAndSupport;
