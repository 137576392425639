import { Link } from 'react-router-dom';
import { MouseEventHandler, useState } from 'react';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';
import { PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import { auth } from '../../../../firebase/instance';
import './EnterVeriCode.css';
import * as routes from '../../../../constants/routes';
import TextInput from '../../../shared/text-input/TextInput';
import { UnknownObject } from '../../../../interfaces/global';
import { firestore } from '../../../../firebase/instance';
import OnboardingCardLayout from '../card-layout/OnboardingCardLayout';
import { useAppSelector } from '../../../../redux/hooks/redux-hooks';
import { RootState } from '../../../../redux/store';

interface Param {
  retryCount: number;
  retryPhoneAuth: MouseEventHandler<HTMLButtonElement>;
}

const ErrorMessage = ({ retryCount, retryPhoneAuth }: Param) => {
  return (
    <div>
      {retryCount < 1 ? (
        <button onClick={retryPhoneAuth} className='restart-signup-link'>
          Click here to retry
        </button>
      ) : (
        <Link to={routes.SIGN_IN} className='restart-signup-link'>
          <span className='click-here'>Click here</span> to start again
        </Link>
      )}
    </div>
  );
};

interface VeriParam extends Param {
  onNext: Function;
}

interface State {
  verificationCode: string;
  updateSuccess: boolean;
  error: UnknownObject;
}

const EnterVeriCode = ({
  onNext,
  retryCount,
  retryPhoneAuth,
}: VeriParam) => {
  const signUp = useAppSelector((state: RootState) => state.signUp);

  const [state, setState] = useState<State>({
    verificationCode: '',
    updateSuccess: false,
    error: {},
  });

  const handleOnClick = () => {
    const { verificationCode } = state;
    const { userAuthData, authState } = signUp;

    const phoneAuthCredential = PhoneAuthProvider.credential(
      authState.verificationId,
      verificationCode
    );
    
    // Verify phone number...
    signInWithCredential(auth, phoneAuthCredential)
      .then(() => {
        setState({ ...state, updateSuccess: true });
        onNext();
      })
      .catch((err) => {
        console.error('Error with sign in: ', err);
        if (retryCount === 0) {
          setState({
            ...state,
            error: { veriCodeError: 'Verification code invalid*' },
          });
        } else if (retryCount === 1) {
          const currentUserUid = userAuthData.uid;
          // delete user in collection
          const docRef = doc(firestore, 'users', currentUserUid);

          getDoc(docRef)
            .then(docSnap => {
              deleteDoc(docSnap.ref)
                .then(() => {
                  console.log('deleted user');
                  setState({
                    ...state,
                    error: { veriCodeError: 'Verification code invalid*' },
                  })
                })
                .catch((err) => {
                  console.error('error deleting user', err);
                });
            })
        }
      });
  };

  const headerText = 'ACCESS CODE HAS BEEN SENT TO YOUR PHONE';
  const buttonLabel = 'GET STARTED';
  return (
    <OnboardingCardLayout
      headerText={headerText}
      content={
        <div>
          <img
            className='onboarding-svg'
            alt='authentication'
            src='/img/onboarding/authentication.svg'
          />
          <TextInput
            placeholder='ACCESS CODE'
            inputMode='numeric'
            pattern='[0-9]*'
            autoComplete='one-time-code'
            onTextInputChange={(event) =>
              setState({
                ...state,
                verificationCode: event.target.value,
              })
            }
            errorMessage={state.error.veriCodeError}
          />

          {state.error?.veriCodeError ? (
            <ErrorMessage
              retryCount={retryCount}
              retryPhoneAuth={retryPhoneAuth}
            />
          ) : (
            <p className='under-input-text'>ENTER CODE.</p>
          )}
        </div>
      }
      buttonParams={
       { label: buttonLabel, onClick: handleOnClick }
      }
    />
  );
};

export default EnterVeriCode;
