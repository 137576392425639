import EditLayout from "./EditLayout";
import "./EditTextAreaInput.css";

interface Param {
  label: string;
  placeholder: string | null | undefined;
  onChange: Function;
  charCount: any;
  value: any;
}

const EditTextAreaInput = ({ label, placeholder, onChange, charCount, value }: Param) => {
  return (
    <EditLayout
      label={label}
      content={
        <>
          <textarea
            placeholder={placeholder || ''}
            rows={5}
            className="edit-text-input"
            onChange={(e) => {
              onChange(e.target.value);
            }}
            value={value||""}
          />
          {charCount}
        </>
      }
    />
  );
};

export default EditTextAreaInput;
