import { useState } from "react";
import "./BillProfileSection.css";

interface Param {
  label: string;
  content: string;
  items?: any[];
  showMore?: any;
}

const BillProfileSection = ({ label, content, items, showMore }: Param) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const truncatedString = content?.toString().slice(0, 200);
  const fullString = content ?? "";
  
  return (
    <div className="bio-section-container">
      <div className="bio-content-label">{label}</div>
      <div className="bio-content-content">
        {showFullContent ? fullString : truncatedString}
        {showMore ? (
          <button
            onClick={() => {
              setShowFullContent(!showFullContent);
            }}
          >
            ...More
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default BillProfileSection;
