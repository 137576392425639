import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks/redux-hooks";
import NavBar from "../navbar/NavBar";

const SharedLayoutWithNav = () => {
  const userAccount = useAppSelector(store => store.account);
  return (
      <>
        <NavBar userAccount={userAccount} />
        <Outlet />
      </>
    )
  }

export default SharedLayoutWithNav;