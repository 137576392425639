import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { Comment } from "../../../interfaces/comment-entities.interfaces";
import { commentsCollectionRef } from "../../instance";

export const childCommentsQuery = (commentId: string) => {
  return query(collection(doc(commentsCollectionRef, commentId), 'childComments'));
}

export const getCommentsQueryByEntityId = (id: number | string) => {
  return query(commentsCollectionRef, where('entityInfo.id', '==', id));
}

export const fetchChildComments = async (commentId: string) => {
  try {
    const childComments = 
      collection(doc(commentsCollectionRef, commentId), 'childComments');
    const docs: any[] = [];
    (await getDocs(childComments)).forEach(doc => {
      docs.push(doc.data());
    });
    return docs as Comment[];
  } catch (e) {
    console.log(e);
    return [];
  }
}

export const fetchGrandchildren = 
  async (childComment: Comment, parentComment: Comment) => {
    try {
      const { id: childId } = childComment;
      const { id: parentId } = parentComment;

      const childComments = 
        collection(doc(commentsCollectionRef, parentId), 'childComments');
      const grandchildren = 
        collection(doc(childComments, childId), 'grandchildComments');
    
      const docs: any[] = [];
      (await getDocs(grandchildren)).forEach(doc => {
        docs.push(doc.data());
      });

      return docs;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

export const grandchildCommentsQuery = (parentId: string, childId: string) => {
    const childCommentsRef = 
      collection(doc(commentsCollectionRef, parentId), 'childComments');
    return query(collection(doc(childCommentsRef, childId), 'grandchildComments'));
}

export const fetchCommentById = async (id: string) => {
  try {
    const docRef = doc(commentsCollectionRef, id);
    const docData = (await getDoc(docRef)).data();
    return docData;
  } catch (e) {
    console.error("Couldn't retrieve comment data: " + e);
    return null;
  }
}