import { MouseEventHandler } from "react";
import ReactDOM from "react-dom";
import "./option.modal.css";

interface Param {
  handleCloseModal: MouseEventHandler<HTMLElement>;
  handleShowCommentWindow: MouseEventHandler<HTMLButtonElement>;
}

const OptionModal = ({
  handleCloseModal,
  handleShowCommentWindow,
}: Param) => {
  return ReactDOM.createPortal(
    <div className="option-modal-root">
      <div className="option-modal-background" onClick={handleCloseModal}></div>
      <div className="option-modal-container">
        <div className="button-container">
          <button onClick={handleShowCommentWindow}>Comment & Share</button>
          <button>Bookmark for later</button>
        </div>
        <div className="cancel-button-container">
          <button className="cancel-button" onClick={handleCloseModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root") as Element
  );
};

export default OptionModal;
