// Going to try to make this into a general function
import { arrayRemove, arrayUnion, doc, DocumentReference, increment, writeBatch, WriteBatch } from "firebase/firestore";
import { Comment } from "../../../interfaces/comment-entities.interfaces";
import { UnknownObject } from "../../../interfaces/global";
import { collectionRefObj, commentsCollectionRef, firestore } from "../../instance";
import { addCommentToUserFollowers, UserFollowersParam } from "../user-followers/user-followers.comments";

export interface AddCommentParam {
  data: Comment,
  entityData: UnknownObject;
  uniqueData?: UnknownObject | null;
  batch?: WriteBatch | undefined;
  ref?: DocumentReference;
  entityType?: string;
  chainBatch?: boolean | undefined;
}

export const addCommentSequence = async (param: AddCommentParam) => {
  try {
    const formattedComment = await addComment(param);
    // When adding comment we could collect the reference
    // and pass that to our userFollowers comment data

    const batchData = 
      await updateEntityUserSupport(formattedComment as AddCommentParam);

    const { userData, id, entityInfo } = batchData.data;
    const { batch, ref } = batchData;

    const userFollowersData: UserFollowersParam = {
      batch,
      comment: {
        id,
        userData,
        entityInfo,
        ref
      }
    }

    await addCommentToUserFollowers(userFollowersData);

  } catch (e) {
    console.error("Error in addStoryCommentSequence: ", e);
  }
  return true;
}

export const updateEntityUserSupport = 
  async ({ data, batch, ref, entityData, chainBatch = true, uniqueData = null }: AddCommentParam) => {
    const { entityInfo, userData, doesSupport } = data;
    const { id, type } = entityInfo;
    const { userId } = userData;

    // get collectionRef based on type
    const entityCollectionDocRef = doc(collectionRefObj[type], id);

    // Pull 
    const userSupportStatus = 
      doesSupport !== null ? 
      {
        [doesSupport ? "supporting" : "opposing"]: arrayUnion(userId),
        [doesSupport ? "opposing" : "supporting"]: arrayRemove(userId)
      } : 
      {
        supporting: arrayRemove(userId),
        opposing: arrayRemove(userId)
      }

    if (!batch) { 
      batch = writeBatch(firestore);
    }
    // TO DO - take this an add it to saveStory
    batch.set(entityCollectionDocRef, {
        appData: { 
          id,
          numOfComments: increment(1),
          ...userSupportStatus,
          uniqueData
        },
        entityData,
      }, { merge: true }
    );

    if (!chainBatch) batch.commit();
    return { data, batch, ref };
  }


export const addComment = 
  async ({ data, entityData, uniqueData, chainBatch = true }: AddCommentParam) => {  

    const newCommentsDocRef: DocumentReference = 
      doc(commentsCollectionRef);
  
    const batch: WriteBatch = writeBatch(firestore);

    const currentTime = new Date().getTime();

    const formattedComment: Comment = {
      ...data,
      id: newCommentsDocRef.id,
      numOfChildren: 0,
      timestampUTC: currentTime,
    }

    batch.set(newCommentsDocRef, formattedComment);

    if (!chainBatch) {
      try {
        await batch.commit();
      } catch (e) {
        throw console.error("Batch error: " + e);
      }
    }

    return { data: formattedComment, ref: newCommentsDocRef, batch, entityData, uniqueData };
  }