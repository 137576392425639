import { arrayRemove, arrayUnion, doc, updateDoc, getDoc } from "firebase/firestore";
import { auth, firebase, usersCollectionRef } from "../instance";
import { getDownloadURL, getStorage, ref, uploadBytes, UploadResult } from "firebase/storage";
import { AccountState } from "../../redux/slices/account/account.slice";
import { updateEmail } from "firebase/auth";
import { fetchUser } from "./fetch-account";
import { propositionCollectionRef } from "../instance";

export interface UpdatePoliticianFollowingParam {
  name: string;
  userId: string;
  isFollowed: boolean | undefined;
}

export const updatePoliticianFollowing = async ({ name, userId, isFollowed }: UpdatePoliticianFollowingParam) => {
  const userDocRef = doc(usersCollectionRef, userId);

  await updateDoc(userDocRef, {
    politiciansFollowing: isFollowed ? arrayRemove(name) : arrayUnion(name)
  });
}

export const uploadProfilePicture = async (imageFile: any) => {
  // upload pic to firebase storage
  const uploadResult: UploadResult = 
    await uploadBytes(ref(getStorage(firebase), `images/${imageFile.name}`), imageFile);
  // doc url
  return await getDownloadURL(uploadResult.ref);
};

export const updateUserAccount = async (newAccountData: AccountState) => {
  const user = auth.currentUser;

  let newUser;
  
  if (user && newAccountData.email) {
    try {
      await updateEmail(user, newAccountData.email);
      const userData = await fetchUser();
      newUser = { ...userData, ...newAccountData };
      await updateDoc(
        doc(usersCollectionRef, user.uid), 
        newUser
      )
    } catch (err) {
      console.error( "Error updating account: " + err);
    }
  }
  
  return newUser;
}

export const getPropositionWithId = async (id: string) => {
  const propositionDoc = await getDoc(doc(propositionCollectionRef, id));
  return propositionDoc.data();
}

const interestExists = (official: any, interest: any) => {
  if (interest) {
    for (let next of interest) {
      if (next === official) {
        return true;
      }
    }
  }
  return false;
};

interface UpdateInterstsParam {
  account: AccountState;
  interest: string;
}

export const updateInterests = async ({ account, interest }: UpdateInterstsParam) => {
  const { interests, userId } = account;
  const interestAlreadyExists = interestExists(interest, interests);
  
  let newInterests;

  if (!interestAlreadyExists) {
    // follow a user if not already followed
    newInterests = [...interests, interest];
  } else {
    //unfollow user if user is followed
    newInterests = interests.filter(
      (next) => next !== interest
    );
  }

  await updateDoc(
    doc(usersCollectionRef, userId), 
    { interests: newInterests }
  )
}