import './loading.css';



const Loader = ({ isFull = false }: any) => {
  return (
    <div className={`loading-wrapper ${isFull ? 'full' : ''}`}>
      <div className={`lds-ellipsis ${isFull ? 'ellipsis-full' : ''}`}><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loader;