import { doc, DocumentData, DocumentSnapshot, getDoc } from "firebase/firestore"
import { auth, usersCollectionRef } from "../instance"
import { AccountState } from "../../redux/slices/account/account.slice";

export const fetchUser = async () => {
  const authUser = auth.currentUser;
  const userDoc: DocumentSnapshot<DocumentData> = await getDoc(doc(usersCollectionRef, authUser?.uid))
  return userDoc.data() as AccountState;
}

export const fetchMediaAndInterests = async () => {
  const user: AccountState = await fetchUser();
  const { medias, interests } = user;
  return { medias, interests }
}