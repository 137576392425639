import { MouseEvent, useState } from 'react';
import OnboardingCardLayout from '../card-layout/OnboardingCardLayout';
import MediaSelector from '../../../shared/media-selector/MediaSelector';
import { updateSignUpState, updateUserMedias } from '../../../../redux/slices/sign-up/sign-up.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux-hooks';
import { RootState } from '../../../../redux/store';

const headerText = 'SELECT NEWS YOU TRUST';
const buttonLabel = 'CONTINUE';

interface Param {
  onNext: Function;
}


const MediaSelection = ({ onNext }: Param) => {
  const [state, updateState] = useState<string[]>([]);
  const signUp = useAppSelector((state: RootState) => state.signUp);
  const dispatch = useAppDispatch();

  const onArrowClick = () => {
    dispatch(
      updateSignUpState({
        currentStep: signUp.currentStep - 1
      })
    )
  };

  const checkValid = () => {
    return state.length > 0;
  };

  const validate = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(updateUserMedias(state));
    onNext();
  };

  return (
    <section id='media-selection'>
      <OnboardingCardLayout
        onArrowClick={onArrowClick}
        headerText={headerText}
        content={
          <MediaSelector
            onChange={(selectedMedias: string[]) => {
              updateState(selectedMedias)
            }}
            medias={state}
          />
        }
        buttonParams={
          { label: buttonLabel, onClick: validate, disabled: !checkValid() }
        }
      />
    </section>
  );
};

export default MediaSelection;
