import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Redirect {
  from: string;
  to: string;
}

export interface Tabs {
  ballotTab: string;
  subBallotTab: string;
}

interface UseNavParam {
  redirects: Redirect[];
  tabs: Tabs;
}


interface Options {
  useOnce?: boolean;
}

interface UseRedirectsParam {
  redirects: Redirect[];
  options?: Options;
}

const defaultOptions = {
  useOnce: false
}

export const useRedirects = ({ redirects, options = defaultOptions }: UseRedirectsParam ) => {
  const location = useLocation();
  const navigate = useNavigate();
  const fixed = useRef(false);
  const { useOnce } = options;
  useEffect(() => {
    if (!fixed.current) {
      redirects.forEach((re) => {
        if (location.pathname === re.from) {
          navigate(re.to);
        }
      });
      if (useOnce) {
        fixed.current = true;
      }
    }
  }, [navigate, location, redirects, fixed, useOnce])
}
// EXPERIMENTAL NOT WORKING
export const useBallotNav = ({ redirects, tabs }: UseNavParam) => {

  const [state, setState] = useState<Tabs>({ ...tabs });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("non stop")
    redirects.forEach((re) => {
      if (location.pathname === re.from) {
        navigate(re.to);
      }
    });

    const paths = 
      location.pathname.split('/').filter(path => !!path);

    setState(prev => ({
      ...prev,
      ballotTab: paths[1] || prev['ballotTab'],
      subBallotTab: paths[2] || prev['subBallotTab']
    }));
  }, [navigate, location, redirects, setState, tabs]);

  return [state, setState];
} 

;


/*
  const [state, setState] = 
    useBallotNav(
      { 
        redirects: [{ from: '/ballot', to: '/ballot/local'}], 
        tabs: { ballotTab: "local", subBallotTab: "executive"}
      }
    );

*/