import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/redux-hooks";
import { AccountState } from "../../../../redux/slices/account/account.slice";
import { saveBills } from "../../../../redux/thunks/politicians-ballot/politicians.thunks";
import { useLocation, useOutletContext } from "react-router-dom";
import DataGovBillCard from "../../proposition/DataGovBillCard";

interface AccountContext {
  account: AccountState;
}

const Congress = () => {
  const { account } = useOutletContext() as AccountContext;
  const bills = useAppSelector(store => store.politicians.bills);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account.userId) {
      dispatch(saveBills());
    }
  }, [dispatch, account.userId]);

  return (
    <>
      { 
        bills.map((bill: any) => <DataGovBillCard bill={bill} backRoute={location.pathname} />)
      }
    </>
  );
}

export default Congress;
