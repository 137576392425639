import "./Section.css";

interface Param {
  executive: boolean | undefined;
  title: string;
  subTitle?: string;
  items: any[]
  renderItem: any;
  isComment?: boolean;
}

const Section = ({ executive, title, subTitle, items, renderItem, isComment = false }: Param) => {
  return (
    <section className={`${isComment ? null : 'official-section'} ${executive ? "executive-padding" : "default-padding"}`}>
      <div className={`${isComment ? null : 'official-section-content-wrapper'}`}>
        <h2 className="official-section-title">{title}</h2>
        <h3>{subTitle}</h3>
        <ul className="official-section-list">{items.map(renderItem)}</ul>
      </div>
    </section>
  );
};

export default Section;
