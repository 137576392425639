import { MouseEvent, useState } from "react";
import TextInput from "../../../shared/text-input/TextInput";
import OnboardingCardLayout from "../card-layout/OnboardingCardLayout";
import { usersCollectionRef } from "../../../../firebase/instance";
import { UnknownObject } from "../../../../interfaces/global";
import { 
  getDocs, 
  Query, 
  query,
  QuerySnapshot, 
  where 
} from "firebase/firestore";
import { 
  updateAuthState, 
  updateSignUpState, 
  updateUserInfo 
} from "../../../../redux/slices/sign-up/sign-up.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/redux-hooks";
import { RootState } from "../../../../redux/store";

const headerText = "CREATE A USER NAME AND PASSWORD";
const buttonLabel = "CONTINUE";

interface Param {
  onNext: Function;
}

const CreatePassword = ({ onNext }: Param) => {
  const [state, updateState] = useState(
    { username: '', passwordOne: '', passwordTwo: '' }
  );

  const signUp = useAppSelector(( state: RootState) => state.signUp);
  const dispatch = useAppDispatch();

  const onArrowClick = () => {
    dispatch(
      updateSignUpState({ currentStep: signUp.currentStep - 1 })
    );
  };

  const validateInput = () => {
    let error = null;
    const { passwordOne, passwordTwo } = state;
    if (passwordOne.length < 6) {
      error = "password too short";
    } else if (passwordTwo !== passwordOne) {
      error = "password does not match";
    }
    return error;
  };

  const userNameExists = async () => {
    const q: Query = 
      query(usersCollectionRef, where('username', '==', state.username));
    return getDocs(q)
      .then((querySnapshot: QuerySnapshot) => {
        return querySnapshot.size >= 1;
      })
  }

  const setErrorState = (error: UnknownObject) => {
    dispatch(updateSignUpState(error))
  }

  const handleClick = (e: MouseEvent) => {
    const error = validateInput();

    switch (error) {
      case "password too short":
        setErrorState({
          error: {
            passwordTooShort: "Password less than six characters.*",
          },
        });
        break;
      case "password does not match":
        setErrorState({
          error: {
            passwordDoesNotMatch: "Password does not match.*",
          },
        });
        break;

      case null:
        userNameExists()
          .then((exists: boolean) => {
            if (exists) {
              setErrorState({
                error: {
                  usernameExists: "This username already exists.*",
                },
              });
            } else {
              dispatch( 
                updateUserInfo({
                  username: state.username
                })
              )
              dispatch( 
                updateAuthState({
                  password: state.passwordTwo
                })
              )
              onNext();
            }
          });
        break;
      default:
        throw new Error(`unknown error ${error}`);
    }
  };

  return (
    <OnboardingCardLayout
      onArrowClick={onArrowClick}
      headerText={headerText}
      content={
        <div>
          <TextInput
            placeholder="USERNAME"
            value={state.username}
            type="text"
            onTextInputChange={(event) =>
              updateState({ ...state, username: event.target.value })
            }
            errorMessage={signUp.error.usernameExists}
          />
          <TextInput
            placeholder="PASSWORD"
            value={state.passwordOne}
            type="password"
            onTextInputChange={(event) =>
              updateState({ ...state, passwordOne: event.target.value })
            }
            errorMessage={signUp.error.passwordTooShort}
          />
          <TextInput
            placeholder="CONFIRM PASSWORD"
            value={state.passwordTwo}
            type="password"
            onTextInputChange={(event) =>
              updateState({ ...state, passwordTwo: event.target.value })
            }
            errorMessage={signUp.error.passwordDoesNotMatch}
          />
        </div>
      }
      buttonParams={
        { label: buttonLabel, onClick: handleClick }
      }
    />
  );
};

export default CreatePassword;
