import { forwardRef } from "react";
import { PerigonEntityData } from "../../../interfaces/comment-entities.interfaces";
import { UnknownObject } from "../../../interfaces/global";
import '../story.css';

interface Param {
  date: string;
  entityData: PerigonEntityData;
  idx: number | undefined;
  renderContent: any;
  showSourceIcon: boolean;
}

const NewsContent = forwardRef<HTMLParagraphElement, Param>((props, ref) => {
  const { date, entityData, idx, renderContent, showSourceIcon } = props;
  return (
    <div>
      <div className="news-content-container">
        <p className="news-content" key={idx} ref={ref}>
          {renderContent} <a href={`${entityData.url}`}>... more</a>
        </p>
      </div>

      {/* souce icon and news date */}
      <div className="news-source-date">
        <div className="news-source-container">
          {showSourceIcon && entityData.source?.id ? (
            <img
              src={`../../img/medias/${entityData.source?.id}.svg`}
              alt="source-icon"
              className="source-icon"
            />
          ) : null}
          <span className="news-source">
            {entityData.source?.name || (entityData as UnknownObject).source?.domain}
          </span>
        </div>
        <span className="publish-date">{` ${date}`}</span>
      </div>
    </div>
  )
})

export default NewsContent;