import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {};

interface TickerData {
  id: string;
  supporting: number;
  opposing: number;
  numOfComments: number;
}

interface TickerSelection {
  id: string;
  selection: string;
}

export const ballotSlice = createSlice({
  name: 'ballot',
  initialState,
  reducers: {
    set_ticker_data: ( state, action: PayloadAction<TickerData> ) => {
      const { id, supporting, opposing, numOfComments } = action.payload;
      return {
        ...state,
        [id]: { supporting, opposing, numOfComments }
      }
    },
    increment_ticker_value: ( state, action: PayloadAction<TickerSelection> ) => {
      const { id, selection } = action.payload;
      if (!state[id]) {
        state[id] = { supporting: 0, opposing: 0, numOfComments: 0};
      }
      state[id][selection] += 1;

    },
    decrement_ticker_value: ( state, action: PayloadAction<TickerSelection> ) => {
      const { id, selection } = action.payload;
      if (!state[id]) {
        state[id] = { supporting: 0, opposing: 0, numOfComments: 0};
      }
      state[id][selection] -= 1;
    }
  }
});

export const { 
  set_ticker_data, 
  increment_ticker_value,
  decrement_ticker_value 
} = ballotSlice.actions;

export default ballotSlice.reducer;