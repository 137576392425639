import { httpsCallable } from "firebase/functions";
import { fetchUser } from "../../../firebase/account/fetch-account";
import { updatePoliticianFollowing, UpdatePoliticianFollowingParam } from "../../../firebase/account/update-account";
import { fetchCommentById } from "../../../firebase/comments/shared/fetch-comments";
import { functions } from "../../../firebase/instance";
import { Comment, RetrievedComment, UserFollowersDoc } from "../../../interfaces/comment-entities.interfaces";
import { UnknownObject } from "../../../interfaces/global";
import { update_recent_activity, update_user_account } from "../../slices/account/account.slice";

interface Param extends UpdatePoliticianFollowingParam {
  dispatch: Function;
}

export const savePoliticianFollowing = async ({ name, userId, isFollowed, dispatch }: Param ) => {
  await updatePoliticianFollowing({ name, userId, isFollowed });
  const updatedUser = await fetchUser();
  dispatch(update_user_account(updatedUser));
}

interface UpdateActivity {
  userFollowersData: UserFollowersDoc;
  dispatch: Function;
}

const addCommentData = async (incoming: RetrievedComment) => {
  const { comment } = incoming;
  const data: Comment = await fetchCommentById(comment.id) as Comment;
  comment.data = data;
  return incoming;
}

export const updateRecentActivity2 = async ({ userFollowersData, dispatch }: UpdateActivity) => {
  
  const { recentComments } = userFollowersData;

  /*
    Strip out firebase ref from comments to avoid serialization error
  */

  const strippedRefComments = 
    (recentComments as any[]).map(cur => {
      if (cur.ref) {
        cur.ref = null;
      }
      return cur;
    });

  const generateFeedFromRecentComments =
    httpsCallable(functions, "generateFeedFromRecentComments");

  const { data } = await generateFeedFromRecentComments({ recentComments: strippedRefComments });

  const feed = (data as unknown as UnknownObject).comments;

  const feedPromises: Promise<any>[] = [];

  for (let i = 0; i < feed.length; i++) {
    const comment = feed[i];
    feedPromises.push(addCommentData(comment));
  }

  const updatedFeed: RetrievedComment[] = await Promise.allSettled(feedPromises)
      .then((resolve: any) => resolve.reduce((acc: any, cur: any) => {
          cur.value && acc.push(cur.value);
          return acc;
        },[])
      );
       

  dispatch(
    update_recent_activity({ feed: updatedFeed })
  )
}