// import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { CommentsDynamicSegment } from '../../../routing/route-loaders';
import '../shared/comment.css';
import '../shared/comments.container.css';
import CommentsDisplay from './CommentsDisplay';


const CommentsContainer =
  () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-1);
    };

    const { entityId, commentType } = 
      useLoaderData() as unknown as CommentsDynamicSegment;      

    return (
      <div className="comments-page-container">
        <div className="previous-and-edit-container-comments">
          <img
            className="go-back-arrow"
            src="/img/previous.svg"
            onClick={handleGoBack}
            alt="go-back-icon"
            />
          <div className="page-title">COMMENTS</div>
        </div>
        <CommentsDisplay entityId={entityId} type={commentType as string}/>
      </div>
    )
  }

export default CommentsContainer;