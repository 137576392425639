import { useState } from "react";
import SearchInput from "../shared/search-input/SearchInput";
import SearchResultDisplay from "./SearchResultDisplay";
import "./People.css";
import { useAppSelector } from "../../redux/hooks/redux-hooks";
import { AccountState } from "../../redux/slices/account/account.slice";
import { queryTypesense } from "../../api/typesense/api.typesense-client";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/instance";
import { SearchResponse } from "typesense/lib/Typesense/Documents";

const emailSubject = `Join me at Mobilize`;
const emailBody = `Hi!%0A%0ACome check out Mobilize at: https://app.mobilizenow.io/#/signin`;

interface PeopleState {
  searchResult: any[];
  inputValue: string;
}

const People = () => {
  const currentUser: AccountState = useAppSelector(store => store.account);
  console.log({ currentUser })

  const [state, setState] = useState<PeopleState>({
    searchResult: [],
    inputValue: "",
  });

  const searchPeople = async (peopleToSearch: string) => {

    const queryParams = {
      collectionName: 'users',
      search: { q: peopleToSearch, query_by: "username" }
    }

    // Check if local - then use local query - else if not - use production query
    const isProduction = process.env.REACT_APP_ENV_MODE === "production";

    let queryFunc = isProduction ? 
      httpsCallable(functions, "queryTypesense") : 
      queryTypesense;

    const typesenseRes = await queryFunc(queryParams) as SearchResponse<{}>;
    
    const results = isProduction ?
      typesenseRes && ((typesenseRes as any)?.data?.hits as any[]).map((cur) => cur.document) :
      typesenseRes && (typesenseRes?.hits as any[]).map((cur) => cur.document)

    // const { data }: HttpsCallableResult<unknown> = await search(peopleToSearch);

    if (results.length > 0) {
      setState({ ...state, searchResult: (results as any[]), inputValue: "" });
    } else {
      setState({ ...state, searchResult: [], inputValue: "" });
    }
  }

  const handleInputChange = (e: any) => {
    setState({ ...state, inputValue: e.target.value });
  };

  const handleOnKeyPress = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault();
      e.stopPropagation();
      const peopleToSearch = e.target.value;
      searchPeople(peopleToSearch);
    }
  };

  return (
    <div className="component-container" id="people-container">
      <SearchInput
        placeholder="Search in People"
        onChange={handleInputChange}
        onKeyPress={handleOnKeyPress}
        value={state.inputValue}
        emailBody={emailBody}
        emailSubject={emailSubject}
      />

      {state.searchResult ? (
        <SearchResultDisplay
          searchResult={state.searchResult}
          following={currentUser.following}
        />
      ) : (
        <div className="no-result-container">
          <p>
            Grow your network. Share your prospetive while learning from others.
          </p>
          <a
            className="email-invite-link"
            href={`mailto:?subject=${emailSubject}&body=${emailBody}`}
          >
            INVITE
          </a>
        </div>
      )}
    </div>
  );
};

export default People;