import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './slices/account/account.slice';
import commentsReducer from './slices/comments/comments.slice';
import politiciansReducer from './slices/politicians/politicians.slice';
import storiesReducer from './slices/stories/stories.slice';
import signUpReducer from './slices/sign-up/sign-up.slice';
import ballotSlice from './slices/ballot/ballot.slice';
import mixedFeedSlice from './slices/feed/mixedFeed.slice';

const store = configureStore({
  reducer: {
    account: accountReducer,
    ballot: ballotSlice,
    comments: commentsReducer,
    mixedFeed: mixedFeedSlice,
    politicians: politiciansReducer,
    stories: storiesReducer,
    signUp: signUpReducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;