import { useEffect, useState } from "react";
// import FooterTabBar from "../../shared/FootTabBar";
import PoliticianPage from "../../../politician/politician-profile/PoliticianPage";
import PropositionPage from "../../proposition/PropositionPage";
import { useOutletContext } from "react-router-dom";
import { UnknownObject } from "../../../../interfaces/global";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/redux-hooks";
import { savePropositions } from "../../../../redux/thunks/politicians-ballot/politicians.thunks";

interface Param {
  data?: any;
}

const State = ({ data }: Param) => {
  const [state] = useState({
    currentTab: "Politicians",
  });

  const dispatch = useAppDispatch();
  const propositions = useAppSelector(store => store.politicians.propositions);
  const { politicians } = useOutletContext() as UnknownObject;
  data = data ? data : politicians?.data?.state;

  useEffect(() => {
    // fetch props
    if (state.currentTab === "Proposition" && propositions.jurisdiciton !== data.name) {
      console.log("Dispatching propositions");
      dispatch(savePropositions(data.name));
    }
  }, [state, dispatch, propositions.jurisdiciton, data]);

  const offices = [
    ...(data?.offices ?? []),
    ...(data?.congressionalDistrict?.offices ?? []),
  ];

  return (
    <>
      {state.currentTab === "Politicians" ? (
        <PoliticianPage offices={offices} />
      ) : (
        <PropositionPage data={propositions.data} jurisdiction={data.name} />
      )}
      {/* <FooterTabBar
        leftLabel="Politicians"
        rightLabel="Proposition"
        onStateChange={(newState: any) => {
          setState({
            ...state,
            currentTab: newState,
          });
        }}
      /> */}
    </>
  );
};

export default State;
