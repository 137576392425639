import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnknownObject } from '../../../interfaces/global';
import { User as FirebaseUser } from 'firebase/auth';
import { UserSignUp } from '../../../interfaces/user-entities.interface';

export interface AuthState {
  email: string;
  verificationId: string;
  password: string;
  phoneNumber: string;
}

export interface SignUpState {
  authState: AuthState;
  currentStep: number;
  error: UnknownObject;
  retryCount: number;
  user: UserSignUp
  userAuthData: FirebaseUser | UnknownObject;
}

const initialState: SignUpState = {
  currentStep: 1,
  user: {
    fullname: '',
    username: '',
    zipcode: '',
    medias: [],
    interests: []
  },
  userAuthData: {},
  authState: { 
    email: '',
    password: '',
    verificationId: '',
    phoneNumber: ''
  },
  error: {},
  retryCount: 0,
}

const stateInstance = JSON.stringify(initialState);

export const signUpSlice = createSlice({
  name: 'sign-up',
  initialState,
  reducers: {
    incrementStep: ( state ) => {
      state.currentStep++;
    },
    resetState: () => {
      return JSON.parse(stateInstance);
    },
    updateSignUpState: ( state, action: PayloadAction<UnknownObject> ) => {
      return {
        ...state,
        ...action.payload
      }
    },
    updateUserInfo: ( state, action: PayloadAction<UnknownObject> ) => {
      Object.assign(state.user, action.payload);
    },
    updateAuthData: (state, action: PayloadAction<UnknownObject> ) => {
      Object.assign(state.userAuthData, action.payload);
    },
    updateUserMedias: ( state, action: PayloadAction<string[]> ) => {
      state.user.medias = action.payload;
    },
    updateUserInterests: ( state, action: PayloadAction<string[]> ) => {
      state.user.interests = action.payload;
    },
    updateAuthState: ( state, action: PayloadAction<UnknownObject> ) => {
      Object.assign(state.authState, action.payload);
    },
    updateError: ( state, action: PayloadAction<UnknownObject> ) => {
      Object.assign(state.error, action.payload);
    }
  }
});

const { actions, reducer } = signUpSlice;

export const { 
  resetState,
  incrementStep,
  updateSignUpState,
  updateUserInfo,
  updateAuthData,
  updateUserMedias,
  updateUserInterests,
  updateAuthState,
  updateError
} = actions;

export default reducer;