import { useEffect, useState } from "react";
import Navigation from "./shared/navigation/Navigation";
import jsonData from "../../routing/gov-branch-links.json";
import { ApolloProvider } from "@apollo/client";
import { OPENSTATE_URI, OPENSTATE_API_KEY } from "../../config";
import "./Ballot.css";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/redux-hooks";
import { savePoliticianData } from "../../redux/thunks/politicians-ballot/politicians.thunks";
import { Outlet } from "react-router-dom";

const client = new ApolloClient({
  uri: OPENSTATE_URI,
  cache: new InMemoryCache(),
  headers: {
    "x-api-key": OPENSTATE_API_KEY,
  }
});

const links = jsonData.data;

export const Ballot = () => {
  const store = useAppSelector(store => store);
  const { politicians, account } = store;

  const dispatch = useAppDispatch();

  const [ballotSection, setBallotSection] = useState({
    politicians,
    account,
    currentBallot: "local",
    currentSubTab: "executive",
  });

  // console.log({ ballotSection })

  useEffect(() => {
    if (account.userId) {
      dispatch(savePoliticianData());
    }
  }, [dispatch, account]);

  useEffect(() => {
    setBallotSection(prev => ({ ...prev, politicians }));
  }, [politicians]);

  const onNavigationChange = ({ path, subPath }: any) => {
    setBallotSection(prev => ({
      ...prev,
      currentBallot: path,
      currentSubTab: subPath,
    }));
  }

  return (
    <ApolloProvider client={client}>
      <div id="ballot" className="ballot-container">
        <Navigation
          id="navigation"
          links={links}
          onNavigationChange={onNavigationChange}
          ballotTab={ballotSection.currentBallot}
          subBallotTab={ballotSection.currentSubTab}
        />
        <Outlet context={ballotSection} />
      </div>
    </ApolloProvider>
  );
};


export default Ballot;
