import "./AccountTabBarItem.css";
import { Link } from "react-router-dom";

interface Param {
  label: string;
  link: string;
  isSelected: boolean;
  stateChange: any;
}

const AccountTabBarItem = (
  { label, link, isSelected, stateChange }: Param
) => {
  const style = isSelected ? "active" : "";
  return (
    <Link to={link} className={`tab-bar-item ${style}`}>
      <div onClick={() => stateChange(label)}>{label.toUpperCase()}</div>
    </Link>
  );
};

export default AccountTabBarItem;
